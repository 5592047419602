import { Component } from "@angular/core";
import { GfxAppContentService } from "../../core/components/app.content/gfx.app.content.service";
import { WorkflowService } from "../Services/workflow.service";
import { GfxState, getUserState } from "../../store/gfxstate";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { IUser } from "../../authentication/models/IUser";

@Component({
    selector : "gfx-wf-spec-list",
    templateUrl : "gfx.wf.spec.list.html"
})
export class GfxWfSpecList {
    
    curItemWFPending: any[];
    wfData;
    list;
    item;
    headers;
    title = "Mes instances";
    public currUser: IUser;
    formattedLines;

    constructor(private appContentService: GfxAppContentService, private wfService : WorkflowService, private store: Store<GfxState>,) {

    }
    
    ngOnInit() {
        //Get current user
        let usrObs: Observable<IUser>;
        usrObs = this.store.select(getUserState);
        let sub = usrObs.subscribe((s) => {
            this.currUser = s;
        });
        
        this.headers = this.curItemWFPending[0];
        this.headers.push('Actions');
        this.list = this.curItemWFPending[1];
        let i=0;
        let rowProps;
        this.formattedLines = [];
        if (this.list.lines!==null) {
            for (let row of this.list.lines) {
                // Step 1. Get all the object keys.
                rowProps = Object.keys(row);
                // Step 2. Create an empty array.
                let rowAsArray = [];
                // Step 3. Iterate through all keys.
                for (let prop of rowProps) { 
                    /* if (prop!='id') { */
                        rowAsArray.push([prop,row[prop]]);
                    /* } */
                }
                this.formattedLines[i] = rowAsArray;
                i++;
            }
        }
        //this.list.lines = this.formattedLines;
    }

    
    openItemView = function (item)
    {
        this.appContentService.changeContent.emit({ ComponentName: "GfxWfView", Data: {curItemWFPending:item,wfData:this.wfData} });
    };
    openItemHistory = function (item)
    {
        this.appContentService.changeContent.emit({ ComponentName: "GfxWfHistory", Data: {curItemWFPending:item} });
    };
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'notEqual'
})

export class NoteEqualPipe implements PipeTransform {
  transform(items: any[], {searchText, pattern}): any[] {
    if(!items) return [];
    if(!searchText) return items;

    searchText = searchText.toLowerCase();

    return items.filter( it => {
        return it.toLowerCase() != searchText;
        });
   }
}