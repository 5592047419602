import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class VisualisationProbService {

  constructor(private gfxhttp: GfxHttpSrv) { }

  getIncidentById(idReclam) {
    return this.gfxhttp.get('incident/incidentById', {idReclam: idReclam});
  }
}
