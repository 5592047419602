import { Component, OnInit } from '@angular/core';
import { MesSignalementsService } from '../../services/mes-signalements.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { ISelectData } from '../../../common/models/ISelectData';
import { GfxRefService } from '../../../../gfx/ref/gfx.ref.service';
import { DeclarProbService } from '../../services/declar-prob.service';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IGfxListConfig } from '../../../../gfx/core/components/gfxlist/IGfxListConfig';
import { GfxListOrderDirectionEnum } from '../../../../gfx/core/components/gfxlist/GfxListOrderDirectionEnum';
import { SignalementsListFilterComponent } from '../signalements-list-filter/signalements-list-filter.component';
import { TbdService } from '../../services/tbd.service';


@Component({
  selector: 'app-mes-signalements',
  templateUrl: './mes-signalements.component.html',
  styleUrls: ['./mes-signalements.component.css']
})
export class MesSignalementsComponent implements OnInit {

  private signalementsList: any;
  config: any;
  collection = { count: 0, data: [] };
  private idProfil;
  private lieux: ISelectData;
  private natures: ISelectData;
  private locataires: ISelectData;
  private filterForm: FormGroup;
  listCfg: IGfxListConfig;
  valueFilterStatut
  statis: any;
  isReturn: boolean;
  oldFilter: any = { FilterElements: [] };
  oldPageSize: any;

  constructor(private tbdSrv: TbdService, private fb: FormBuilder, private signalementSrv: MesSignalementsService, private utilSrv: GfxMiscSrv
    , private declarPrbSrv: DeclarProbService) {

  }

  ngOnInit() {
    this.statis = { nonTraites: '', encours: '', resolus: '', soumis: '', refuses: '' }
    this.tbdSrv.getStatisAnnuel().subscribe((data) => {
      this.statis = data;
      // this.statis.soumis = Number(data.nonTraites) + Number(data.encours) + Number(data.resolus);
    });
    this.idProfil = sessionStorage.getItem('idProfile');

    this.valueFilterStatut = "";
    if (localStorage.getItem('etat')) {
      this.valueFilterStatut = JSON.parse(localStorage.getItem('etat'));
    }

    if (sessionStorage.getItem('isReturn')) {
      this.isReturn = true;
      this.oldFilter = JSON.parse(sessionStorage.getItem('oldFilter'));
      this.oldPageSize = JSON.parse(sessionStorage.getItem('oldPageSize'));
    }

    if (this.idProfil == 2) {

      this.listCfg = {
        Code: "signalementList",
        Title: "Liste des signalements",
        DataUrl: "incident/messignalements",
        ListOptions: {
          OrderBy:  "idIncident",
          OrderDir: GfxListOrderDirectionEnum.DESC,
          HeaderPanelExpanded: false,
          EnableBuiltInFilter: true,
          EnableCustomFilter: true,
          ExpandCustomFilter: false,
        },
        Columns: [
          { Name: "idNature", Label: "", Render: "categorie" },
          { Name: "idNature", Label: "CATEGORIE", Render: "nature" },
          { Name: "reference", Label: "RÉFÉRENCE", Render: "reference" },
          { Name: "date", Label: "DATE DE La RÉCLAMATION", Render: "date", TypeDate: true },
          { Name: "dtCr", Label: "DATE DE PUBLICATION", Render: "dtCr", TypeDate: true },
          { Name: "idLieu", Label: "Localisation", Render: "lieu" },
          { Name: "idEtatIncid", Label: "STATUT", Render: "status" }
        ],
        Filter: {
          CustomFilterComponent: SignalementsListFilterComponent,
          FilterElements: this.oldFilter.FilterElements.length > 0 ? this.oldFilter.FilterElements : [
            { Name: "nature", Field: "f1", Operator: "=", Value: "" },
            { Name: "lieu", Field: "f2", Operator: "=", Value: "" },
            { Name: "status", Field: "f3", Operator: "=", Value: this.valueFilterStatut },
          ]
        },

        Actions: [
          { Name: "view", Label: "Visualiser", ComponentName: "VisualisationProbComponent", FaIcon: "fa-eye", Params: { hideAction: false } },
          { Name: "Traitement", Label: "Traitement", ComponentName: "DispatcherFormComponent", FaIcon: "fa-cogs", Params: { hideAction: this.hideTraitAction } },//, Params : {hideAction: this.hideTraitAction}
          { Name: "Suivi", Label: "Fiche de suivi", ComponentName: "SuiviReclamationComponent", FaIcon: "fa-chart-bar", Params: { hideAction: this.hideTraitAction } },
          { Name: "Cloturer", Label: "Clôturer", ComponentName: "ColtureFormComponent", FaIcon: "fa-check", Params: { hideAction: this.hideTraitAction } },
          { Name: "Imprimer", Label: "Imprimer", ComponentName: "ImprimerProbComponent", FaIcon: "fa-print", Params: { hideAction: this.hidePrintAction } }
        ],
        PaginatorConfig: {
          StaticMode: false,
          PageSize: this.oldPageSize > 10 ? this.oldPageSize : 10,
          SizeOptions: [10, 20, 50, 100],
          ShowFirstLastBtns: true
        },
        ExportOptions: {
          FileName: "profils",
          Columns: ["code", "libelle"],
          EnableEXCEL: true,
          EnableWORD: false,
          EnablePDF: false,
        }

      }
    } else {

      this.listCfg = {
        Code: "signalementList",
        Title: "Liste des signalements",
        DataUrl: "incident/messignalements",
        ListOptions: {
          OrderBy: "idIncident",
          OrderDir: GfxListOrderDirectionEnum.DESC,
          HeaderPanelExpanded: false,
          EnableBuiltInFilter: true,
          EnableCustomFilter: true,
          ExpandCustomFilter: false,
        },
        Columns: [
          { Name: "idNature", Label: "", Render: "categorie" },
          { Name: "idNature", Label: "CATEGORIE", Render: "nature" },
          { Name: "reference", Label: "RÉFÉRENCE", Render: "reference" },
          { Name: "idAppartement", Label: "LOCATAIRE", Render: "locataire" },
          { Name: "date", Label: "DATE DE La RÉCLAMATION", Render: "date", TypeDate: true },
          { Name: "dtCr", Label: "DATE DE PUBLICATION", Render: "dtCr", TypeDate: true },
          { Name: "idLieu", Label: "Localisation", Render: "lieu" },
          { Name: "idEtatIncid", Label: "STATUT", Render: "status" },
          { Name: "delai", Label: "DÉLAI (J)", Render: "delai" }
        ],
        Filter: {
          CustomFilterComponent: SignalementsListFilterComponent,
          FilterElements: this.oldFilter.FilterElements.length > 0 ? this.oldFilter.FilterElements : [
            { Name: "nature", Field: "f1", Operator: "=", Value: "" },
            { Name: "lieu", Field: "f2", Operator: "=", Value: "" },
            { Name: "status", Field: "f3", Operator: "=", Value: this.valueFilterStatut },
          ]
        },

        Actions: [
          { Name: "view", Label: "Visualiser", ComponentName: "VisualisationProbComponent", FaIcon: "fa-eye ", Params: { hideAction: this.hideViewAction } },
          { Name: "Traitement", Label: "Traitement", ComponentName: "DispatcherFormComponent", FaIcon: "fa-cogs ", Params: { hideAction: this.hideTraitAction } },//, Params : {hideAction: this.hideTraitAction}
          { Name: "Suivi", Label: "Fiche de suivi", ComponentName: "SuiviReclamationComponent", FaIcon: "fa-chart-bar ", Params: { hideAction: this.hideSuiviAction } },
          { Name: "Cloturer", Label: "Clôturer", ComponentName: "ColtureFormComponent", FaIcon: "fa-check", Params: { hideAction: this.hideClotureAction } },
          { Name: "Imprimer", Label: "Imprimer", ComponentName: "ImprimerProbComponent", FaIcon: "fa-print", Params: { hideAction: this.hidePrintAction } }
        ],
        PaginatorConfig: {
          StaticMode: false,
          PageSize: this.oldPageSize > 10 ? this.oldPageSize : 10,
          SizeOptions: [10, 20, 50, 100],
          ShowFirstLastBtns: true
        },
        ExportOptions: {
          FileName: "profils",
          Columns: ["code", "libelle"],
          EnableEXCEL: true,
          EnableWORD: false,
          EnablePDF: false,
        }


      }
    }

    this.declarPrbSrv.getLieuxProblemes().subscribe((data) => {
      this.lieux = data;
    });

    this.declarPrbSrv.getNaturesProblemes().subscribe((data) => {
      this.natures = data;
    });

    localStorage.removeItem('etat')

  }

  hideViewAction(row) {
    return true;
  }

  hideTraitAction(row) {
    if (row.idProfil == 3 && row.estResolu == 0) {
      return true;
    }
  }

  hideClotureAction(row) {
    if (row.idProfil == 3 && (row.estResolu == 0 || row.estResolu == 1)) {
      return true;
    }
  }

  hideSuiviAction(row) {
    if (row.idProfil == 3 && (row.estResolu == 1)) {
      return true;
    }
  }

  hidePrintAction(row) {
    localStorage.setItem('isPrint', JSON.stringify(1));
    return true;
  }


  goToMesSignnalementsByEtat(etat) {
    if (etat == "refuse") {
      localStorage.setItem('etat', JSON.stringify("3"));
    }
    if (etat == "nonTraites") {
      localStorage.setItem('etat', JSON.stringify("0"));
    }
    if (etat == "enCours") {
      localStorage.setItem('etat', JSON.stringify("1"));
    }
    if (etat == "resolu") {
      localStorage.setItem('etat', JSON.stringify("2"));
    }
    this.utilSrv.navigateTo("MesSignalementsComponent");
  }
}
