import { Injectable } from "@angular/core";
import { GfxHttpSrv } from "../../../../../../gfx/helpers/GfxHttpSrv";


@Injectable()
export class ProfilUserService {
    constructor(private gfxhttp : GfxHttpSrv) { }

    get(id) {
        return this.gfxhttp.get('user' , {id:id});

    }

    put(ProfilUser) {
        return this.gfxhttp.put('user/updatePwdPhoto', ProfilUser);
    }
    
}