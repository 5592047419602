import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GfxCoreMaterialModule } from '../gfx/core/gfx.core.material.module';
import { GfxSharedModule } from '../gfx/gfx-shared/gfx-shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { HighchartsChartModule } from 'highcharts-angular';
import { RefAppartemListComponent } from './refs/components/ref-appartem-list/ref-appartem-list.component';
import { RefAppartemListFilterComponent } from './refs/components/ref-appartem-list-filter/ref-appartem-list-filter.component';
import { PropreListComponent } from './common/models/propre-list/propre-list.component';
import { RefAppartementFormComponent } from './refs/components/ref-appartement-form/ref-appartement-form.component';
import { RefAppartementService } from './refs/services/ref-appartement.service';
import { appRefsSrv } from './common/services/app.refs.service';
import { LocataireListComponent } from './refs/components/locataire-list/locataire-list.component';
import { LocataireFormComponent } from './refs/components/locataire-form/locataire-form.component';
import { DeclarProbComponent } from './reclam/components/declar-prob/declar-prob.component';
import { DeclarProbService } from './reclam/services/declar-prob.service';
import { FileSelectDirective } from "ng2-file-upload";
import { MesSignalementsComponent } from './reclam/components/mes-signalements/mes-signalements.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MesSignalementsService } from './reclam/services/mes-signalements.service';
import { PagerService } from './common/services/pager.service';
import { PropreListService } from './common/services/propre-list.service';
import { TbdComponent } from './reclam/components/tbd/tbd.component';
import { TbdService } from './reclam/services/tbd.service';
import { DispatcherFormComponent } from './reclam/components/dispatcher-form/dispatcher-form.component';
import { DispatcherFormService } from './reclam/services/dispatcher-form.service';
import { ColtureFormComponent } from './reclam/components/colture-form/colture-form.component';
import { ColtureReclamService } from './reclam/services/colture-reclam.service';
import { RefLocataireService } from './refs/services/ref-locataire.service';

import { RefLieuxProbService } from './refs/services/ref-lieux-prob.service';
import { RefLieuxProbComponentList } from './refs/components/ref-lieux-prob-list/ref-lieux-prob-list.component';
import { RefLieuProbFormComponent } from './refs/components/ref-lieu-prob-form/ref-lieu-prob-form.component';
import { VisualisationProbComponent } from './reclam/components/visualisation-prob/visualisation-prob.component';
import { VisualisationProbService } from './reclam/services/visualisation-prob.service';
import { SignalementsListFilterComponent } from './reclam/components/signalements-list-filter/signalements-list-filter.component';
import { SuiviReclamationComponent } from './reclam/components/suivi-reclamation/suivi-reclamation.component';
import { SuiviReclamationService } from './reclam/services/suivi-reclamation.service';
import { RefTypeComponent } from './refs/components/ref-type-list/ref-type.component';
import { RefTypeService } from './refs/services/ref-type.service';
import { RefTypeFormComponent } from './refs/components/ref-type-form/ref-type-form.component';
import { SubMenuInfoComponent } from '../gfx/theme/residence_theme/containers/sub-menu-info/sub-menu-info.component';
import { RefNatureProbListComponent } from './refs/components/ref-nature-prob-list/ref-nature-prob-list.component';
import { RefNatureProbFormComponent } from './refs/components/ref-nature-prob-form/ref-nature-prob-form.component';
import { RefNatureProbService } from './refs/services/ref-nature-prob.service';
import { ExportAsService } from 'ngx-export-as';
import { ImprimerProbComponent } from './reclam/components/imprimer-prob/imprimer-prob.component';
import { AppartementListFilterComponent } from './reclam/components/appartement-list-filter/appartement-list-filter.component';
import { LocataireListFilterComponent } from './reclam/components/locataire-list-filter/locataire-list-filter.component';


@NgModule({
  imports: [
    CommonModule,
    GfxSharedModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    GfxCoreMaterialModule,
    HighchartsChartModule,
    NgxPaginationModule,
    GfxCoreMaterialModule
  ],
  declarations: [
    RefAppartemListComponent,
    RefAppartemListFilterComponent,
    PropreListComponent,
    RefAppartementFormComponent,
    LocataireListComponent,
    LocataireFormComponent,
    DeclarProbComponent,
    FileSelectDirective,
    MesSignalementsComponent,
    TbdComponent,
    DispatcherFormComponent,
    ColtureFormComponent,
    RefLieuxProbComponentList,
    RefLieuxProbComponentList,
    RefLieuProbFormComponent,
    RefLieuProbFormComponent,
    VisualisationProbComponent,
    SignalementsListFilterComponent,
    SuiviReclamationComponent,
    RefTypeComponent,
    RefTypeFormComponent,
    SubMenuInfoComponent,
    RefNatureProbListComponent,
    RefNatureProbFormComponent,
    ImprimerProbComponent,
    AppartementListFilterComponent,
    LocataireListFilterComponent
  ],
  entryComponents: [
    RefAppartemListComponent,
    RefAppartemListFilterComponent,
    PropreListComponent,
    RefAppartementFormComponent,
    LocataireListComponent,
    LocataireFormComponent,
    DeclarProbComponent,
    MesSignalementsComponent,
    TbdComponent,
    DispatcherFormComponent,
    ColtureFormComponent,
    RefLieuxProbComponentList,
    RefLieuProbFormComponent,
    VisualisationProbComponent,
    SignalementsListFilterComponent,
    SuiviReclamationComponent,
    RefTypeComponent,
    RefTypeFormComponent,
    SubMenuInfoComponent,
    RefNatureProbListComponent,
    RefNatureProbFormComponent,
    ImprimerProbComponent,
    AppartementListFilterComponent,
    LocataireListFilterComponent
  ],
  providers: [
    RefAppartementService,
    appRefsSrv,
    DeclarProbService,
    MesSignalementsService,
    PagerService,
    PropreListService,
    TbdService
    , DispatcherFormService,
    ColtureReclamService,
    RefLocataireService,
    RefLieuxProbService,
    VisualisationProbService,
    SuiviReclamationService,
    RefTypeService,
    RefNatureProbService,
    ExportAsService
  ]
})
export class AppMainModule { }