import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class RefAppartementService {

    constructor(private gfxhttp: GfxHttpSrv) { }

    getAppartement(id) {
        return this.gfxhttp.get('appartement/findOne', { idAppartem: id });
    }

    post(data) {
        return this.gfxhttp.post('appartement', data);
    }

    put(data) {
        return this.gfxhttp.put('appartement', data);
    }

    saveFromExcel(data) {
        return this.gfxhttp.post('appartement/saveFromExcel', data);
    }

}
