import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../../helpers/GfxHttpSrv';

@Injectable()
export class SubMenuInfoService {


  constructor(private gfxhttp: GfxHttpSrv) { }

  getListFaq() {
    return this.gfxhttp.get('faq/all');
  }
  post(question, reponse) {
    return this.gfxhttp.post('faq', { question: question, reponse: reponse });
  }

  savePublication(data) {
    return this.gfxhttp.post('mentionslegales', data);
  }

  savePdf(data) {
    return this.gfxhttp.post('aide', data);
  }

  saveContact(data) {
    return this.gfxhttp.post('contact', data);
  }

  saveAffiche(data) {
    return this.gfxhttp.post('residenceaffiches', data);
  }

  updateAffiche(data) {
    return this.gfxhttp.post('residenceaffiches/update', data);
  }

  suppAffiche(idAffiche) {
    return this.gfxhttp.get('residenceaffiches/supprimer', {idAffiche:idAffiche});
  }

  suppFaq(idFaq) {
    return this.gfxhttp.get('faq/supprimer', {idFaq:idFaq});
  }

  suppPublication(idPub) {
    return this.gfxhttp.get('mentionslegales/supprimer', {idPub:idPub});
  }

  getPdfAideList() {
    return this.gfxhttp.get('aide/all');
  }

  getAffichesList() {
    return this.gfxhttp.get('residenceaffiches/all');
  }

  getContact() {
    return this.gfxhttp.get('contact');
  }

  getResidencePublication() {
    return this.gfxhttp.get('mentionslegales/all');
  }
}
