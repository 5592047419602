import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class AppConfig {
    private _conf: Object
    constructor(private http: HttpClient) {
    }

    get(key: any) {
        if (this._conf != null)
            return this._conf[key];
        else
            return "";
    }

    load() {
        return new Promise((resolve, reject) => {
            this.http.get('config/conf.json')
                .pipe(map(res => res as Object))
                .subscribe((configObject) => {
                    this._conf = configObject;
                    resolve();
                })
        });
    }

}