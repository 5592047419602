import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { ColtureReclamService } from '../../services/colture-reclam.service';
import * as moment from 'moment'
import { IGfxListActionComponent } from '../../../../gfx/core/components/gfxlist/IGfxListActionComponent';

@Component({
  selector: 'app-colture-form',
  templateUrl: './colture-form.component.html',
  styleUrls: ['./colture-form.component.css']
})
export class ColtureFormComponent implements OnInit, IGfxListActionComponent {
  SelectedItem
  private clotureForm: FormGroup;
  private cloture = {
    date: '', commentaire: '',
  };
  private msgSucces
  private newDate
  idReclam

  constructor(private fb: FormBuilder, private clotureSrv: ColtureReclamService, private utilSrv: GfxMiscSrv) { }

  ngOnInit() {

    // this.newDate = moment(new Date()).format('YYYY-MM-DD')

    this.idReclam = this.SelectedItem.id

    this.clotureForm = this.fb.group({
      // date: [this.cloture.date, Validators.required],
      commentaire: [this.cloture.commentaire],
    });
  }

  save() {
    let data = this.clotureForm.value;
    const formData: FormData = new FormData();

    // formData.append("date", data.date);
    formData.append("commentaire", data.commentaire);
    formData.append("idReclam", this.idReclam);

    this.clotureSrv.post(formData).subscribe((data) => {
      this.msgSucces = true;
      localStorage.removeItem('idReclam');
    })
  }

  return() {
    sessionStorage.setItem("isReturn", "1");
    this.msgSucces = false;
    this.utilSrv.navigateTo("MesSignalementsComponent");
  }
}
