import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GfxMiscSrv } from '../../../../helpers/gfx.misc.service';
import { SubMenuInfoService } from './sub-menu-info.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ConfirmDialogComponent } from '../../../../core/components/confirm.dialog/confirm.dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-sub-menu-info',
  templateUrl: './sub-menu-info.component.html',
  styleUrls: ['./sub-menu-info.component.css']
})
export class SubMenuInfoComponent implements OnInit {
  msgMenuItem
  subMenuContactMsg1
  subMenuContactMsg2
  subMenuContactMsg3
  isFaq: boolean;
  isContact: boolean;
  isAide: boolean;
  isMentLeg: boolean;
  faqForm: FormGroup;
  pubForm: FormGroup;
  aideForm: FormGroup;
  faqList: any
  pubList: any
  idProfil: string;
  arrayBuffer: any;
  file: File;
  private blobUrl: SafeUrl;
  isSaved: boolean;
  private fileList
  private extensionList
  msgFailedUpload
  pdfList = new Map();
  fileNameList = []
  pdfAideFromServerList = new Map();
  msg: string;
  fileName: string;
  adresse: string;
  tel: string;
  adresseStr: string;
  contactForm: FormGroup;
  contact = ""
  isInfogen: boolean;
  affichesList: any[] = [];
  afficheForm: FormGroup;
  resultat: Boolean;

  constructor(private fb: FormBuilder, private utilSrv: GfxMiscSrv, private srvSubMenu: SubMenuInfoService, private sanitizer: DomSanitizer, private dialog: MatDialog) { }

  ngOnInit() {
    this.isFaq = false;
    this.isContact = false
    this.isAide = false
    this.isMentLeg = false
    this.isInfogen = false;
    this.resultat = false;
    this.msg = ""

    this.faqForm = this.fb.group({
      question: ['', Validators.required],
      reponse: ['', Validators.required],
    });

    this.pubForm = this.fb.group({
      publication: ['', Validators.required],
    });

    this.afficheForm = this.fb.group({
      intitule: ['', [Validators.required]],
      file: ['', [Validators.required]],
      dateComm: ['', [Validators.required]],
    });

    this.aideForm = this.fb.group({
      file: ['', Validators.required],
    });

    this.contactForm = this.fb.group({
      tel: [''],
      adresse: [''],
    });


    this.idProfil = sessionStorage.getItem('idProfile');

    this.srvSubMenu.getListFaq().subscribe((data) => {
      this.faqList = data
    })

    this.srvSubMenu.getResidencePublication().subscribe((data) => {
      this.pubList = data
    })

    this.srvSubMenu.getPdfAideList().subscribe((data) => {
      this.buildPdfAideList(data)
    })

    this.srvSubMenu.getContact().subscribe((data) => {
      if (data && data[0]) {
        this.adresseStr = "Adresse : ";
        this.tel = "Téléphone : " + data[0].tel;
        this.adresse = data[0].adresse;
      }

    })

    if (localStorage.getItem("subMenuMsg")) {
      var str = localStorage.getItem("subMenuMsg")
      this.msgMenuItem = str.substring(1, str.length - 1);
      if (this.msgMenuItem == "F.A.Q") {
        this.isFaq = true;
        this.msgMenuItem = this.msgMenuItem + " (Foire Aux Questions)"
      }
      if (this.msgMenuItem == "Aide") this.isAide = true;
      if (this.msgMenuItem == "contact") this.isContact = true;
      if (this.msgMenuItem == "RGPD") {
        this.isMentLeg = true;
        this.msgMenuItem = this.msgMenuItem + " (Mentions légales)"
      }
      if (this.msgMenuItem == "infoGen") {
        this.msgMenuItem = "Informations générales"
        this.isInfogen = true;
        this.srvSubMenu.getAffichesList().subscribe((data) => {
          this.buildPdfAfficheList(data)
        })
      }
    }

  }

  buildPdfAfficheList(data) {
    if (data && data.length > 0) {
      this.affichesList = []
      data.forEach(affiche => {
        var byteCharacters = atob(affiche.file);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: 'application/pdf' });

        const f: File = <File>Object.assign(blob);
        affiche.file = f;
        this.affichesList.push(affiche);
      });
    }
  }

  buildPdfAideList(data) {
    if (data && data.length > 0) {
      data.forEach(file => {
        var byteCharacters = atob(file[Object.keys(file)[0]]);
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: 'application/pdf' });

        const f: File = <File>Object.assign(blob);
        this.pdfAideFromServerList.set(f, [Object.keys(file)[0]][0]);
      });
    }
  }

  Upload(event) {
    this.isSaved = true
    this.msg = ''
    this.file = event.target.files[0];
    this.fileName = this.file.name;
    var sizeInMB = Number((this.file.size / (1024 * 1024)).toFixed(2));
    if (sizeInMB <= 10) {
      if (this.file, this.file.name.substr(this.file.name.length - 3) == "pdf") {
        if (this.isInfogen) {
          this.afficheForm.value.file = this.file;
        } else {
          this.pdfList.set(this.file, this.file.name);
        }

      } else {
        this.isSaved = false
        this.msg = 'Seuls les formats PDF sont autorisés'
      }
    } else {
      this.isSaved = false
      this.msg = 'Le fichier ne peut pas dépasser ' + 10 + 'MB'
    }

  }

  suppAffiche(idAffiche) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirmation",
        msg: "Êtes-vous sûr de vouloir supprimer cet élément ?",
        libCancelBtn: "Non",
        libConfirmBtn: "Oui",
      },
      height: '200px',
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.srvSubMenu.suppAffiche(idAffiche).subscribe((data) => {
          if (data.retour == 1) {
            this.srvSubMenu.getAffichesList().subscribe((data) => {
              this.buildPdfAfficheList(data)
            })
          }
        })
      }
    })
  }

  deleteFaq(idFaq) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirmation",
        msg: "Êtes-vous sûr de vouloir supprimer cet élément ?",
        libCancelBtn: "Non",
        libConfirmBtn: "Oui",
      },
      height: '200px',
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.srvSubMenu.suppFaq(idFaq).subscribe((data) => {
          if (data.retour == 1) {
            this.srvSubMenu.getListFaq().subscribe((data) => {
              this.faqList = data
            })
          }
        })
      }
    })
  }

  deletePublicRGPD(idPub) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "Confirmation",
        msg: "Êtes-vous sûr de vouloir supprimer cet élément ?",
        libCancelBtn: "Non",
        libConfirmBtn: "Oui",
      },
      height: '200px',
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.srvSubMenu.suppPublication(idPub).subscribe((data) => {
          if (data.retour == 1) {
            this.srvSubMenu.getResidencePublication().subscribe((data) => {
              this.pubList = data
            })
          }
        })
      }
    })
  }

  updateFaq(idFaq) {

  }

  updateAffiche(item) {
    this.afficheForm = this.fb.group({
      id: item.id,
      intitule: [item.intitule, [Validators.required]],
      file: [''],
      dateComm: [item.date, [Validators.required]],
    });
  }

  openPdf(file) {
    window.open(URL.createObjectURL(file));
  }

  getKeys(map) {
    return Array.from(map.keys());
  }
  getValues(map) {
    return Array.from(map.values());
  }

  save(itemSelected) {
    if (itemSelected == "faq") {
      this.srvSubMenu.post(this.faqForm.value.question, this.faqForm.value.reponse).subscribe((data) => {
        this.srvSubMenu.getListFaq().subscribe((data) => {
          this.faqList = data
        })
      })
    }

    if (itemSelected == "RGPD") {
      this.srvSubMenu.savePublication(this.pubForm.value).subscribe((data) => {
        this.srvSubMenu.getResidencePublication().subscribe((data) => {
          this.pubList = data
        })
      })
    }

    if (itemSelected == "aide") {
      const formData: FormData = new FormData();
      let files = Array.from(this.pdfList.keys());

      files.forEach(element => {
        this.file = (element as File)
        formData.append('fileKey', this.file, this.file.name);
      })

      this.srvSubMenu.savePdf(formData).subscribe((data) => {
        if (data.retour == 1) {
          this.isSaved = true;
          this.msg = data.msg;
          this.pdfList.clear();
          this.pdfAideFromServerList.clear();
          this.aideForm = this.fb.group({
            file: ['', Validators.required],
          });
          this.fileName = ""
          this.srvSubMenu.getPdfAideList().subscribe((data) => {
            this.buildPdfAideList(data)
          })
        } else {
          this.isSaved = false;
          this.msg = data.msg
        }
      })
    }

    if (itemSelected == "contact") {

      this.srvSubMenu.saveContact(this.contactForm.value).subscribe((data) => {
        if (data.retour == 1) {
          this.isSaved = true;
          this.msg = data.msg;

          this.contactForm = this.fb.group({
            tel: [''],
            adresse: [''],
          });
          this.fileName = ""
          this.srvSubMenu.getContact().subscribe((data) => {
            if (data && data[0]) {
              this.adresseStr = "Adresse : ";
              this.tel = "Téléphone : " + data[0].tel;
              this.adresse = data[0].adresse;
            }
          })
        } else {
          this.isSaved = false;
          this.msg = data.msg
        }
      })
    }

    if (itemSelected == "affiche") {
      if (this.afficheForm.value.id) {
        const formData: FormData = new FormData();
        formData.append("id", this.afficheForm.value.id);
        if (this.file)
          formData.append("file", this.file);
        else formData.append("file", null);
        formData.append("dateComm", this.afficheForm.value.dateComm);
        formData.append("intitule", this.afficheForm.value.intitule);
        this.srvSubMenu.updateAffiche(formData).subscribe((data) => {
          if (data.retour == 1) {
            this.srvSubMenu.getAffichesList().subscribe((data1) => {
              this.buildPdfAfficheList(data1)
            })
            this.isSaved = true;
            this.msg = data.msg;
            this.afficheForm = this.fb.group({
              intitule: ['', [Validators.required]],
              file: ['', [Validators.required]],
              dateComm: ['', [Validators.required]],
            });
            this.fileName = ""
          } else {
            this.isSaved = false;
            this.msg = data.msg;
          }
        })
      } else {
        const formData: FormData = new FormData();
        formData.append("file", this.file);
        formData.append("dateComm", this.afficheForm.value.dateComm);
        formData.append("intitule", this.afficheForm.value.intitule);
        this.srvSubMenu.saveAffiche(formData).subscribe((data) => {
          if (data.retour == 1) {
            this.srvSubMenu.getAffichesList().subscribe((data) => {
              this.buildPdfAfficheList(data)
            })
            this.isSaved = true;
            this.msg = data.msg;
            this.afficheForm = this.fb.group({
              intitule: ['', [Validators.required]],
              file: ['', [Validators.required]],
              dateComm: ['', [Validators.required]],
            });
            this.fileName = ""
          } else {
            this.isSaved = false;
            this.msg = data.msg;
          }
        })
      }

    }

  }


}
