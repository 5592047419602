import { Injectable } from "@angular/core";
import { GfxHttpSrv } from "../../../gfx/helpers/GfxHttpSrv";

@Injectable()
export class appRefsSrv {

    constructor(private gfxhttp: GfxHttpSrv) { }


   

    getAppartement(id) {
        return this.gfxhttp.get('appartement', { id: id });
    }

    getRefAppartements(motif?) {
        return this.gfxhttp.get('appartement/alldispo', { motif: motif ? motif : "" });
    }

    getRefAppartementsByLocataire(idAppartem, motif?) {
        return this.gfxhttp.get('appartement/allDispoByLocataire', { idAppartem: idAppartem, motif: motif ? motif : "" });
    }

    getListAppartements() {
        return this.gfxhttp.get('appartement');
    }

    getListLieuxProblemes() {
        return this.gfxhttp.get('lieuprobleme');
    }

    getListLocataires() {
        return this.gfxhttp.get('locataire');
    }


    getLocataires() {
        return this.gfxhttp.get('refs/locataires');
    }

    getNaturesProblemes() {
        return this.gfxhttp.get('refs/natures');
    }

    getLieuTravaux() {
        return this.gfxhttp.get('refs/lieu');
    }

    getProfiles() {
        return this.gfxhttp.get('refs/profiles');
    }

    getTypes() {
        return this.gfxhttp.get('type/all');
    }


    getFicheSuiviByReclamation(idReclam) {
        return this.gfxhttp.get('suivireclamation/ficheSuiviByReclamation', { idReclam: idReclam });
    }

    getProfilesByUser(idUser) {
        return this.gfxhttp.get('refs/profilesByUser', { idUser: idUser });
    }


}
