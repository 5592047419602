import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GfxListComponent } from '../core/components/gfxlist/gfx.list.component';
import { GfxExcelService } from '../helpers/GfxExcelService';
import { GfxListPaginatorService } from '../core/components/gfxlist/gfx.list.paginator.service';
import { GfxAppContentService } from '../core/components/app.content/gfx.app.content.service';
import { GfxListService } from '../core/components/gfxlist/gfx.list.service';
import { GfxCoreMaterialModule } from '../core/gfx.core.material.module';
import { HttpClientModule } from '@angular/common/http';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GfxHttpSrv } from '../helpers/GfxHttpSrv';
import { GfxGedService } from '../core/components/gfxged/gfx.ged.service';
import { GfxGedComponent } from '../core/components/gfxged/gfx.ged.component';
import { GfxTraitOkComponent } from '../core/components/gfx-trait-ok/gfx-trait-ok.component';
import { GfxListCustomFilterDirective } from '../core/components/gfxlist/gfx.list.custom.filter.directive';
import { GfxMiscSrv } from '../helpers/gfx.misc.service';
import { WfInstDocsComponent } from '../workflow/wf-inst-docs/wf-inst-docs.component';

@NgModule({
  imports: [
    CommonModule,
    GfxCoreMaterialModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule ,
  ],
  declarations: [GfxListComponent, GfxGedComponent, GfxTraitOkComponent,GfxListCustomFilterDirective,WfInstDocsComponent],
  exports: [GfxListComponent, GfxGedComponent, GfxTraitOkComponent, WfInstDocsComponent],
  providers:[
    GfxExcelService,
    GfxListPaginatorService,
    GfxAppContentService,
    GfxListService,
    GfxHttpSrv,
    GfxGedService,
    GfxMiscSrv
  ]
})
export class GfxSharedModule { }
