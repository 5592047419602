import { Component, OnInit, ViewChild, Input } from "@angular/core";
import { IGfxRefInfo } from "./IGfxRefInfo";
import { MatTableDataSource, MatPaginator, MatDialog, MatSnackBar } from "@angular/material";
import { GfxRefService } from "./gfx.ref.service";
import { Observable } from "rxjs";
import { IGfxRef } from "./IGfxRef";
import { Store } from "@ngrx/store";
import { GfxState, getGfxRefState } from "../store/gfxstate";
import { GfxCommonHelper } from "../helpers/GfxCommonHelper";
import { IGfxRefConfig } from "./IGfxRefConfig";
import { GfxAppContentService } from "../core/components/app.content/gfx.app.content.service";
import { GfxRefFormComponent } from "./gfx.ref.form";
import { IGfxRefForm } from "./IGfxRefForm";
import { ConfirmDialogComponent } from "../core/components/confirm.dialog/confirm.dialog.component";
import { IGfxRefFormElement } from "./IGfxRefFormElement";
import { IGfxRefFormElementType } from "./IGfxRefFormElementType";
import { GfxRefInputTypeEnum } from "./GfxRefInputTypeEnum";
import { GfxRefAction, UPDATEREF } from "../store/actions/gfxref";
import { IGfxRefDetail } from "./IGfxRefDetail";

@Component({
    selector: "gfx-ref-component",
    templateUrl: "./gfx.ref.component.html",
    styles: [`
    .mat-expansion-panel-body {
        padding: 0;
    }
    .form-container .mat-dialog-container {
        padding: 0px;
    }
    `]
})
export class GfxRefComponent implements OnInit {
    @Input() refconfig: IGfxRefConfig;
    @Input() sourcecomponent: string;
    @Input() refinfo: IGfxRefInfo;
    columnsList: string[] = [];
    dataSource: MatTableDataSource<{}>;
    refObs: Observable<IGfxRef>;
    currentRef: IGfxRef;

    @ViewChild(MatPaginator) paginator: MatPaginator;
    ngOnInit(): void {
        this.refService.getRef(this.refinfo);
        this.refObs = this.store.select(getGfxRefState);
        this.refObs.subscribe((ref: IGfxRef) => {
            this.currentRef = ref;
            const elementsLength = this.commonHelper.getObjectPropertyByPath(
                ["Detail", "Form", "Elements", "length"], this.currentRef
            );

            if (elementsLength) {
                this.columnsList = this.currentRef.Detail.Form.Elements.map((fe) => fe.Name);
                this.columnsList.push("GfxRefActions");
                this.dataSource = new MatTableDataSource(this.currentRef.Detail.Data);
                this.dataSource.paginator = this.paginator;
            }
        })

    }
    search(value: string) {
        this.dataSource.filter = value.trim();
    }


    openRefForm(refInfo: IGfxRefInfo, frmConfig: IGfxRefForm, editMode: boolean) {
        let dataObj = {
            Form: { ...frmConfig },
            RefInfo: { ...refInfo },
            EditMode: editMode
        }

        let dialogRef = this.dialog.open(GfxRefFormComponent, {
            width: '700px',
            panelClass: 'form-container',
            data: dataObj
        });
    }
    deleteRefElement(elem: any) {
        let dialogRef = this.dialog.open(ConfirmDialogComponent, {
            data: {

                msg: "Etes-vous sûr de vouloir supprimer le l'élément ?"
            },
            height: '200px',
            width: '300px',
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == true) {
                this.refService.deleteRefElem(this.refinfo, elem.id).subscribe((result: IGfxRefDetail) => {
                    const currRef: IGfxRef = {
                        Detail: result,
                        Info: this.refinfo
                    }
                    this.store.dispatch(new GfxRefAction(UPDATEREF, currRef));
                    dialogRef.close();
                    this.refService.getRef(this.refinfo);
                    this.snackBar.open('Élément supprimé avec succès', 'Ok', {
                        duration: 500,
                    });
                });
    
            }
        });
    }

    EditRefElem(elemData: any) {
        let newForm: IGfxRefForm = { ...this.currentRef.Detail.Form }
        newForm.Elements.forEach((elem: IGfxRefFormElement) => {
            switch (elem.Type.InputType) {
                case GfxRefInputTypeEnum.Text:
                    elem.Value = elemData[elem.Name];
                    break;
                case GfxRefInputTypeEnum.Select:
                    elem.Value = elemData[elem.Name].Value;
                    break;
                case GfxRefInputTypeEnum.Checkbox:
                    elem.Value = elemData[elem.Name].Value;
                    break;
                case GfxRefInputTypeEnum.Radio:
                    elem.Value = elemData[elem.Name].Value;
                    break;
                default:
                    elem.Value = elemData[elem.Name];
                    break;

            }
        })

        newForm.Id = elemData.id;
        
        this.openRefForm(this.refinfo, newForm, true);

    }
    backToSrcComponent() {
        this.appContentService.changeContent.emit({ ComponentName: this.sourcecomponent, Data: {} });
    }
    constructor(
        private refService: GfxRefService,
        private store: Store<GfxState>,
        private commonHelper: GfxCommonHelper,
        private appContentService: GfxAppContentService,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,

    ) {

    }
}