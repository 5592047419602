import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class DeclarProbService {

  constructor(private gfxhttp: GfxHttpSrv) { }

  getLieuxProblemes() {
    return this.gfxhttp.get('lieuprobleme/all');
  }

  getNaturesProblemes() {
    return this.gfxhttp.get('natureprobleme/all');
  }

  getLocataires(motif?) {
    return this.gfxhttp.get('locataire/all', { motif: motif ? motif : ""});
  }

  getAppartementByLocataire() {
    return this.gfxhttp.get('appartement/appartementByLocataire');
  }

  getAppartementByIdLocataire(idLoc) {
    return this.gfxhttp.get('appartement/appartementByIdLocataire', {idLoc: idLoc});
  }

  getNatureById(id) {
    return this.gfxhttp.get('natureprobleme/natureById', {id:id});
  }

  getLieuById(id) {
    return this.gfxhttp.get('lieuprobleme/lieuById', {id:id});
  }


  post(data) {
    return this.gfxhttp.post('appartement', data);
  }

  put(data) {
    return this.gfxhttp.put('appartement', data);
  }

  saveIncident(data) {
    return this.gfxhttp.post('incident', data);
  }

}
