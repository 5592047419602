import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { IGfxListActionComponent } from "../../core/components/gfxlist/IGfxListActionComponent";
import { GfxAppContentService } from "../../core/components/app.content/gfx.app.content.service";
import { UsersService } from "../services/users.service";
import { GfxDictionary } from "../../core/models/GfxDictionary";
import { GfxChangeContentEventData } from "../../core/components/app.content/gfx.change.content.event.data";
import { ConfirmDialogComponent } from "../../core/components/confirm.dialog/confirm.dialog.component";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { appRefsSrv } from "../../../app/common/services/app.refs.service";
import { ISelectData } from "../../../app/common/models/ISelectData";
import { GfxMiscSrv } from "../../../app/../gfx/helpers/gfx.misc.service";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";

@Component({
  selector: "gfx-user-form",
  templateUrl: "gfx.user.form.component.html"
})
export class GfxUserFormComponent implements OnInit, IGfxListActionComponent {

  SourceComponentName: string;
  msgErr;
  msg = ''
  SelectedItem;
  private userForm: FormGroup;
  private user = {
    id: '', login: '', password: '', nom: '', tel: '', profil: '', mail: '', actif: ''
  };
  private profiles: ISelectData;

  constructor(private appContentService: GfxAppContentService, private usersService: UsersService,
    private fb: FormBuilder, private refsrv: appRefsSrv, private utilSrv: GfxMiscSrv) {

  }

  ngOnInit(): void {
    this.userForm = this.fb.group({
      login: [this.user.login, Validators.required],
      password: ['', Validators.required],
      nom: [this.user.nom],
      tel: [this.user.tel, [Validators.pattern(/^\d{10}$/)]],
      profil: [this.user.profil, Validators.required],
      mail: [this.user.mail, [Validators.email]],
      actif: [this.user.actif]
    });

    if (this.SelectedItem && this.SelectedItem.id) {
      this.usersService.get(this.SelectedItem.id).subscribe((data) => {
        this.user = data;
        this.userForm = this.fb.group({
          id: [this.user.id],
          login: [this.user.login, Validators.required],
          password: [this.user.password],
          nom: [this.user.nom],
          tel: [this.user.tel, [Validators.pattern(/^\d{10}$/)]],
          profil: [this.user.profil, Validators.required],
          mail: [this.user.mail, Validators.email],
          actif: [this.user.actif]
        });
      });
    }

    this.refsrv.getProfiles().subscribe((data) => {
      this.profiles = data;
    });
  }

  isPwdRequired() {
    return !this.user.id;
  }

  clearTel() {
    if (this.userForm.value.tel == "") {
      if (this.userForm.value.id) {
        this.userForm = this.fb.group({
          id: [this.userForm.value.id],
          login: [this.userForm.value.login, Validators.required],
          password: [this.userForm.value.password],
          nom: [this.userForm.value.nom],
          tel: ['', [Validators.pattern(/^\d{10}$/)]],
          profil: [this.userForm.value.profil, Validators.required],
          mail: [this.userForm.value.mail, Validators.email],
          actif: [this.userForm.value.actif]
        });
      } else {
        this.userForm = this.fb.group({
          id: [this.userForm.value.id],
          login: [this.userForm.value.login, Validators.required],
          password: [this.userForm.value.password],
          nom: [this.userForm.value.nom],
          tel: ['', [Validators.pattern(/^\d{10}$/)]],
          profil: [this.userForm.value.profil, Validators.required],
          mail: [this.userForm.value.mail, Validators.email],
          actif: [this.userForm.value.actif]
        });
      }

    }
  }

  return() {
    this.utilSrv.navigateTo("GfxUsersManagComponent");
  }

  save() {

    this.userForm.value.actif = this.userForm.value.actif ? 1 : 0;
    if (this.userForm.value.id && !this.userForm.value.password) {
      delete this.userForm.value.password;
    }

    if (this.userForm.value.id) {
      this.usersService.put(this.userForm.value).subscribe((res) => {
        if (res.retour && res.retour != 1) {
          this.msgErr = true;
          this.msg = res[0];
        } else {
          this.downloadPdf(res.loginFile, "login")
          this.return();
        }

      })
    } else {
      this.usersService.post(this.userForm.value).subscribe((res) => {
        if (!res.idUser) {
          this.msgErr = true;
          this.msg = res[0];
        } else {
          this.downloadPdf(res.loginFile, "login")
          this.return();
        }
      })
    }
  }


  downloadPdf(file, filename) {
    var byteCharacters = atob(file);
    var byteNumbers = new Array(byteCharacters.length);
    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    var blob = new Blob([byteArray], { type: 'application/pdf' });
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob);
      return;
    }
    const data = window.URL.createObjectURL(blob);

    var link = document.createElement('a');
    link.href = data;
    link.download = filename + ".pdf";
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(function () {
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 1000);
  }
}