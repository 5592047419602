import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { AppConfig } from "../core/services/AppConfig";


@Injectable()
export class GfxHttpSrv {
    beUrl: any;

    constructor(private http: HttpClient, private appConfig: AppConfig) {
        this.beUrl = appConfig.get("BackendUrl") + '/';
    }
    get(url, data?, paramsReady?) {
        let params;
        if (!paramsReady) {
            params = new HttpParams();
            if (data) {
                Object.keys(data).forEach(function (item) {
                    params = params.set(item, data[item]);
                });
            }
        } else {
            params = data;
        }

        return this.http.get<any>(url, { params });
    };

    post(url, data) {
        // return this.utlSrv.openDialog(GfxMsgValidComponent,data);
        return this.http.post<any>(url, data)
        // .subscribe(
        //     data => console.log('success', data),
        //     error => this.utlSrv.openDialog(GfxMsgValidComponent,error.error.text)
        //   );
    }

    put(url, data) {
        const params = new HttpParams().set("id", data.id);
        return this.http.put<any>(url, data, { params });
    }

    delete(url, id) {
        const params = new HttpParams().set("id", id);
        return this.http.delete<any>(url, { params });
    }

    resend(absoluteUrl, method, data) {
        console.log(absoluteUrl, method, data);
        
        data.msgConfirmOk = true;
        let url = absoluteUrl.slice(absoluteUrl.indexOf(this.beUrl) + this.beUrl.length);
        if (method == 'POST') {
            console.log('post');
            
            this.post(url, data);
        } else if (method == 'PUT') {
            console.log('put');
            this.put(url, data);
        }

    }

}
