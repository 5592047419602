import { Component, Inject, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog, MatSnackBar } from "@angular/material";
import { IGfxRefInfo } from "./IGfxRefInfo";
import { IGfxRefForm } from "./IGfxRefForm";
import { FormGroup, FormControl, FormBuilder, FormArray, Validators } from "@angular/forms";
import { IGfxRefFormElement } from "./IGfxRefFormElement";
import { element } from "protractor";
import { GfxRefInputTypeEnum } from "./GfxRefInputTypeEnum";
import { ConfirmDialogComponent } from "../core/components/confirm.dialog/confirm.dialog.component";
import { Observable } from "rxjs";
import { IGfxOpResObj } from "../core/models/IGfxOpResObj";
import { GfxRefService } from "./gfx.ref.service";
import { IGfxRef } from "./IGfxRef";
import { GfxState } from "../store/gfxstate";
import { Store } from "@ngrx/store";
import { IGfxRefDetail } from "./IGfxRefDetail";
import { GfxRefAction, UPDATEREF } from "../store/actions/gfxref";

@Component({
    selector: "gfx-ref-form",
    templateUrl: "./gfx.ref.form.html",
    styleUrls: ["./gfx.ref.form.css"]

})
export class GfxRefFormComponent implements OnInit {
    refInfo: IGfxRefInfo;
    refForm: IGfxRefForm;
    editMode: boolean = false;
    form: FormGroup;
    oldValues: any = {}
    inputTypeEnum = GfxRefInputTypeEnum;
    idRefElem: any = undefined;
    ngOnInit(): void {
        this.form = new FormGroup({});
        [...this.refForm.Elements].forEach((elem: IGfxRefFormElement) => {
            let validatorsArr: any[] = [];
            if (elem.Validation.Required)
                validatorsArr.push(Validators.required);
            const fc = new FormControl('', validatorsArr);
            if (this.editMode) {
                fc.setValue(elem.Value);
                this.idRefElem = this.refForm.Id;
            }

            this.form.addControl(elem.Name, fc);
        })

        this.oldValues = this.form.getRawValue();


    }
    Valider() {
        if (this.editMode)
            this.refService.editRefElem(this.refInfo, this.form.getRawValue(), this.idRefElem).subscribe((result: IGfxRefDetail) => {
                const currRef: IGfxRef = {
                    Detail: result,
                    Info: this.refInfo
                }
                this.store.dispatch(new GfxRefAction(UPDATEREF, currRef));
                this.dialogRef.close();
            });

        else
            this.refService.addRefElem(this.refInfo, this.form.getRawValue()).subscribe((result: IGfxRefDetail) => {
                const currRef: IGfxRef = {
                    Detail: result,
                    Info: this.refInfo
                }
                this.store.dispatch(new GfxRefAction(UPDATEREF, currRef));
                this.dialogRef.close();
            });

    }
    Annuler() {
        const newValues = this.form.getRawValue();
        let difference = Object.keys(newValues).reduce((difference, key) => {
            if (this.oldValues[key] === newValues[key]) return difference
            return {
                ...difference,
                [key]: newValues[key]
            }
        }, {})
        if (Object.keys(difference).length > 0) {
            let dialogConfirm = this.dialog.open(ConfirmDialogComponent, {
                data: {

                    msg: "Vos modifications vont être annulées. Êtes-vous sûr(e) de vouloir continuer?"
                },
                height: '200px',
                width: '300px',
            });

            dialogConfirm.afterClosed().subscribe(result => {
                if (result == true) {
                    this.dialogRef.close();
                }
            });
        } else
            this.dialogRef.close();

    }

    constructor(public dialogRef: MatDialogRef<GfxRefFormComponent>,
        @Inject(MAT_DIALOG_DATA)
        private data: any,
        private store: Store<GfxState>,
        public dialog: MatDialog,
        public snackBar: MatSnackBar,
        private refService: GfxRefService) {
        this.refInfo = <IGfxRefInfo>this.data.RefInfo;
        this.refForm = <IGfxRefForm>this.data.Form;
        this.editMode = <boolean>this.data.EditMode;

    }
}