import { Component } from "@angular/core";
import { GfxAppContentService } from "../../core/components/app.content/gfx.app.content.service";
import { WorkflowService } from "../Services/workflow.service";
import { GfxState, getUserState } from "../../store/gfxstate";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { IUser } from "../../authentication/models/IUser";

@Component({
    selector : "gfx-wf-tasks",
    templateUrl : "gfx.wf.tasks.component.html"
})
export class GfxWfTasksComponent {
    SelectedItem: any;
    wroflows: [any];
    title = "Mes tâches en instance";
    wfSpecData = {};
    public currUser: IUser;

    constructor(private appContentService: GfxAppContentService, private wfService : WorkflowService, private store: Store<GfxState>,) {

    }
    
    ngOnInit() {
        //Get current user
        let usrObs: Observable<IUser>;
        usrObs = this.store.select(getUserState);
        let sub = usrObs.subscribe((s) => {
            this.currUser = s;
        });
        let obs = this.wfService.getPendingTasks(this.currUser.idProfile);
        obs.subscribe((retour : any) => {
            this.wroflows = retour.lines;
        });
    }

    openList(wf)
    {
        let obsSpec = this.wfService.getWfSpecData(wf);
        obsSpec.subscribe((retour : any) => {
            this.wfSpecData = retour;
            this.appContentService.changeContent.emit({ ComponentName: "GfxWfSpecList", Data: {curItemWFPending:this.wfSpecData, wfData:wf} });
        });

    };
}