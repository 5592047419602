import * as mainComponent from './containers/main/main.component';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { GfxThemeModule } from '../theme/gfx.theme.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppConfig } from './services/AppConfig';
import { AuthService } from '../authentication/services/AuthService';
import { IntermediateHookService } from './services/IntermediateHookService';
import { RouterModule, Routes } from '@angular/router';
import { AppGuard } from './services/AppGuard';
import { GfxHttpInterceptor } from '../helpers/GfxHttpInterceptor';
import { StoreModule } from '@ngrx/store'
import { reducers } from '../store/gfxstate'
import { AppMenu } from './services/AppMenu';
import { ConfirmDialogComponent } from './components/confirm.dialog/confirm.dialog.component';
import { GfxCoreMaterialModule } from './gfx.core.material.module';
import { NoteService } from '../notes/services/note.service';
import { ReactiveFormsModule } from '@angular/forms';
import { GfxUsersManagComponent } from '../admin/users/gfx.users.management.component';
import { GfxProfilesManagComponent } from '../admin/profiles/gfx.profiles.management.component';
import { GfxErrorsComponent } from '../error/components/gfx.errors.component';
import { GfxWfMainComponent } from '../workflow/containers/gfx.wf.main.component';
import { GfxWfTasksComponent } from '../workflow/containers/gfx.wf.tasks.component';
import { GfxRefContainerComponent } from '../ref/gfx.ref.container';
import { GfxWfSpecList, NoteEqualPipe } from '../workflow/containers/gfx.wf.spec.list';
import { GfxWfView } from '../workflow/containers/gfx.wf.view';
import { MatPaginatorIntl } from '@angular/material';
import { GfxUserFormComponent } from '../admin/users/gfx.user.form.component';
import { GfxListMatPaginatorIntlFr } from './components/gfxlist/gfx.list.mat.paginator.intl.service';
import { GfxExcelService } from '../helpers/GfxExcelService';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { GfxUsersListFilterComponent } from '../admin/users/gfx.users.list.filter';
import { UsersService } from '../admin/services/users.service';
import { ProfileService } from '../admin/services/profile.service';
import { Note } from "../notes/components/note.component";
import { NoteList } from "../notes/components/note.list.component";
import { NoteForm } from "../notes/components/note.form.component";
import { GfxDemoComponent } from '../../demo/containers/gfx.demo.component';
import { GfxProfileFormComponent } from '../admin/profiles/gfx.profile.form.component';
import { GfxWorkflowsListFilterComponent } from '../workflow/containers/gfx.workflows.list.filter';
import { WorkflowService } from '../workflow/Services/workflow.service';
import { DemoGfxGedComponent } from '../../demo/components/gfxged/demo.ged.component';
import { GfxUpDownHelper } from '../helpers/GfxUpDownHelper';
import { FilePreviewDialogComponent } from './components/file.preview/file.preview.component';
import { PdfViewerModule } from 'ng2-pdf-viewer/ng2-pdf-viewer'
import { GfxNotificationsComponent } from './components/gfxnotifications/gfx.notifications.component';
import { GfxNotificationsService } from './components/gfxnotifications/gfx.notifications.service';
import { GfxErrorModule } from '../error/gfx.error.module';
import { GfxErrorDialogComponent } from '../error/components/gfx.error.dialog.component';
import { DemoGfxRefComponent } from '../../demo/components/ref/demo.ref.component';
import { GfxRefService } from '../ref/gfx.ref.service';
import { GfxRefComponent } from '../ref/gfx.ref.component';
import { GfxCommonHelper } from '../helpers/GfxCommonHelper';
import { GfxRefFormComponent } from '../ref/gfx.ref.form';
import { GfxTypeGetterPipe } from '../helpers/GfxTypeGetterPipe';
import { RefCustomComponent } from '../../demo/components/ref/demo.ref.custom.component';
import { GfxMiscSrv } from '../../app/../gfx/helpers/gfx.misc.service';

import { GfxAproposComponent } from '../theme/default/containers/user.menu/gfx.apropos.component';
import { GfxProfilComponent } from '../theme/default/containers/user.menu/profil.user.component';
import { ProfilUserService } from '../theme/default/containers/user.menu/services/profil.user.service';
import { appWfSrv } from '../../app/common/services/app.wf.service';
import { WfInstancesComponent } from '../workflow/wf-instances/wf-instances.component';
import { WfInstancesListComponent } from '../workflow/wf-instances-list/wf-instances-list.component';
import { WfTransitionDiagComponent } from '../workflow/wf-transition-diag/wf-transition-diag.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { UsrProfilSecComponent } from '../admin/users/usr-profil-sec/usr-profil-sec.component';
import { ProfilDroitsComponent } from '../admin/profiles/profil-droits/profil-droits.component';
import { GfxMsgValidComponent } from './components/gfx-msg-valid/gfx-msg-valid.component';
import { NgxPrintModule } from 'ngx-print';
import { GfxModalUtilComponent } from './components/gfx-modal-util/gfx-modal-util.component';
import { GfxAppContentModalComponent } from './components/app.content/gfx.app.content.modal.component.';
import { AppMainModule } from '../../app/app.main.module';
import { GfxSharedModule } from '../gfx-shared/gfx-shared.module';
import { GfxDefaultViewComponent } from '../theme/default/components/gfx-default-view/gfx-default-view.component';
import { WfInstLogComponent } from '../workflow/wf-inst-log/wf-inst-log.component';
import { BeMsgErrorComponent } from './components/be-msg-error/be-msg-error.component';
import { LoginComponent } from './containers/main/login/login.component';
import { HomeComponent } from './containers/main/home/home.component';
import { ResidenceLoginComponent } from '../theme/residence_theme/containers/residence-login/residence-login.component';
import { ResidenceHomeComponent } from '../theme/residence_theme/containers/residence-home/residence-home.component';
import { RecuperationPwdComponent } from '../theme/residence_theme/containers/recuperation-pwd/recuperation-pwd.component';
import { ChangerPwdFirstAccessComponent } from '../theme/residence_theme/containers/changer-pwd-first-access/changer-pwd-first-access.component';

export function appConfigFactory(conf: AppConfig) {
  return () => conf.load();
}
const gfxRoutes: Routes = [
  { path: 'login', component: ResidenceLoginComponent },
  { path: 'recuperationPwd', component: RecuperationPwdComponent },
  { path: 'changerPwd', component: ChangerPwdFirstAccessComponent },
  //{ path: 'home', redirectTo: 'home', pathMatch: 'full'},
  { path: '', component: ResidenceHomeComponent, canActivate: [AppGuard] },
  { path: '**', component: ResidenceHomeComponent }
];
@NgModule({
  declarations: [
    mainComponent.MainComponent,
    GfxTypeGetterPipe,
    FilePreviewDialogComponent,
    ConfirmDialogComponent,
    GfxErrorDialogComponent,
    GfxRefContainerComponent,
    GfxRefComponent,
    GfxRefFormComponent,
    GfxNotificationsComponent,
    GfxUsersManagComponent,
    GfxUsersListFilterComponent,
    GfxUserFormComponent,
    GfxProfilesManagComponent,
    GfxProfileFormComponent,
    GfxErrorsComponent,
    GfxWfMainComponent,
    GfxWorkflowsListFilterComponent,
    GfxWfTasksComponent,
    GfxWfSpecList,
    NoteEqualPipe,
    GfxWfView,
    DemoGfxGedComponent,
    DemoGfxRefComponent,
    NoteList,
    NoteForm,
    Note,
    GfxDemoComponent,
    GfxAproposComponent,
    GfxProfilComponent,
    WfInstancesComponent,
    WfInstancesListComponent,
    WfTransitionDiagComponent,
    UsrProfilSecComponent,
    ProfilDroitsComponent,
    GfxMsgValidComponent,
    GfxModalUtilComponent,
    GfxAppContentModalComponent,
    RefCustomComponent,
    GfxDefaultViewComponent,
    WfInstLogComponent,
    BeMsgErrorComponent,
    LoginComponent,
    HomeComponent
  ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(gfxRoutes),
    GfxErrorModule,
    GfxCoreMaterialModule,
    GfxThemeModule,
    HttpClientModule,
    StoreModule.forRoot(reducers),
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    PdfViewerModule,
    GfxSharedModule,
    AppMainModule,
    HighchartsChartModule,
    NgxPrintModule,
    GfxCoreMaterialModule
  ],
  providers: [
    AppConfig,
    AppMenu,
    GfxHttpInterceptor,
    AppGuard,
    AuthService,
    GfxExcelService,
    GfxUpDownHelper,
    GfxCommonHelper,
    GfxRefService,
    // GfxListService,
    // GfxListPaginatorService,
    GfxNotificationsService,
    // GfxAppContentService,
    IntermediateHookService,
    ProfileService,
    UsersService,


    {
      provide: APP_INITIALIZER,
      useFactory: appConfigFactory,
      deps: [AppConfig],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GfxHttpInterceptor,
      multi: true
    },
    NoteService,
    { provide: MatPaginatorIntl, useClass: GfxListMatPaginatorIntlFr },
    WorkflowService,
    appWfSrv,
    GfxMiscSrv,
    ProfilUserService
  ],
  entryComponents: [
    FilePreviewDialogComponent,
    ConfirmDialogComponent,
    GfxErrorDialogComponent,
    GfxUsersManagComponent,
    GfxProfilesManagComponent,
    GfxErrorsComponent,
    GfxWfMainComponent,
    GfxWorkflowsListFilterComponent,
    GfxWfTasksComponent,
    GfxRefContainerComponent,
    GfxRefComponent,
    GfxRefFormComponent,
    GfxWfSpecList,
    GfxWfView,
    GfxUserFormComponent,
    GfxProfileFormComponent,
    GfxUsersListFilterComponent,
    GfxNotificationsComponent,
    DemoGfxGedComponent,
    NoteList,
    NoteForm,
    Note,
    GfxDemoComponent,
    GfxAproposComponent,
    GfxProfilComponent,
    WfInstancesComponent,
    WfInstancesListComponent,
    WfTransitionDiagComponent,
    UsrProfilSecComponent,
    ProfilDroitsComponent,
    GfxMsgValidComponent,
    GfxModalUtilComponent,
    GfxAppContentModalComponent,
    RefCustomComponent,
    GfxDefaultViewComponent,
    WfInstLogComponent,
    BeMsgErrorComponent
  ],
  bootstrap: [mainComponent.MainComponent]
})
export class GfxCoreModule {

}