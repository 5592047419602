import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { IAuthObject } from '../models/IAuthObject';
import { GfxState } from '../../store/gfxstate';
import { Store } from '@ngrx/store';
import { AuthAction, USRLOGGEDIN, USRLOGGEDOUT } from '../../store/actions/auth';
import { IUser } from '../models/IUser';
import { IGfxOpResObj } from '../../core/models/IGfxOpResObj';
import { GfxHttpSrv } from '../../helpers/GfxHttpSrv';
import { IRecuperationPwd } from '../../core/models/IRecuperationPwd';

@Injectable()
export class AuthService {
    constructor(private http: HttpClient, private store: Store<GfxState>, private gfxhttp: GfxHttpSrv) { }
    authProcessed: EventEmitter<IGfxOpResObj> = new EventEmitter<IGfxOpResObj>();
    recuperationPwd: EventEmitter<IRecuperationPwd> = new EventEmitter<IRecuperationPwd>();
    login(authObj: IAuthObject) {

        this.http.post<any>('auth/login', { usr: authObj.utilisateur, pwd: authObj.motDePasse })
            .subscribe((res: IUser) => {
                if (!res.token) {
                    let msg = res[0];
                    if (typeof res == "string")
                        msg = res;
                    this.authProcessed.emit({
                        OpSuccess: 0,
                        Msg: msg
                    })
                } else {
                    sessionStorage.setItem('curUser', JSON.stringify(res));
                    sessionStorage.setItem('idProfile', JSON.stringify(res.idProfile));
                    this.store.dispatch(new AuthAction(USRLOGGEDIN, res));
                }
            });
    }

    saveCodeRecupPwd(login, email) {
        return this.gfxhttp.get('auth/saveCodeRecupPwd', { login: login, mail: email })
    }

    saveResetPwd(login, pwd) {
        return this.gfxhttp.get('auth/saveResetPwd', { login: login, pwd: pwd })
    }

    getRecupPwd(login) {
        return this.gfxhttp.get('auth/recuperationPwd', { login: login })
    }

    logout() {
        sessionStorage.removeItem('curUser');
        sessionStorage.removeItem('idProfile');
        this.store.dispatch(new AuthAction(USRLOGGEDOUT, null));
    }




}