import { Component, OnInit, Injector } from '@angular/core';
import { GfxListFilterComponent } from '../../../../gfx/core/components/gfxlist/GfxListFilterComponent';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DeclarProbService } from '../../services/declar-prob.service';

@Component({
  selector: 'app-appartement-list-filter',
  templateUrl: './appartement-list-filter.component.html',
  styleUrls: ['./appartement-list-filter.component.css']
})
export class AppartementListFilterComponent extends GfxListFilterComponent implements OnInit {
  idProfil

  Types: any[]
  Lieux: any[]
  Status: any[]
  SelectedType: any;
  SelectedStatus: any;
  SelectedLieu: any;
  motif: { motif: string; };
  filteredOptions: any[];
  isLoading: boolean;
  private filterForm: FormGroup;

  constructor(injector: Injector, private fb: FormBuilder) {
    super(injector);
  };

  ngOnInit(): void {
    this.idProfil = sessionStorage.getItem('idProfile');

    this.filterForm = this.fb.group({
      appartement: [""],
      adresse: [""]
    });

  }

  Actualiser() {
    let dataFilter = this.filterForm.value;

    this.SetElementValue("appartement", dataFilter.appartement);
    this.SetElementValue("adresse", dataFilter.adresse);
    this.RefreshList();
  }
}
