import { Component, OnInit } from '@angular/core';
import { IGfxListConfig } from '../../../../gfx/core/components/gfxlist/IGfxListConfig';
import { GfxAppContentService } from '../../../../gfx/core/components/app.content/gfx.app.content.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { appRefsSrv } from '../../../common/services/app.refs.service';
import { GfxListOrderDirectionEnum } from '../../../../gfx/core/components/gfxlist/GfxListOrderDirectionEnum';
import { RefAppartemListFilterComponent } from '../ref-appartem-list-filter/ref-appartem-list-filter.component';
import { ISelectData } from '../../../common/models/ISelectData';
import { FormGroup, FormBuilder } from '@angular/forms';
import { AppartementListFilterComponent } from '../../../reclam/components/appartement-list-filter/appartement-list-filter.component';

@Component({
    selector: 'app-ref-appartem-list',
    templateUrl: './ref-appartem-list.component.html',
    styleUrls: ['./ref-appartem-list.component.css']
})
export class RefAppartemListComponent implements OnInit {
    
  private appartemList: any;
  private idProfil;
  private filterForm: FormGroup;
  listCfg: IGfxListConfig;

  constructor(private fb: FormBuilder, private appRefSrv: appRefsSrv, private utilSrv: GfxMiscSrv) {

  }

  ngOnInit() {
    this.idProfil = sessionStorage.getItem('idProfile');

    this.listCfg = {
      Code: "appartementList",
      Title: "Liste des appartements",
      DataUrl: "appartement",
      ListOptions: {
        OrderBy: "id",
        OrderDir: GfxListOrderDirectionEnum.ASC,
        HeaderPanelExpanded: false,
        EnableBuiltInFilter: true,
        EnableCustomFilter: true,
        ExpandCustomFilter: false,
      },
      Columns: [
        // { Name: "lib", Label: "LIBELLÉ", Render: "lib" },
       
        { Name: "code", Label: "Code", Render: "code" },
        { Name: "adresse", Label: "Adresse", Render: "adresse" },
      ],
      
      Filter: {
        CustomFilterComponent: AppartementListFilterComponent,
        FilterElements: [
          { Name: "appartement", Field: "f1", Operator: "=", Value: "" },
          { Name: "adresse", Field: "f2", Operator: "Like", Value: "" }
        ]
      },

      Actions: [
        { Name: "Edit", Label: "Modifier", ComponentName: "RefAppartementFormComponent", FaIcon: "fa-edit", Params:{hideAction: false}  },
      ],

      PaginatorConfig: {
        StaticMode: false,
        PageSize: 10,
        SizeOptions: [10, 20, 50, 100],
        ShowFirstLastBtns: true
      },
      ExportOptions: {
        FileName: "appartement",
        Columns: ["Libelle", "code"],
        EnableEXCEL: true,
        EnableWORD: false,
        EnablePDF: false,
      }
    }

  }

  NewAppart(){
    this.utilSrv.navigateTo("RefAppartementFormComponent");
  }

}
