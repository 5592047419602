import { Component, ViewChild, Input, OnInit } from "@angular/core";
import { IGfxFile } from "./IGfxFile";
import { GfxGedFileTypeEnum } from "./GfxGedFileTypeEnum";
import { MatMenuTrigger, MatDialog, MatSnackBar, MatTableDataSource, MatPaginator } from "@angular/material";
import { GfxGedService } from "./gfx.ged.service";
import { Observable } from "rxjs";
import * as FileSaver from "file-saver";
import { FilePreviewDialogComponent } from "../file.preview/file.preview.component";
import { IGfxGedConfig } from "./IGfxGedConfig";
import { IGfxGedData } from "./IGfxGedData";
import { Store } from "@ngrx/store";
import { GfxState, getGfxGedDataSelector } from "../../../store/gfxstate";
import { HttpClient } from "@angular/common/http";
import { IGfxOpResObj } from "../../models/IGfxOpResObj";
import { GfxGedRenderingMode } from "./GfxGedRenderingMode";
import { ConfirmDialogComponent } from "../confirm.dialog/confirm.dialog.component";

@Component({
  selector: "gfx-ged",
  templateUrl: "./gfx.ged.component.html",
  styleUrls: ["./gfx.ged.component.css"]
})
export class GfxGedComponent implements OnInit {
  @Input("config") config: IGfxGedConfig;
  @ViewChild("gedFileInputView") gedFileInputView;
  @ViewChild("drawer") drawer;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  renderedColumns: any[];
  dataSource: MatTableDataSource<{}>;
  fileToUpload: File;
  selectedFile: IGfxFile;
  editMode: boolean = false;
  currentOperation: string = "";
  processing: boolean = false;
  uploading: boolean = false;
  uploadProgress: Observable<number>;
  gedBackendAPI = {};
  currentFiles: IGfxFile[] = [];
  dataObs: Observable<IGfxGedData>;
  currDescription: string = "";
  currentRenderingMode: GfxGedRenderingMode;
  getFontAwesomeIcon(type: GfxGedFileTypeEnum): string {
    let iconStr: string = "fa-file";
    switch (type) {
      case GfxGedFileTypeEnum.Excel:
        iconStr = "fa-file-excel";
        break;
      case GfxGedFileTypeEnum.PDF:
        iconStr = "fa-file-pdf";
        break;
      case GfxGedFileTypeEnum.PowerPoint:
        iconStr = "fa-file-powerpoint";
        break;
      case GfxGedFileTypeEnum.Text:
        iconStr = "fa-file-text";
        break;
      case GfxGedFileTypeEnum.WORD:
        iconStr = "fa-file-word";
        break;
      case GfxGedFileTypeEnum.PNG:
        iconStr = "fa-image";
        break;
      case GfxGedFileTypeEnum.JPG:
        iconStr = "fa-image";
        break;
      default:
        iconStr = "fa-file";
        break;
    }
    return iconStr;
  }

  getFileTypeViaExtension(ext: string): GfxGedFileTypeEnum {
    let gedFileType: GfxGedFileTypeEnum = GfxGedFileTypeEnum.Other;

    switch (ext) {

      case "pdf":
        gedFileType = GfxGedFileTypeEnum.PDF;
        break;
      case "docx":
        gedFileType = GfxGedFileTypeEnum.WORD;
        break;
      case "xlsx":
        gedFileType = GfxGedFileTypeEnum.Excel;
        break;
      case "pptx":
        gedFileType = GfxGedFileTypeEnum.PowerPoint;
        break;
      case "txt":
        gedFileType = GfxGedFileTypeEnum.Text;
        break;
      case "png":
        gedFileType = GfxGedFileTypeEnum.PNG;
        break;
      case "jpg":
        gedFileType = GfxGedFileTypeEnum.JPG;
        break;
      default:
        gedFileType = GfxGedFileTypeEnum.Other;
        break;

    }


    return gedFileType;
  }

  showActionsMenu(event: MouseEvent, viewChild: MatMenuTrigger) {
    event.preventDefault();
    viewChild.openMenu();
  }

  openDrawer(file: IGfxFile) {
    let oldFile = this.selectedFile;
    this.selectedFile = file;
    if (this.selectedFile == oldFile || !this.drawer.opened)
      this.drawer.toggle();
  }

  closeDrawer() {
    if (this.drawer.opened) {
      this.editMode = false;
      this.drawer.toggle();
    }
  }

  editFile(file: IGfxFile) {
    //Modification
    this.editMode = true;
  }

  deleteFile(file: IGfxFile) {

    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {

        msg: "Etes-vous sûr de vouloir supprimer le fichier ?"
      },
      height: '200px',
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this.gedService.deleteFile(file).subscribe((res) => {
          this.gedService.getFiles(this.config);
          this.closeDrawer();
          this.snackBar.open('Fichier supprimé avec succès', 'Ok', {
            duration: 500,
          });
        });
      }
    });


  }

  downloadFile(file: IGfxFile) {
  
    this.gedService.downloadFile(file).subscribe((fileData: Blob) => {
      FileSaver.saveAs(fileData, file.Name);
    });
  }

  showFile(file: IGfxFile) {
    let dataObj = {
      fileName: file.Name,
      fileUrl: file.FileUrl,
      fileData: {}
    }

    let dialogRef = this.dialog.open(FilePreviewDialogComponent, {
      data: dataObj
    });
  }

  selectFileToUpload() {
    this.gedFileInputView.nativeElement.click();
  }

  removeExtension(fileName: string) {
    return fileName.replace(/\.[^/.]+$/, "");
  }

  upload() {
    //Uploading file
    if (
      !this.gedFileInputView.nativeElement.files ||
      this.gedFileInputView.nativeElement.files.length <= 0
    )
      return;
    this.fileToUpload = this.gedFileInputView.nativeElement.files[0];
    this.uploading = true;
    this.uploadProgress = this.gedService.newFile(this.fileToUpload, this.config.Params);
    this.uploadProgress.subscribe(
      (perc) => {
        //async (uploadProgress | async)
        //in case we want not to use async uploadProgress  : (ppp )
        //let ppp = perc;
       },
      (err) => { },
      () => {
        this.uploading = false;
        this.gedService.getFiles(this.config);
          this.closeDrawer();
          this.snackBar.open('Fichier chargé avec succès', 'Ok', {
            duration: 500,
          });
      }
    );
  }

  disableEditMode() {
    this.editMode = false;
  }

  validateFileChanges() {
    this.selectedFile.Description = this.currDescription;
    this.gedService.editFile(this.selectedFile).subscribe((res) => {
      this.gedService.getFiles(this.config);
      this.disableEditMode();
    })

  }

  switchRenderingMode() {
    if (this.currentRenderingMode == GfxGedRenderingMode.List) {
      this.dataSource.paginator = this.paginator;
      this.currentRenderingMode = GfxGedRenderingMode.Grid
    }

    else
      this.currentRenderingMode = GfxGedRenderingMode.List

  }

  getFileExtension(fineName: string): string {
    let ext: string = "";
    const re = /(?:\.([^.]+))?$/;
    ext = re.exec(fineName)[1];
    return ext;
  }

  constructor(
    private gedService: GfxGedService,
    public dialog: MatDialog,
    private store: Store<GfxState>,
    public snackBar: MatSnackBar,
    private http: HttpClient) { }
  ngOnInit(): void {
    this.renderedColumns = ["Name", "Size", "CreationDate", "gedFileOptions"];
    this.currentRenderingMode = this.config.Layout.RenderingMode;
    this.gedService.getFiles(this.config);
    this.dataObs = this.store.select(getGfxGedDataSelector(this.config.GedName));
    this.dataObs.subscribe((gedData: IGfxGedData) => {

      //Récuperation des donnees apartir backend 
      this.currentFiles = gedData.Data.map((file: IGfxFile) => {
        file.Type = this.getFileTypeViaExtension(this.getFileExtension(file.Name));
        return file;
      });
      this.dataSource = new MatTableDataSource(this.currentFiles);

      this.dataSource.paginator = this.paginator;


    })
  }
}
