import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class PropreListService {

  constructor(private gfxhttp: GfxHttpSrv) { }

  getAll(dataUrl) {
      return this.gfxhttp.get(dataUrl);
  }

}
