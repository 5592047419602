import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar } from '@angular/material';
import { GfxMiscSrv } from '../../../app/../gfx/helpers/gfx.misc.service';
import { WorkflowService } from '../Services/workflow.service';
import { GfxGedService } from '../../core/components/gfxged/gfx.ged.service';

@Component({
  selector: 'app-wf-transition-diag',
  templateUrl: './wf-transition-diag.component.html',
  styleUrls: ['./wf-transition-diag.component.css']
})
export class WfTransitionDiagComponent implements OnInit {
  private comment: any;
  private actions: { id: string; lib: string }[];
  transitionFrm: FormGroup;
  file: any;
  @ViewChild("gedFileInputView") gedFileInputView;
  fileToUpload: any;
  constructor(private fb: FormBuilder, private utilSrv: GfxMiscSrv, private wfSrv: WorkflowService
    , public dialogRef: MatDialogRef<WfTransitionDiagComponent>, @Inject(MAT_DIALOG_DATA) private data: any,
    private gedService: GfxGedService, private snackBar: MatSnackBar) {

  }

  ngOnInit() {

    this.transitionFrm = this.fb.group({
      idInstance: [this.data.idInstance],
      idTransit: ['', Validators.required],
      comment: ['', Validators.required],
      file: ['']

    });

    let idProfile = sessionStorage.getItem('idProfile');
    this.wfSrv.getTransitions(this.data.idInstance, idProfile).subscribe((data) => {
      this.actions = data;
    });

  }

  upload(event) {
    if (
      !this.gedFileInputView.nativeElement.files ||
      this.gedFileInputView.nativeElement.files.length <= 0
    )
      return;
    this.fileToUpload = this.gedFileInputView.nativeElement.files[0];

  }

  selectFileToUpload() {
    this.gedFileInputView.nativeElement.click();
  }

  save() {
    this.wfSrv.save(this.transitionFrm.value).subscribe((data) => {
      let params = [{ Name: 'idTbl', Value: "2" },
      { Name: 'idElem', Value: data.idTransLog }];

      this.gedService.newFile(this.fileToUpload, params);
      this.dialogRef.close();
      let idWf = localStorage.getItem('idWfTrans');
      let idState = localStorage.getItem('ididStateTrans');
      this.utilSrv.navigateTo("WfInstancesListComponent", { idWf: idWf, idState: idState });
    });

  }

}
