import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { WorkflowService } from '../Services/workflow.service';

@Component({
  selector: 'app-wf-inst-log',
  templateUrl: './wf-inst-log.component.html',
  styleUrls: ['./wf-inst-log.component.css']
})
export class WfInstLogComponent implements OnInit {

  instLogs;

  constructor(@Inject(MAT_DIALOG_DATA) private data: any, private wfSrv: WorkflowService) { }

  ngOnInit() {
    this.wfSrv.instLog(this.data.idInstance).subscribe((data)=> {
      this.instLogs = data;
    });
  }

}
