import { Component, OnInit } from '@angular/core';
import { MenuItem } from '../../../../core/models/MenuItem';
import { IGfxNotification } from '../../../../core/components/gfxnotifications/IGfxNotification';
import { IUser } from '../../../../authentication/models/IUser';
import { ISelectData } from '../../../../../app/common/models/ISelectData';
import { AppConfig } from '../../../../core/services/AppConfig';
import { AppMenu } from '../../../../core/services/AppMenu';
import { appRefsSrv } from '../../../../../app/common/services/app.refs.service';
import { GfxState, getMenuState, getUserState } from '../../../../store/gfxstate';
import { Store } from '@ngrx/store';
import { AuthService } from '../../../../authentication/services/AuthService';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Router } from '@angular/router';
import { GfxMiscSrv } from '../../../../helpers/gfx.misc.service';
import { GfxNotificationsService } from '../../../../core/components/gfxnotifications/gfx.notifications.service';
import { GfxAppContentService } from '../../../../core/components/app.content/gfx.app.content.service';
import { DeclarProbComponent } from '../../../../../app/reclam/components/declar-prob/declar-prob.component';
import { AuthAction, USRLOGGEDOUT } from '../../../../store/actions/auth';
import { ResidenceNotifService } from '../residence-notif/residence-notif.service';
import { timer, Observable, Subject, Subscription } from 'rxjs';
import { switchMap, takeUntil, catchError } from 'rxjs/operators';

@Component({
    selector: 'residence-home',
    templateUrl: './residence-home.component.html',
    styleUrls: ['./residence-home.component.css']
})
export class ResidenceHomeComponent implements OnInit {

    notifs = []
    public codeActif: string;
    public appName: string;
    public appVersion: string;
    private menuObs: Observable<MenuItem[]>;
    notifObs: Observable<IGfxNotification[]>;
    public currMenu: MenuItem[];
    public selectedMenu: MenuItem;
    public selectedMenuItem: MenuItem;
    public currUser: IUser;
    public nbrOfNotifications: number = 0;
    public lastNotifications: IGfxNotification[];
    public nbrOfNotifToShow: number = 5;
    componentName: String;
    profiles: ISelectData[];
    defaultTdb;
    userphoto: string = "assets/ONEA-logo.png";
    idProfil
    etatMenu
    menuHidden
    appHeaderWidth;
    content
    public innerWidth: any;
    public appHeaderHeight
    etapesStyle
    notifNonLuSize: Number = 0;
    subscription: Subscription;
    statusText: string;

    constructor(private appConfig: AppConfig,
        private appMenu: AppMenu,
        private refsrv: appRefsSrv,
        private notifSrv: ResidenceNotifService,
        private store: Store<GfxState>,
        private authService: AuthService,
        public dialog: MatDialog,
        private router: Router,
        public snackBar: MatSnackBar,
        private utilSrv: GfxMiscSrv,
        private notificationsService: GfxNotificationsService,
        private appContentService: GfxAppContentService) {
        this.appName = appConfig.get("AppName");
        this.appVersion = appConfig.get("AppVersion");
        this.menuObs = this.store.select(getMenuState);
        this.appMenu.getMenu();

        //Get current user
        let usrObs: Observable<IUser>;
        usrObs = this.store.select(getUserState);
        let sub = usrObs.subscribe((s) => {

            if (!s) {
                this.router.navigate(['login'], { skipLocationChange: true });
            }
            else {
                this.currUser = s;
                this.userphoto = s.photo;
            }
        });
    }

    ngOnInit(): void {

        this.subscription = timer(0, 5000).pipe(switchMap(() => this.notifSrv.getNotifs())).subscribe(data => {
            this.notifNonLuSize=0
            if (data && data.length > 0) {
                data.forEach(element => {
                    if (element.estlu == 0)
                        this.notifNonLuSize = Number(this.notifNonLuSize) + 1
                });
            }
            this.notifSrv.changeNotificationsState(data);
        });

        this.etapesStyle = '';
        this.innerWidth = window.innerWidth;
        this.etatMenu = ''
        this.menuHidden = false
        this.idProfil = sessionStorage.getItem('idProfile');
        this.menuObs.subscribe((m: MenuItem[]) => {
            this.currMenu = m;
        });

        this.selectedMenu = { code: null, id: null, label: null, mdIcon: null, children: null };
        this.selectedMenuItem = { code: null, id: null, label: null, mdIcon: null, children: null };

        this.refsrv.getProfilesByUser(this.currUser.id).subscribe(data => {
            this.profiles = data
            let defaultProfile = data.filter(function (p) {
                return p.isDefault == 1;
            });
            this.defaultTdb = defaultProfile[0]["defaultTdb"];
            this.utilSrv.navigateTo(defaultProfile[0]["defaultView"]);
        });

        this.notifSrv.NotificationsState.subscribe((data) => {
            this.notifs = data
        });
    }

    getNitifcation(item) {
        localStorage.setItem("notif", JSON.stringify(item))
        this.utilSrv.navigateTo("ResidenceNotifComponent");
    }

    logOut(event) {
        this.utilSrv.confirmDialog("Annuler", 'Déconnecter', "Etes-vous sûr de vouloir vous déconnecter ?", this.authService.logout.bind(this));
    }

    ChangeContent(item) {

        if (this.innerWidth <= 768) {
            if (this.etatMenu == '') {
                this.etatMenu = 'active';
                this.innerWidth = window.innerWidth;
                this.appHeaderWidth = document.getElementById('appHeaderWidth').clientWidth;
                document.getElementById('appHeaderWidth').style.width = this.appHeaderWidth + 350 + 'px';

                this.content = document.getElementById('content').clientWidth;
                document.getElementById('content').style.minWidth = this.appHeaderWidth + 'px';
                this.content = document.getElementById('content').clientWidth;
                this.menuHidden = true;
            } else {
                this.etatMenu = ''
                this.appHeaderWidth = document.getElementById('appHeaderWidth').clientWidth;
                document.getElementById('appHeaderWidth').style.width = this.appHeaderWidth - 350 + 'px';

                this.content = document.getElementById('content').clientWidth;
                document.getElementById('content').style.width = this.content - 350 + 'px';
            }
        }



        this.codeActif = '';
        if (this.componentName == item.code)
            this.appContentService.changeContent.emit({ ComponentName: item.code, Data: {} });
        this.componentName = item.code;

    }

    showNotifications() {
        const notifComponent = this.appConfig.get("NotificationsComponent");
        if (notifComponent) {
            this.componentName = notifComponent;
        } else {
            setTimeout(() => {
                this.snackBar.open(this.appName + " : le composant de notifications n'est pas enregistré ou n'existe pas", "OK", {
                    duration: 3000,
                });
            });
        }
    }

    goToAccueil() {
        this.utilSrv.navigateTo("GfxAproposComponent");
    }

    goToApropos() {
        this.utilSrv.navigateTo("GfxAproposComponent");
    }

    goToProfil() {
        this.utilSrv.navigateTo("GfxProfilComponent");
    }

    getProfilesMenus(idProfile) {
        this.currMenu = []
        sessionStorage.setItem('idProfile', JSON.stringify(idProfile));
        this.appMenu.getMenu(idProfile);

        this.profiles.forEach(element => {
            if (element.id == idProfile) {
                this.defaultTdb = element["defaultTdb"];
                if (this.defaultTdb)
                    this.utilSrv.navigateTo(this.defaultTdb);
                else
                    this.utilSrv.navigateTo("GfxDefaultViewComponent");
            }
        });
    }

    displayDashBord() {
        if (this.innerWidth <= 768) {
            if (this.etatMenu == '') {
                this.etatMenu = 'active';
                this.innerWidth = window.innerWidth;
                this.appHeaderWidth = document.getElementById('appHeaderWidth').clientWidth;
                document.getElementById('appHeaderWidth').style.width = this.appHeaderWidth + 350 + 'px';

                this.content = document.getElementById('content').clientWidth;
                document.getElementById('content').style.minWidth = this.appHeaderWidth + 'px';
                this.content = document.getElementById('content').clientWidth;
                // this.appHeaderWidth = this.appHeaderWidth.nativeElement.offsetWidth
                this.menuHidden = true;
            } else {
                this.etatMenu = ''
                this.appHeaderWidth = document.getElementById('appHeaderWidth').clientWidth;
                document.getElementById('appHeaderWidth').style.width = this.appHeaderWidth - 350 + 'px';

                this.content = document.getElementById('content').clientWidth;
                document.getElementById('content').style.width = this.content - 350 + 'px';
                // this.appHeaderWidth = this.appHeaderWidth.nativeElement.offsetWidth
            }
        }

        this.utilSrv.navigateTo(this.defaultTdb);
    }

    declarerProb() {

        if (this.innerWidth < 577) {
            this.etapesStyle = 'active';
        }

        if (this.innerWidth <= 768) {
            if (this.etatMenu == '') {
                this.etatMenu = 'active';
                this.innerWidth = window.innerWidth;
                this.appHeaderWidth = document.getElementById('appHeaderWidth').clientWidth;
                document.getElementById('appHeaderWidth').style.width = this.appHeaderWidth + 350 + 'px';

                this.content = document.getElementById('content').clientWidth;
                document.getElementById('content').style.minWidth = this.appHeaderWidth + 'px';
                this.content = document.getElementById('content').clientWidth;
                // this.appHeaderWidth = this.appHeaderWidth.nativeElement.offsetWidth
                this.menuHidden = true;
            } else {
                this.etatMenu = ''
                this.appHeaderWidth = document.getElementById('appHeaderWidth').clientWidth;
                document.getElementById('appHeaderWidth').style.width = this.appHeaderWidth - 350 + 'px';

                this.content = document.getElementById('content').clientWidth;
                document.getElementById('content').style.width = this.content - 350 + 'px';
                // this.appHeaderWidth = this.appHeaderWidth.nativeElement.offsetWidth
            }
        }

        this.utilSrv.navigateTo("DeclarProbComponent");
    }
    hideShowMenu() {
        if (this.etatMenu == '') {
            this.etatMenu = 'active';
            this.innerWidth = window.innerWidth;
            this.appHeaderWidth = document.getElementById('appHeaderWidth').clientWidth;
            document.getElementById('appHeaderWidth').style.width = this.appHeaderWidth + 350 + 'px';

            this.content = document.getElementById('content').clientWidth;
            document.getElementById('content').style.minWidth = this.appHeaderWidth + 'px';
            this.content = document.getElementById('content').clientWidth;
            // this.appHeaderWidth = this.appHeaderWidth.nativeElement.offsetWidth
            this.menuHidden = true;
        } else {
            this.etatMenu = ''
            this.appHeaderWidth = document.getElementById('appHeaderWidth').clientWidth;
            document.getElementById('appHeaderWidth').style.width = this.appHeaderWidth - 350 + 'px';

            this.content = document.getElementById('content').clientWidth;
            document.getElementById('content').style.width = this.content - 350 + 'px';
            // this.appHeaderWidth = this.appHeaderWidth.nativeElement.offsetWidth
        }
    }

    goToSubMenuItem(idMenuItem) {

        if (this.innerWidth <= 768) {
            if (this.etatMenu == '') {
                this.etatMenu = 'active';
                this.innerWidth = window.innerWidth;
                this.appHeaderWidth = document.getElementById('appHeaderWidth').clientWidth;
                document.getElementById('appHeaderWidth').style.width = this.appHeaderWidth + 350 + 'px';

                this.content = document.getElementById('content').clientWidth;
                document.getElementById('content').style.minWidth = this.appHeaderWidth + 'px';
                this.content = document.getElementById('content').clientWidth;
                // this.appHeaderWidth = this.appHeaderWidth.nativeElement.offsetWidth
                this.menuHidden = true;
            } else {
                this.etatMenu = ''
                this.appHeaderWidth = document.getElementById('appHeaderWidth').clientWidth;
                document.getElementById('appHeaderWidth').style.width = this.appHeaderWidth - 350 + 'px';

                this.content = document.getElementById('content').clientWidth;
                document.getElementById('content').style.width = this.content - 350 + 'px';
                // this.appHeaderWidth = this.appHeaderWidth.nativeElement.offsetWidth
            }
        }

        localStorage.removeItem("subMenuMsg");
        if (idMenuItem == 0) {
            localStorage.setItem("subMenuMsg", JSON.stringify("contact"))
        }
        if (idMenuItem == 1) {
            localStorage.setItem("subMenuMsg", JSON.stringify("F.A.Q"))
        }
        if (idMenuItem == 2) {
            localStorage.setItem("subMenuMsg", JSON.stringify("Aide"))
        }
        if (idMenuItem == 3) {
            localStorage.setItem("subMenuMsg", JSON.stringify("RGPD"))
        }
        if (idMenuItem == 4) {
            localStorage.setItem("subMenuMsg", JSON.stringify("infoGen"))
        }
        this.utilSrv.navigateTo("SubMenuInfoComponent");
    }

}