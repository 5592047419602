export enum TypeFeExport {
    PDF = "pdf",
    PNG = "png",
    XLSX = "xlsx",
    XLS = "xls",
    DOCX = "docx",
    DOC = "doc",
    TXT = "txt",
    CSV = "csv",
    JSON = "json",
    XML = "xml"
}
