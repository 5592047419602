import { Component } from "@angular/core";

@Component({
    selector: "gfx-demo",
    templateUrl: "./gfx.demo.component.html"
})
export class GfxDemoComponent {
    title: string = "Bienvenue dans la page démo du GFX";
    currentDemo: string;

    setDemo(name: string) {
        
        this.currentDemo = name;
    }
}