import { Injectable,Inject,ComponentFactoryResolver,ReflectiveInjector } from "@angular/core";

import {DefaultIntermediateComponent} from '../../theme/default/containers/default.intermediate.component';
@Injectable()
export class IntermediateHookService {
    viewContainerRef: any;
    factoryResolver : ComponentFactoryResolver;
    constructor(@Inject(ComponentFactoryResolver) factoryResolver){
        this.factoryResolver = factoryResolver
    }
    setRootViewContainerRef(viewContainerRef) {
        this.viewContainerRef = viewContainerRef
      }
}