import { Injectable, Inject } from "@angular/core";
import {
  HttpClient,
  HttpRequest,
  HttpEventType,
  HttpResponse,
  HttpParams
} from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { AppConfig } from "../core/services/AppConfig";
Injectable();
export class GfxUpDownHelper {
  constructor(
    @Inject(HttpClient) private http: HttpClient,
    @Inject(AppConfig) private appConfig: AppConfig
  ) { }

  public upload(file: File, url: string, arrayParams?): Observable<number> {
    
    let params = new HttpParams();

    arrayParams.forEach(element => {
      if (element) {
        params = params.set(element.Name, element.Value);
      }

    });

    
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    const req = new HttpRequest("POST", url, formData, {
      reportProgress: true, params
    });
    //Creation d'un progress subject
    const progress = new Subject<number>();

    //Subscribe for the progress
    this.http.request(req).subscribe(event => {
      if (event.type === HttpEventType.UploadProgress) {
        //calcul et injection du pourcentage dans le stream
        let perc = Math.round((100 * event.loaded) / event.total);
        progress.next(perc);
      } else if (event instanceof HttpResponse) {

        progress.complete();
      }
    });

    return progress.asObservable();
  }

  public download(url: string): Observable<Blob> {
    return this.http.get(url, {
      responseType: "blob"
    });
  }
}
