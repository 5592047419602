import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ColtureReclamService } from '../../../../../app/reclam/services/colture-reclam.service';
import { debounceTime, switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { appRefsSrv } from '../../../../../app/common/services/app.refs.service';
import { Router } from '@angular/router';
import { AuthService } from '../../../../authentication/services/AuthService';

@Component({
  selector: 'app-recuperation-pwd',
  templateUrl: './recuperation-pwd.component.html',
  styleUrls: ['./recuperation-pwd.component.css']
})
export class RecuperationPwdComponent implements OnInit {
  isForgetPwd
  identificationForm: FormGroup
  emailPsd
  err: boolean;
  msg: any;
  data: any;
  emailEnvoye: boolean;
  codeRecu: any;
  codeSaisi: string;
  codeValid: boolean;
  errCode: boolean;
  pwd: String;
  confirmPwd: String


  constructor(public fb: FormBuilder, private userSrv: ColtureReclamService, private authService: AuthService, private router: Router, ) { }

  ngOnInit() {
    this.err = false;
    this.emailEnvoye = false;
    this.codeValid = false
    this.errCode = false;
    this.pwd = ''
    this.confirmPwd = ''

    this.data = JSON.parse(localStorage.getItem("donneeRecupPwd"));
    localStorage.removeItem("donneeRecupPwd")
    if (this.data.retour == 1) {
      var email = this.data.email;
      var a = email.split("@");
      var b = a[0];
      var newstr = "";
      for (var i in b) {
        if (Number(i) > 2 && Number(i) < Number(b.length) - 2) newstr += "*";
        else newstr += b[i];
      }
      this.emailPsd = newstr + "@" + a[1];
    } else {
      this.err = true;
      this.msg = this.data.msg;
    }

  }

  saveCodeRecup() {
    this.authService.saveCodeRecupPwd(this.data.login, this.data.email).subscribe((res) => {
      if (res.retour == 1) {
        this.err = false;
        this.emailEnvoye = true;
        this.codeRecu = res.code
      } else {
        this.err = true;
        this.msg = res.msg;
      }
    });
  }

  verifierCodeRecup() {
    if (this.codeRecu == this.codeSaisi) {
      this.errCode = false;
      this.codeValid = true

    } else {
      this.errCode = true;
    }
  }

  resetPwd() {
    this.authService.saveResetPwd(this.data.login, this.pwd).subscribe((res) => {
      if (res.retour == 1) {
        this.return()
      }else{
        this.err = true;
        this.msg = res.msg;
      }
    })
  }

  return() {
    this.router.navigate(['login'], { skipLocationChange: true });
  }

}
