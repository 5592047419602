import { Component, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DispatcherFormService } from '../../services/dispatcher-form.service';
import { ISelectData } from '../../../common/models/ISelectData';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import * as moment from 'moment'
import { IGfxListActionComponent } from '../../../../gfx/core/components/gfxlist/IGfxListActionComponent';

@Component({
  selector: 'app-dispatcher-form',
  templateUrl: './dispatcher-form.component.html',
  styleUrls: ['./dispatcher-form.component.css']
})
export class DispatcherFormComponent implements OnInit, IGfxListActionComponent {
  SelectedItem
  private dispatchForm: FormGroup;
  private dispatch = {
    idResponsabilite: '', date: '', commentaire: '',
  };
  private responsabilites: ISelectData;
  private msgSucces
  newDate
  idReclam
  msg

  constructor(private fb: FormBuilder, private dispatchSrv: DispatcherFormService, private utilSrv: GfxMiscSrv) { }

  ngOnInit() {
    // this.newDate = moment(new Date()).format('YYYY-MM-DD')

    this.idReclam = this.SelectedItem.id

    this.dispatchForm = this.fb.group({
      // idResponsabilite: [this.dispatch.idResponsabilite, Validators.required],
      // date: [this.dispatch.date, Validators.required],
      commentaire: [this.dispatch.commentaire],
    });

    this.dispatchSrv.getResponsabilites().subscribe((data) => {
      this.responsabilites = data;
    });

  }

  save(idTraitChoisi) {

    let data = this.dispatchForm.value;
    const formData: FormData = new FormData();

    formData.append("idTraitChoisi", idTraitChoisi);
    // formData.append("date", data.date);
    formData.append("commentaire", data.commentaire);
    formData.append("idReclam", this.idReclam);

    this.dispatchSrv.post(formData).subscribe((data) => {
      this.msgSucces = true;
      if(idTraitChoisi==0)this.msg = "La réclamation a été validée avec succès ! "
      if(idTraitChoisi==1)this.msg = "La réclamation a été validée avec succès ! "
      localStorage.removeItem('idReclam');
    })
  }

  return() {
    sessionStorage.setItem("isReturn", "1");
    this.msgSucces = false;
    this.utilSrv.navigateTo("MesSignalementsComponent");
  }
}
