import { Component, OnInit, Injector } from "@angular/core";
import { IGfxListFilterElem } from "../../core/components/gfxlist/IGfxListFilterElem";
import { GfxListFilterComponent } from "../../core/components/gfxlist/GfxListFilterComponent";

@Component({
    selector: "gfx-workflows-list-filter",
    templateUrl: "gfx.workflows.list.filter.html"
})
export class GfxWorkflowsListFilterComponent extends GfxListFilterComponent implements OnInit {

    ngOnInit(): void {
    }
    
    Actualiser() {
    }

    constructor(injector: Injector) {
        super(injector);
    };

}