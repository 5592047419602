import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gfx-default-view',
  templateUrl: './gfx-default-view.component.html',
  styleUrls: ['./gfx-default-view.component.css']
})
export class GfxDefaultViewComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
