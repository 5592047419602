import { Component, OnInit } from '@angular/core';
import { GfxMiscSrv } from '../../../../app/../gfx/helpers/gfx.misc.service';
import { ProfileService } from '../../services/profile.service';

@Component({
  selector: 'app-profil-droits',
  templateUrl: './profil-droits.component.html',
  styleUrls: ['./profil-droits.component.css']
})
export class ProfilDroitsComponent implements OnInit {
  SelectedItem;
  droitMenuList = [];
  listEnvoi = [];
  refList = [];

  constructor(private utilSrv: GfxMiscSrv, private profilesService: ProfileService) { }

  ngOnInit() {
    this.profilesService.getDroits(this.SelectedItem.id).subscribe((data) => {
      this.droitMenuList = data;


    });

    this.profilesService.getReferentiels(this.SelectedItem.id).subscribe((data) => {
      this.refList = data;
    });

  }
  return() {
    this.utilSrv.navigateTo("GfxProfilesManagComponent");
  }

  save() {
    let menu = this.utilSrv.nestedToSimpleArray(this.droitMenuList);
    this.profilesService.saveDroitsMenu(menu).subscribe((data) => {
      this.return();
    })

  }
  saveRef() {
    this.profilesService.saveDroitsRefs(this.refList).subscribe((data) => {
      this.return();
    })
    this.return();
  }

}
