import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../services/AppConfig';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  theme: any;

  constructor(private apConfig: AppConfig) { }

  ngOnInit() {
    this.theme = this.apConfig.get("theme");
  }

}
