import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { IGfxOpResObj } from "../core/models/IGfxOpResObj";
import { AppConfig } from "../core/services/AppConfig";
import { GfxRefAction, UPDATEREF } from "../store/actions/gfxref";
import { GfxRefListAction, UPDATEREFLIST } from "../store/actions/gfxreflist";
import { GfxState } from "../store/gfxstate";
import { IGfxRef } from "./IGfxRef";
import { IGfxRefDetail } from "./IGfxRefDetail";
import { IGfxRefInfo } from "./IGfxRefInfo";

@Injectable()
export class GfxRefService {
    refBackendAPI = {};
    constructor(
        private appConfig: AppConfig,
        private http: HttpClient,
        private store: Store<GfxState>
    ) {
        this.refBackendAPI = this.appConfig.get("Ref")["BackendAPI"];
    }

    getRefList(idProfile) {

        this.http
            .get<any>("refdyn/refdynlist?idProfile="+idProfile)
            .subscribe((result) => {
                this.store.dispatch(new GfxRefListAction(UPDATEREFLIST, result.listrefdyn));
            });
    }

    getRef(info: IGfxRefInfo) {
        const params = new HttpParams().set("code", info.code);
        //params;
        this.http
            .get<any>("refdyn/refdyn-form", { params })
            .subscribe((result: IGfxRefDetail) => {
                const currRef: IGfxRef = {
                    Detail: result,
                    Info: info
                }
                this.store.dispatch(new GfxRefAction(UPDATEREF, currRef));
            });
    }
    getRefForm(info: IGfxRefInfo) {
        let params = new HttpParams();
        params.set("code", info.code);
        return this.http.get<any>(this.refBackendAPI["RefForm"], { params })
    }

    deleteRefElem(info : IGfxRefInfo,id : number) {
        return this.http.post<IGfxRefDetail>("refdyn/supprimer", {id: id, code : info.code});
        
    }

    addRefElem(info : IGfxRefInfo,refObject : any) {
        return this.http.post<IGfxRefDetail>("refdyn/valider",{refInfo : info, refObject : refObject});
        
    }
    editRefElem(info : IGfxRefInfo,refObject : any, idRefElem: any) {
        return this.http.post<IGfxRefDetail>("refdyn/valider",{refInfo : info, refObject : refObject, idRefElem : idRefElem});
        
    }
}