import { Action } from "@ngrx/store";
import { MenuItem } from "../../core/models/MenuItem";
export const UPDATEMENU = "[GFX] [MENU] Update";
export class MenuAction implements Action {
    type : string;
    getMenu(){
        return this.payload;
    }
    constructor(_type : string, private payload : MenuItem[]){
        this.type = _type;
    }
}