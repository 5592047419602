import { Component, OnInit, NgModule, ApplicationRef, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { WorkflowService } from '../Services/workflow.service';
import { GfxMiscSrv } from '../../../app/../gfx/helpers/gfx.misc.service';
import { FormBuilder } from '@angular/forms';
import { IGfxListActionComponent } from '../../core/components/gfxlist/IGfxListActionComponent';
import { MatDialog } from '@angular/material';
import { WfTransitionDiagComponent } from '../wf-transition-diag/wf-transition-diag.component';
import { WfInstLogComponent } from '../wf-inst-log/wf-inst-log.component';


@Component({
  selector: 'app-wf-instances-list',
  templateUrl: './wf-instances-list.component.html',
  styleUrls: ['./wf-instances-list.component.css']
})
export class WfInstancesListComponent implements OnInit, IGfxListActionComponent {
  SelectedItem;
  SourceComponentName: string;

  private insList = { headers: [], lines: [] };
  private idWf: any;
  private idState: any;
  private instViewComponent: any;
  private idElement: any;
  idProfile;

  constructor(private utilSrv: GfxMiscSrv, private fb: FormBuilder,
    private wfSrv: WorkflowService, private dialog: MatDialog, private appref: ApplicationRef,
    private viewContainer: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.idProfile = sessionStorage.getItem("idProfile");
    this.wfSrv.getListInstance(this.idWf, this.idState, this.idProfile).subscribe((data) => {
      this.insList = data;
    });

    this.wfSrv.getinstViewComponent(this.idWf).subscribe((data) => {
      this.instViewComponent = data.instViewComponent;
    });

  }


  transition(idInstance) {
    //this.utilSrv.navigateTo("GreOuvrDetailComponent", {SelectedItem: {id: item.idOuvrage}});

    // let dataObj = {SelectedItem: {id: item.idOuvrage}}

    let dialogRef = this.dialog.open(WfTransitionDiagComponent, {
      width: '800px',
      panelClass: 'form-container',
      data: { idInstance: idInstance }
    });
  }

  instLog(idInstance) {
    let dialogRef = this.dialog.open(WfInstLogComponent, {
      width: '800px',
      panelClass: 'form-container',
      data: { idInstance: idInstance }
    });
  }

  view(viewComp, idInst) {


  }

  retour() {
    this.utilSrv.navigateTo("WfInstancesComponent");
  }



}
