import { IGfxGedData } from "../../core/components/gfxged/IGfxGedData";
import * as gfxged from "../actions/gfxged"
export function reducer(state : IGfxGedData[], action : gfxged.GfxGedAction) {
    switch (action.type) {
        case gfxged.UPDATEDATA:
            let newData = action.getGedData();
            let filteredList = [];
            if (state) {
                filteredList = state.filter(d => d.GedName != newData.GedName);
                filteredList.push(newData);
            } else if (newData)
                filteredList.push(newData)

            return filteredList;
        default:
            return state;
    }
}