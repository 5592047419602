import { Component, Inject, ViewContainerRef, OnInit } from '@angular/core';
import { IntermediateHookService } from '../../services/IntermediateHookService';
import { AppConfig } from '../../services/AppConfig';


@Component({
  selector: 'main-container',
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit {
  ngOnInit(): void {
    this.title = this.appConfig.get("AppName");
  }
  title = 'GFX3';
  constructor(private appConfig : AppConfig) {
  }
  
}