import { IUser } from "../authentication/models/IUser";
import * as authReducer from "./reducers/auth";
import * as menuReducer from "./reducers/menu";
import * as gfxlistReducer from "./reducers/gfxlist";
import * as gfxgedReducer from "./reducers/gfxged";
import * as gfxnotificationsReducer from "./reducers/gfxnotifications";
import * as gfxreflistReducer from "./reducers/gfxreflist";
import * as gfxrefReducer from "./reducers/gfxref";
import { MenuItem } from "../core/models/MenuItem";
import { IGfxListData } from "../core/components/gfxlist/IGfxListData";
import { IGfxGedData } from "../core/components/gfxged/IGfxGedData";
import { IGfxNotification } from "../core/components/gfxnotifications/IGfxNotification";
import { IGfxRefInfo } from "../ref/IGfxRefInfo";
import { IGfxRef } from "../ref/IGfxRef";

export interface GfxState {
    user: IUser,
    menu: MenuItem[],
    gfxlists: IGfxListData[],
    gfxgeds: IGfxGedData[],
    gfxnotifications: IGfxNotification[],
    gfxreflist: IGfxRefInfo[],
    gfxref : IGfxRef

}

export const reducers = {
    user: authReducer.reducer,
    menu: menuReducer.reducer,
    gfxlists: gfxlistReducer.reducer,
    gfxgeds: gfxgedReducer.reducer,
    gfxnotifications: gfxnotificationsReducer.reducer,
    gfxreflist: gfxreflistReducer.reducer,
    gfxref : gfxrefReducer.reducer
}

//les projections 
export const getUserState = (state: GfxState) => state.user;
export const getMenuState = (state: GfxState) => state.menu;
export const getGfxListDataSelector = (code: string) => {
    return function gfxListDataSelector(state: GfxState) {
        if (state.gfxlists)
            return state.gfxlists.find(s => s.Code == code);
        else
            return { Code: code, Data: [], TotalItems: 0,  CurrentFilter:{}}//Add currentFilter 
    }
}
export const getGfxGedDataSelector = (gedName: string) => {
    return function gfxListDataSelector(state: GfxState) {
        if (state.gfxgeds)
            return state.gfxgeds.find(s => s.GedName == gedName);
        else
            return { GedName: gedName, Data: [] }
    }
}
export const getGfxNotificationsState = (state: GfxState) => state.gfxnotifications;
export const getGfxRefListState = (state: GfxState) => state.gfxreflist;
export const getGfxRefState = (state: GfxState) => state.gfxref;