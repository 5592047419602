import { Injectable } from "@angular/core";
import { IGfxFile } from "./IGfxFile";
import { Observable } from "rxjs";
import { AppConfig } from "../../services/AppConfig";
import { HttpClient, HttpParams } from "@angular/common/http";
import { GfxUpDownHelper } from "../../../helpers/GfxUpDownHelper";
import { IGfxGedParam } from "./IGfxGedParam";
import { IGfxGedData } from "./IGfxGedData";
import { Store } from "@ngrx/store";
import { GfxState } from "../../../store/gfxstate";
import { UPDATEDATA, GfxGedAction } from "../../../store/actions/gfxged";
import { IGfxGedConfig } from "./IGfxGedConfig";
import { IGfxOpResObj } from "../../models/IGfxOpResObj";
import { GfxHttpSrv } from "../../../helpers/GfxHttpSrv";

@Injectable()
export class GfxGedService {
  gedBackendAPI = {};
  constructor(
    private upDownHelper: GfxUpDownHelper,
    private appConfig: AppConfig,
    private http: GfxHttpSrv,
    private store: Store<GfxState>
  ) {
    this.gedBackendAPI = appConfig.get("GedBackendAPI");
  }
  newFile(file: File, params): Observable<number> {
    return this.upDownHelper.upload(file, "gfxged/registerDoc", params);
  }
  downloadFile(file: IGfxFile): Observable<Blob> {
    return this.upDownHelper.download((file.FileUrl) ? file.FileUrl :  this.gedBackendAPI["Download"] + "/" + file.Id.toString());
  }
  deleteFile(file: IGfxFile) : Observable<IGfxOpResObj> {

    return this.http.delete("gfxged/delDoc", file.Id.toString());
    
  }
  editFile(file: IGfxFile) {
    return this.http.put(this.gedBackendAPI["Edit"] + "/" + file.Id.toString(),file);
  }

  getFiles(config : IGfxGedConfig) {
    let params = new HttpParams();
    config.Params.forEach(element => {
      params = params.set(element.Name, element.Value);
    });
    this.http
      .get("gfxged/listDocs", params, true)
      .subscribe((result: any) => {
        let gedData: IGfxGedData = {
          GedName: config.GedName,
          Data: result
        };
        this.store.dispatch(new GfxGedAction(UPDATEDATA, gedData));
      });
  }
}
