import { Injectable } from "@angular/core";
import { MatDialog, MatSnackBar } from "@angular/material";
import * as jspdf from 'jspdf';
import html2canvas from 'html2canvas';
import { GfxAppContentService } from "../core/components/app.content/gfx.app.content.service";
import { GfxDictionary } from "../core/models/GfxDictionary";
import { GfxChangeContentEventData } from "../core/components/app.content/gfx.change.content.event.data";
import { ConfirmDialogComponent } from "../core/components/confirm.dialog/confirm.dialog.component";
import { AppConfig } from "../core/services/AppConfig";
import { GfxHttpSrv } from "./GfxHttpSrv";
import { BeMsgErrorComponent } from "../core/components/be-msg-error/be-msg-error.component";
import { ExportAsService, ExportAsConfig } from "ngx-export-as";

@Injectable()
export class GfxMiscSrv {
  appName: any;

  constructor(private appContentService: GfxAppContentService,
    private exportAsService: ExportAsService,
    private dialog: MatDialog,
    private http: GfxHttpSrv,
    private snackBar: MatSnackBar,
    private appConfig: AppConfig) {
    this.appName = appConfig.get("AppName");
  }

  navigateTo(compName, data?) {
    let infoToSend: GfxDictionary<any> = data || {};
    let eventData: GfxChangeContentEventData = { ComponentName: "", Data: {} };
    eventData.ComponentName = compName;
    eventData.Data = infoToSend;

    this.appContentService.changeContent.emit(eventData);
  }

  openDialog(ComponentName, data, width?, classe?) {
    this.dialog.open(ComponentName, {
      width: width ? width : '700px',
      panelClass: classe ? classe : 'form-container',
      data: data
    });
  }

  openSnackBar(msg, duree?) {
    this.snackBar.open(msg, 'Ok', {
      duration: duree ? duree : 500,
    });
  }

  openSnackBarFromComp(config) {
    this.snackBar.openFromComponent(BeMsgErrorComponent, config);
  }

  confirmDialog(libCancelBtn, libConfirmBtn, msg, cb?, url?, params?, ComponentName?) {
    let dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: this.appName,
        msg: msg,
        libCancelBtn: libCancelBtn,
        libConfirmBtn: libConfirmBtn,
      },
      height: '200px',
      width: '300px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        if (cb)
          cb();
        else if (url) {
          this.http.get(url, params).subscribe((data) => {
            let infoToSend: GfxDictionary<any> = {}
            infoToSend["SourceComponentName"] = "nothingForNow";
            let eventData: GfxChangeContentEventData = { ComponentName: "", Data: {} };
            eventData.ComponentName = ComponentName;
            eventData.Data = infoToSend;
            this.appContentService.changeContent.emit(eventData);
          });
        }

      }
    });
  }

  nestedToSimpleArray(nestedArrayThreeLevel) {
    let simpleArray = [];

    nestedArrayThreeLevel.forEach(function (value) {
      simpleArray.push(value);
      if (value.children) {
        value.children.forEach(function (value) {
          simpleArray.push(value);
          if (value.children) {
            value.children.forEach(function (value) {
              simpleArray.push(value);
            });
          }
        });
      }
    });

    return simpleArray
  }

  public captureScreen(idElem, nameDoc?, isPortOrLand?) {
    nameDoc = nameDoc ? nameDoc : "Document";
    isPortOrLand = isPortOrLand ? isPortOrLand : "p";
    var data = document.getElementById(idElem);
    html2canvas(data).then(canvas => {
      // Few necessary setting options
      var imgWidth = 208;
      var pageHeight = 295;

      if (isPortOrLand == 'l') {
        var imgWidth = 295;
        var pageHeight = 208;
      }

      var imgHeight = canvas.height * imgWidth / canvas.width;
      var heightLeft = imgHeight;

      const contentDataURL = canvas.toDataURL('image/png')
      let pdf = new jspdf(isPortOrLand, 'mm', 'a4'); // A4 size page of PDF
      var position = 0;

      pdf.addImage(contentDataURL, 'PNG', 0, position, imgWidth, imgHeight);
      //pdf.addPage();
      pdf.save(nameDoc + '.pdf'); // Generated PDF
    });
  }

  public renameProp(newKey, oldKey, obj) {
    var renameProp = (
      oldProp,
      newProp,
      { [oldProp]: old, ...others }
    ) => {
      return {
        [newProp]: old,
        ...others
      };
    }

    return renameProp(newKey, oldKey, obj);

  }

  impressFe(idElem, type, fileName) {
    let exportAsConfig: ExportAsConfig = {
      type: type, // the type you want to download
      elementId: idElem // the id of html/table element
    }
    this.exportAsService.save(exportAsConfig, fileName).subscribe(() => {
    });
  }

  sum_array(array) {
    return array.reduce((total, num) => {
      return parseInt(total) + parseInt(num);
    });
  }

  sum_array_key(array, key) {
    return array.reduce(function (cnt, o) { return cnt + o[key]; }, 0)
  }

  concat_array_key(array, key, sep) {
    return array.reduce(function (cnt, o) { return cnt ? cnt + sep + o[key] : cnt + o[key]; }, '')
  }

  get_n_fst_letters(strOrArr, letterNbr, key?) {
    let str;
    if (key) {
      str = this.concat_array_key(strOrArr, key, ', ');
    } else {
      str = strOrArr;
    }

    str = str.length <= letterNbr ? str.substring(0, letterNbr) : str.substring(0, letterNbr) + '...';
    console.log('str: ', str);

    return str;
  }

}
