import { Injectable } from "@angular/core";
import { GfxHttpSrv } from "../../helpers/GfxHttpSrv";

@Injectable()
export class WorkflowService {
    constructor(private http: GfxHttpSrv){

    }
    
    getPendingTasks (idprofile) {
        let url = 'workflow/pendingList?idProfile='+idprofile;
        return this.http.get(url);
    }

    getWfSpecData (wf) {
        return this.http.get(wf.urlListData+'?idState='+wf.idStateInSrcTbl);
    }

    getInstActions (idprofile, idWfInstState) {
        return this.http.get("workflow/actions?idProfile=" + idprofile + "&state=" + idWfInstState);
    }

    postWfAction (data) {
        return this.http.post("workflow/action", data);
    }

    getListInstance(idWf, idState,idProfile) {
        return this.http.get('workfl/wfInstances', {idWf: idWf, idState : idState, idProfile: idProfile});
    }

    getinstViewComponent(idWf) {
        return this.http.get('workfl/wfInstViewComponent', {idWf: idWf});
    }

    getElement(idInst) {
        return this.http.get('workfl/wfInstElement', {idInst: idInst});
    }

    getWfList(idProfile) {
        return this.http.get('workfl',{idProfile: idProfile});
    }

    getTransitions(idInstance,idProfile) {
        return this.http.get('workfl/wfTransitions', {idInstance: idInstance,idProfile:idProfile});
    }

    save(idTransit) {
        return this.http.post('workfl/doTransition', idTransit);
    }

    instLog(inst) {
        return this.http.get('workfl/instLog', {idInstance: inst});
    }

    getInstDocs(inst, idTbl) {
        return this.http.get('workfl/instDocs', {idInstance: inst, idTbl: idTbl});
    }
}