import { Component, OnInit } from '@angular/core';
import { VisualisationProbService } from '../../services/visualisation-prob.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { IGfxListActionComponent } from '../../../../gfx/core/components/gfxlist/IGfxListActionComponent';

@Component({
  selector: 'app-visualisation-prob',
  templateUrl: './visualisation-prob.component.html',
  styleUrls: ['./visualisation-prob.component.css']
})
export class VisualisationProbComponent implements OnInit, IGfxListActionComponent {
  SelectedItem
  idReclam
  reclammation = {
    appartement: "",
    lieu: "",
    nature: "",
    date: "",
    commentaire: "",
    files: []
  }
  urlImgs
  urlVideo
  idProfil
  isPrint: any;
  wait: boolean;
  constructor(private srvVisProb: VisualisationProbService, private utilSrv: GfxMiscSrv) { }

  ngOnInit() {
    this.urlImgs = []
    this.urlVideo = [];
    this.idProfil = sessionStorage.getItem('idProfile');

    this.idReclam = this.SelectedItem.id;
    let extensionFile = "";
    let typeFile = "";
    let fileNameOnDisk = "";

    this.wait = true;

    this.srvVisProb.getIncidentById(this.idReclam).subscribe((data) => {
      this.reclammation = data;
      this.wait = false;

      if (data.files && data.files.length > 0) {
        data.files.forEach(file => {

          extensionFile = [Object.keys(file)[0]][0];
          fileNameOnDisk = file[Object.keys(file)[0]];

          if (extensionFile == "mp4" || extensionFile == "mov") {
            this.urlVideo.push(fileNameOnDisk)
          } else if (extensionFile == "jpg" || extensionFile == "png" || extensionFile == "PNG") {
            this.urlImgs.push(fileNameOnDisk)
          }
        });
      }

    });
  }

  openImg(img) {
    // var image = new Image();
    // image.src = img;

    window.open(img);
    // w.document.write(image.outerHTML);
  }

  return() {
    sessionStorage.setItem("isReturn", "1");
    console.log("visual")
    this.utilSrv.navigateTo("MesSignalementsComponent");
  }

}
