import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../../helpers/GfxHttpSrv';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class ResidenceNotifService {
  private Notifications = new BehaviorSubject([]);
  private CompteurNotifsNonLu = new BehaviorSubject([]);
  NotificationsState = this.Notifications.asObservable();
  CompteurNotifsNonLuState = this.CompteurNotifsNonLu.asObservable();
  notifNonLuSize: number;

  constructor(private gfxhttp: GfxHttpSrv) { }

  marqueLu(idNotif) {
    return this.gfxhttp.get('notifications/marqueLu', { idNotif: idNotif });
  }

  changeNotificationsState(state) {
    this.Notifications.next(state)
  }

  changeCompteurNotifsNonLuState(state) {
    this.CompteurNotifsNonLu.next(state)
  }

  getNotifs() {
    return this.gfxhttp.get('notifications/all')
}
}
