import { Component, OnInit, Type } from "@angular/core";
import { AppConfig } from "../../../core/services/AppConfig";
import { AppMenu } from "../../../core/services/AppMenu";
import { Observable } from "rxjs";
import { MenuItem } from "../../../core/models/MenuItem";
import { Store } from "@ngrx/store";
import { GfxState, getMenuState, getUserState, getGfxNotificationsState } from "../../../store/gfxstate";
import { IUser } from "../../../authentication/models/IUser";
import { AuthService } from "../../../authentication/services/AuthService";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmDialogComponent } from "../../../core/components/confirm.dialog/confirm.dialog.component";
import { Router } from "@angular/router";
import { MatSnackBar } from "@angular/material";
import { IGfxNotification } from "../../../core/components/gfxnotifications/IGfxNotification";
import { GfxNotificationsService } from "../../../core/components/gfxnotifications/gfx.notifications.service";
import { GfxAppContentService } from "../../../core/components/app.content/gfx.app.content.service";
import { GfxMiscSrv } from "../../../../app/../gfx/helpers/gfx.misc.service";
import { appRefsSrv } from "../../../../app/common/services/app.refs.service";
import { ISelectData } from "../../../../app/common/models/ISelectData";

@Component({
    selector: "home-container",
    templateUrl: "./default.home.component.html",
    styleUrls: ["./default.home.component.css"]
})

export class DefaultHomeComponent implements OnInit {

    public codeActif: string;
    public appName: string;
    public appVersion: string;
    private menuObs: Observable<MenuItem[]>;
    notifObs: Observable<IGfxNotification[]>;
    public currMenu: MenuItem[];
    public selectedMenu: MenuItem;
    public selectedMenuItem: MenuItem;
    public currUser: IUser;
    public nbrOfNotifications: number = 0;
    public lastNotifications: IGfxNotification[];
    public nbrOfNotifToShow: number = 5;
    componentName: String;
    profiles: ISelectData[];
    defaultTdb;
    userphoto: string = "assets/ONEA-logo.png";
    constructor(private appConfig: AppConfig,
        private appMenu: AppMenu,
        private refsrv: appRefsSrv,
        private store: Store<GfxState>,
        private authService: AuthService,
        public dialog: MatDialog,
        private router: Router,
        public snackBar: MatSnackBar,
        private utilSrv: GfxMiscSrv,
        private notificationsService: GfxNotificationsService,
        private appContentService: GfxAppContentService) {
        this.appName = appConfig.get("AppName");
        this.appVersion = appConfig.get("AppVersion");
        this.menuObs = this.store.select(getMenuState);
        this.appMenu.getMenu();

        //Get current user
        let usrObs: Observable<IUser>;
        usrObs = this.store.select(getUserState);
        let sub = usrObs.subscribe((s) => {
            if (s == null)
                this.router.navigate(['login'], { skipLocationChange: true });
            else {
                this.currUser = s;
                this.userphoto = s.photo;
            }
        });
    }

    ngOnInit(): void {
        this.menuObs.subscribe((m: MenuItem[]) => {
            this.currMenu = m;
        });

        this.selectedMenu = { code: null, id: null, label: null, mdIcon: null, children: null };
        this.selectedMenuItem = { code: null, id: null, label: null, mdIcon: null, children: null };

        this.refsrv.getProfilesByUser(this.currUser.id).subscribe(data => {
            this.profiles = data
            let defaultProfile = data.filter(function(p) {
                return p.isDefault==1;
            });
            this.defaultTdb = defaultProfile[0]["defaultTdb"];
            this.utilSrv.navigateTo(defaultProfile[0]["defaultView"]);
         } );

        /* this.notificationsService.getNotifications();
        this.notifObs = this.store.select(getGfxNotificationsState);
        this.notifObs.subscribe((notifs: IGfxNotification[]) => {
            if (notifs && Array.isArray(notifs)) {
                this.nbrOfNotifications = notifs.length;
                const nbrToShow = this.notificationsService.getNbrOfNotifToShow();
                if (nbrToShow)
                    this.nbrOfNotifToShow = nbrToShow;
                this.lastNotifications = notifs.slice(0, this.nbrOfNotifToShow);
            }

        }); */
    }

    logOut(event) {
        this.utilSrv.confirmDialog("Annuler", 'Déconnecter', "Etes-vous sûr de vouloir vous déconnecter ?", this.authService.logout.bind(this));
    }

    ChangeContent(item) {

        this.codeActif = '';
        if (!item.children) { // Si l'item cliqué n'as pas de child
            let selectedMenuArr = this.currMenu.filter(function (rootMenu) {
                let test = false;
                if (rootMenu.children != null) {
                    rootMenu.children.forEach(function (child) {
                        if (child.id === item.id) {
                            test = true;
                        }
                    })
                }
                return test;
            });

            if (selectedMenuArr.length > 0) { // Si l'item cliqué a un parent, 
                this.selectedMenu = selectedMenuArr[0];
            }
            else {
                this.selectedMenu = item;
            }

            this.selectedMenuItem = item;

            if (this.componentName == item.code)
                this.appContentService.changeContent.emit({ ComponentName: item.code, Data: {} });
            this.componentName = item.code;
        }
        else {
            this.selectedMenuItem = { code: null, id: null, label: null, mdIcon: null, children: null };
            this.selectedMenu = item;
        }
    }

    showNotifications() {
        const notifComponent = this.appConfig.get("NotificationsComponent");
        if (notifComponent) {
            this.componentName = notifComponent;
        } else {
            setTimeout(() => {
                this.snackBar.open(this.appName + " : le composant de notifications n'est pas enregistré ou n'existe pas", "OK", {
                    duration: 3000,
                });
            });
        }
    }

    goToAccueil() {
        this.utilSrv.navigateTo("GfxAproposComponent");
    }

    goToApropos() {
        this.utilSrv.navigateTo("GfxAproposComponent");
    }

    goToProfil() {
        this.utilSrv.navigateTo("GfxProfilComponent");
    }

    getProfilesMenus(idProfile) {
          this.currMenu=[]
          sessionStorage.setItem('idProfile', JSON.stringify(idProfile));
        this.appMenu.getMenu(idProfile);

       this.profiles.forEach(element => {
           if(element.id==idProfile){
            this.defaultTdb = element["defaultTdb"];
            if(this.defaultTdb)
                this.utilSrv.navigateTo(this.defaultTdb);
            else
                this.utilSrv.navigateTo("GfxDefaultViewComponent");
           }
       });
    }

    displayDashBord() {
        
        this.utilSrv.navigateTo(this.defaultTdb);
    }


}