import { Component, OnInit } from '@angular/core';
import { GfxAppContentService } from '../../../../gfx/core/components/app.content/gfx.app.content.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { appRefsSrv } from '../../../common/services/app.refs.service';
import { IGfxListConfig } from '../../../../gfx/core/components/gfxlist/IGfxListConfig';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GfxListOrderDirectionEnum } from '../../../../gfx/core/components/gfxlist/GfxListOrderDirectionEnum';

@Component({
  selector: 'app-ref-lieux-prob',
  templateUrl: './ref-lieux-prob.component.html',
  styleUrls: ['./ref-lieux-prob.component.css']
})
export class RefLieuxProbComponentList implements OnInit {
  private lieuList: any;
  private idProfil;
  private filterForm: FormGroup;
  listCfg: IGfxListConfig;

  constructor(private fb: FormBuilder, private appRefSrv: appRefsSrv, private utilSrv: GfxMiscSrv) {

  }

  ngOnInit() {
    this.idProfil = sessionStorage.getItem('idProfile');

    this.listCfg = {
      Code: "lieuProbList",
      Title: "Liste des lieux de réclamation",
      DataUrl: "lieuprobleme",
      ListOptions: {
        OrderBy: "id",
        OrderDir: GfxListOrderDirectionEnum.ASC,
        HeaderPanelExpanded: false,
        EnableBuiltInFilter: true,
        EnableCustomFilter: true,
        ExpandCustomFilter: false,
      },
      Columns: [
        { Name: "lib", Label: "LIBELLÉ", Render: "lib" },
      ],

      Actions: [
        { Name: "Edit", Label: "Modifier", ComponentName: "RefLieuProbFormComponent", FaIcon: "fa-edit", Params:{hideAction: false}  },
        {
          Name: "Supprimer", Label: "Supprimer", ComponentName: "RefLieuxProbComponentList", FaIcon: "fa-trash-alt", Params: {hideAction: false,
            confirmAction: {
              cancelBtn: "Non",
              confirmBtn: "Oui",
              msg: "Êtes-vous sûr de vouloir supprimer cet élément ?",
              url: "lieuprobleme/supprimer"
            }
          }
        }
      ],

      PaginatorConfig: {
        StaticMode: false,
        PageSize: 10,
        SizeOptions: [10, 20, 50, 100],
        ShowFirstLastBtns: true
      },
      ExportOptions: {
        FileName: "lieux",
        Columns: ["Libellé"],
        EnableEXCEL: true,
        EnableWORD: false,
        EnablePDF: false,
      }
    }

  }

  NewLieuProb(){
    this.utilSrv.navigateTo("RefLieuProbFormComponent");
  }

}
