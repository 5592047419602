import { Component, OnInit } from '@angular/core';
import { IGfxListActionComponent } from '../../../../gfx/core/components/gfxlist/IGfxListActionComponent';
import { appRefsSrv } from '../../../common/services/app.refs.service';
import { ISelectData } from '../../../common/models/ISelectData';
import * as moment from 'moment'
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SuiviReclamationService } from '../../services/suivi-reclamation.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { VisualisationProbService } from '../../services/visualisation-prob.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-suivi-reclamation',
  templateUrl: './suivi-reclamation.component.html',
  styleUrls: ['./suivi-reclamation.component.css']
})
export class SuiviReclamationComponent implements OnInit, IGfxListActionComponent {
  SelectedItem;
  reclamation: { id: '', reference: '' };
  types: ISelectData
  suiviReclamList: any
  newDate
  ficheSuiviForm: FormGroup
  private suivi = {
    idType: '', date: '', commentaire: '',
  };
  idReclam
  dateLastSuivi
  constructor(private datePipe: DatePipe,private srvVisProb: VisualisationProbService, private fb: FormBuilder, private refsrv: appRefsSrv, private suivirv: SuiviReclamationService, private utilSrv: GfxMiscSrv) { }

  ngOnInit() {
    this.newDate = moment(new Date()).format('YYYY-MM-DD')
    this.dateLastSuivi = ''

    this.reclamation = { id: '', reference: '' };

    if (this.SelectedItem) {
      localStorage.removeItem('idReclam');
      this.reclamation = this.SelectedItem
      this.idReclam = this.reclamation.id
    }

    if (localStorage.getItem('idReclam')) {
      this.idReclam = JSON.parse(localStorage.getItem('idReclam'));
      this.srvVisProb.getIncidentById(this.idReclam).subscribe((data) => {
        this.reclamation = data;
      });
    }

    this.ficheSuiviForm = this.fb.group({
      idType: [this.suivi.idType, Validators.required],
      date: [this.suivi.date, Validators.required],
      commentaire: [this.suivi.commentaire],
    });

    this.refsrv.getFicheSuiviByReclamation(this.idReclam).subscribe((data) => {
      this.suiviReclamList = data.data;
      if (data.dateLastSuivi) {
        this.dateLastSuivi =data.dateLastSuivi
      }

    });

    this.refsrv.getTypes().subscribe((data) => {
      this.types = data;
    });
  }

  save() {

    let data = this.ficheSuiviForm.value;
    const formData: FormData = new FormData();

    formData.append("idType", data.idType);
    formData.append("date", data.date);
    formData.append("commentaire", data.commentaire);
    formData.append("idReclam", this.idReclam);

    this.suivirv.post(formData).subscribe((data) => {
      if (data.msg == "ok") {
        this.refrech();
      } else {
        this.utilSrv.navigateTo("MesSignalementsComponent");
      }

    })
  }

  refrech() {
    localStorage.setItem('idReclam', JSON.stringify(this.idReclam));
    this.utilSrv.navigateTo("SuiviReclamationComponent");
  }

  return() {
    sessionStorage.setItem("isReturn", "1");
    localStorage.removeItem('idReclam');
    this.utilSrv.navigateTo("MesSignalementsComponent");
  }

}
