import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class RefNatureProbService {

  constructor(private gfxhttp: GfxHttpSrv) { }

  getNatureProb(id) {
      return this.gfxhttp.get('natureprobleme/findOne', {idNature:id});
  }

  post(data) {
      return this.gfxhttp.post('natureprobleme', data);
  }

  put(data) {
      return this.gfxhttp.put('natureprobleme', data);
  }

}