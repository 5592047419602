import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../../services/AppConfig';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  theme: any;

  constructor(private apConfig: AppConfig) { }

  ngOnInit() {
    this.theme = this.apConfig.get("theme");
  }

}
