import { Component, Output, EventEmitter } from "@angular/core";
import { Note } from "./note.component";
import { NoteService } from "../services/note.service";
import * as $ from 'jquery/dist/jquery';
import { bootstrap } from 'bootstrap/dist/js/bootstrap';
import { NoteForm } from "./note.form.component";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
    selector:'note-list',
    templateUrl: './note.list.component.html'
})
export class NoteList {
    noteCourante : Note;
    isEdit : boolean;
    notes : Note[];
    constructor(private ns : NoteService) {
        let obs = ns.getNotes();
        obs.subscribe((notes : Note[]) => {
            this.notes = notes;
        });
    }

    newNote () {
        this.noteCourante = new Note("");
        this.isEdit = false;
    }
    editNote(note : Note) {
        this.noteCourante = note;
        this.isEdit = true;
    }

    updateList(note) {
        let obs = this.ns.getNotes();
        obs.subscribe((notes : Note[]) => {
            this.notes = notes;
        });
    }

    deleteNote(note:Note) {
        if (confirm("Supprimer la note ?")) {
            let obs = this.ns.deleteNote(note);
            obs.subscribe((retour : any) => {
                let indexToDelete = this.notes.indexOf(note);
                if (indexToDelete !== -1) {
                this.notes.splice(indexToDelete,1);
                }
            });
        }
    }
}