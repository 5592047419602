import { Component, OnInit, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-be-msg-error',
  templateUrl: './be-msg-error.component.html',
  styleUrls: ['./be-msg-error.component.css']
})
export class BeMsgErrorComponent implements OnInit {

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: any, private snackBar: MatSnackBar,) { }

  ngOnInit() {
    console.log(this.data);
    
  }

  close () {
    this.snackBar.dismiss();
  }

}
