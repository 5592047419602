import { Component, OnInit, Injector } from "@angular/core";
import { IGfxListFilterElem } from "../../core/components/gfxlist/IGfxListFilterElem";
import { GfxListFilterComponent } from "../../core/components/gfxlist/GfxListFilterComponent";
import { ProfileService } from "../services/profile.service";
import { HttpClient, HttpClientModule } from "@angular/common/http";

@Component({
    selector: "gfx-users-list-filter",
    templateUrl: "gfx.users.list.filter.html"
})
export class GfxUsersListFilterComponent extends GfxListFilterComponent implements OnInit {

    Login: string;
    NomPrenom: string;
    Profiles: any[];
    SelectedProfile: any;
    ngOnInit(): void {
        const httpClient = this.InjectorInst.get<HttpClient>(HttpClient);
        httpClient.get<any>("profiles").subscribe((data) => {
            this.Profiles = data.lines;

        })

    }

    Actualiser() {
       
        this.SetElementValue("login", this.Login);
        this.SetElementValue("profile", this.SelectedProfile);
        this.RefreshList();

    }

    constructor(injector: Injector) {
        super(injector);
    };



}