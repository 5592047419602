import { Component, OnInit } from '@angular/core';
import { TbdService } from '../../services/tbd.service';
import * as Highcharts from 'highcharts';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';

@Component({
  selector: 'app-tbd',
  templateUrl: './tbd.component.html',
  styleUrls: ['./tbd.component.css']
})
export class TbdComponent implements OnInit {
  private listNouvDem: any;
  private listEncoursDem: any;
  private listResoluDem: any;

  configNouvDem: any;
  configEncoursDem: any;
  configResoluDem: any;

  countNouvDem = 0;
  countEncoursDem = 0;
  countResoluDem = 0;

  a: Number = 1
  b: Number = 1
  c: Number = 1

  collection = { count: 0, data: [] };
  private idProfil
  statis
  recapAnnuel
  nbTotalProb: Number
  Highcharts = Highcharts;
  recapChart
  dataChart

  constructor(private tbdSrv: TbdService, private utilSrv: GfxMiscSrv) {

  }

  ngOnInit() {
    this.idProfil = sessionStorage.getItem('idProfile');
    this.dataChart = []

    this.statis = { nonTraites: '', encours: '', resolus: '', soumis: '', refuses: '' }
    this.recapAnnuel = []
    this.nbTotalProb = 0

    this.tbdSrv.getStatisAnnuel().subscribe((data) => {
      this.statis = data
      // this.statis.soumis = Number(data.nonTraites) + Number(data.encours) + Number(data.resolus)
    });

    this.tbdSrv.getRecapAnnuel().subscribe((data) => {
      this.recapAnnuel = data.recapList
      this.nbTotalProb = data.total
      // #F9B777 #EFA74C
      if (this.recapAnnuel && this.recapAnnuel.length > 0) {
        this.recapAnnuel.forEach(element => {
          element["pourcentage"] = ((Number(element.nombre) / Number(this.nbTotalProb)) * 100).toFixed(2)
          let arr = []
          arr.push(element.type)
          arr.push(Number(element.nombre))
          this.dataChart.push(arr)
        });
      }

      this.recapChart = {
        title: {
          text: ''
        },
        series: [{
          type: 'pie',
          name: '',
          colors: [
            '#FBAB58',
            '#A47A00',
          ],
          data: this.dataChart
        }]
      };

    });

  }

  goToMesSignnalementsByEtat(etat) {
    if (etat == "refuse") {
      localStorage.setItem('etat', JSON.stringify("3"));
    }
    if (etat == "nonTraites") {
      localStorage.setItem('etat', JSON.stringify("0"));
    }
    if (etat == "enCours") {
      localStorage.setItem('etat', JSON.stringify("1"));
    }
    if (etat == "resolu") {
      localStorage.setItem('etat', JSON.stringify("2"));
    }
    this.utilSrv.navigateTo("MesSignalementsComponent");
  }

}
