import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, ComponentFactoryResolver, Type } from "@angular/core";
import { MatTableDataSource, MatPaginator, MatSnackBar } from "@angular/material";

import { Observable } from "rxjs";

import { Store } from "@ngrx/store";

import * as _ from 'underscore';
import { IGfxListConfig } from "../../../../gfx/core/components/gfxlist/IGfxListConfig";
import { GfxListCustomFilterDirective } from "../../../../gfx/core/components/gfxlist/gfx.list.custom.filter.directive";
import { IGfxListData } from "../../../../gfx/core/components/gfxlist/IGfxListData";
import { IGfxListFilter } from "../../../../gfx/core/components/gfxlist/IGfxListFilter";
import { IGfxListSearch } from "../../../../gfx/core/components/gfxlist/IGfxListSearch";
import { getGfxListDataSelector, GfxState } from "../../../../gfx/store/gfxstate";
import { IGfxListAction } from "../../../../gfx/core/components/gfxlist/IGfxListAction";
import { GfxDictionary } from "../../../../gfx/core/models/GfxDictionary";
import { GfxChangeContentEventData } from "../../../../gfx/core/components/app.content/gfx.change.content.event.data";
import { GfxListFilterComponent } from "../../../../gfx/core/components/gfxlist/GfxListFilterComponent";
import { IGfxListFilterElem } from "../../../../gfx/core/components/gfxlist/IGfxListFilterElem";
import { AppConfig } from "../../../../gfx/core/services/AppConfig";
import { GfxListService } from "../../../../gfx/core/components/gfxlist/gfx.list.service";
import { GfxMiscSrv } from "../../../../gfx/helpers/gfx.misc.service";
import { GfxAppContentService } from "../../../../gfx/core/components/app.content/gfx.app.content.service";
import { GfxListPaginatorService } from "../../../../gfx/core/components/gfxlist/gfx.list.paginator.service";
import { GfxExcelService } from "../../../../gfx/helpers/GfxExcelService";



@Component({
  selector: 'propre-list',
  templateUrl: './propre-list.component.html',
  styleUrls: ['./propre-list.component.css']
})
export class PropreListComponent implements OnInit, AfterViewInit {


  @Input('config') config: IGfxListConfig;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  public dataObs: Observable<IGfxListData>;
  renderedColumns: any[];
  @ViewChild(GfxListCustomFilterDirective) customFilterDirective: GfxListCustomFilterDirective;
  dataSource: MatTableDataSource<{}>;
  gfxPaginator: any = {};
  gfxListPanelExpanded: boolean = false;
  curr_page_size: number;
  curr_page: number;
  curr_filter: IGfxListFilter;
  curr_list: IGfxListData;
  curr_saved_searchs: IGfxListSearch[];
  curr_search_element: string;
  curr_search_value: any;
  appName: string;
  filter_elements = [];
  intervalSearch = false;
  ngOnInit(): void {
      if (this.config.Filter)
          this.loadCustomFilter(this.config.Filter.CustomFilterComponent);
      this.renderedColumns = this.config.Columns.map(col => col.Name);
      this.renderedColumns.push("actionsList");
      if (this.config.Filter)
          this.curr_filter = this.config.Filter;
      this.curr_saved_searchs = [];
      this.filter_elements = this.config.Columns.map((col) => {
          return { value: col.Name, label: col.Label };
      })
      this.gfxListPanelExpanded = this.config.ListOptions.HeaderPanelExpanded;
      this.curr_page_size = this.config.PaginatorConfig.PageSize;
      this.curr_page = (this.config.PaginatorConfig.StaticMode) ? null : 1;
      this.getData(this.curr_page);
      this.dataObs = this.store.select(getGfxListDataSelector(this.config.Code));
      this.dataObs.subscribe((current_list: any) => {
          this.curr_list = current_list || {};
          console.log("this.curr_list.Data");
          console.log(this.curr_list.Data);
          this.dataSource = new MatTableDataSource(this.curr_list.Data);
          if (this.config.PaginatorConfig.StaticMode)

              this.dataSource.paginator = this.paginator;
          else
              this.setPage(this.curr_page, this.curr_list.TotalItems, this.curr_page_size);
      })

  }

  ngAfterViewInit() {

  }

  toggleIntervalSearch() {
      console.log("intervalSearch: ", this.intervalSearch);
      if(this.intervalSearch) {
          this.curr_search_value = "";
          this.intervalSearch = false;
      } else {
          this.curr_search_value = {
              dtDu : "",
              dtAu : ""
          };

          this.intervalSearch = true;
      }
  }

  ProcessAction(item, act: IGfxListAction) {
      if (act.Params && act.Params.confirmAction) {
          this.utilSrv.confirmDialog(act.Params.confirmAction.cancelBtn, act.Params.confirmAction.confirmBtn,
              act.Params.confirmAction.msg,
              false, act.Params.confirmAction.url, { id: item.id });
      } else {
          let infoToSend: GfxDictionary<any> = {}
          infoToSend["SelectedItem"] = item;
          infoToSend["SourceComponentName"] = "nothingForNow";
          infoToSend["Params"] = act.Params;
          let eventData: GfxChangeContentEventData = { ComponentName: "", Data: {} };
          eventData.ComponentName = act.ComponentName;
          eventData.Data = infoToSend;
          this.appContentService.changeContent.emit(eventData);
      }
  }


  actionHidden(item, cb) {
      if (!cb)
          return true;
      return cb(item);
  }

  getData(page: number) {
      if (!page) {
          this.listService.getData(this.config.Code,
              this.config.DataUrl,
              this.curr_filter,
              0,
              0,
              this.config.ListOptions.OrderBy,
              this.config.ListOptions.OrderDir);
          return;
      }
      this.curr_page = page;
      let offset = (this.curr_page - 1) * this.curr_page_size;
      this.listService.getData(this.config.Code,
          this.config.DataUrl,
          this.curr_filter,
          offset,
          this.curr_page_size,
          this.config.ListOptions.OrderBy,
          this.config.ListOptions.OrderDir);
  }

  setPage(page: number, totalItem: number, pageSize: number) {

      // get pager object from service
      this.gfxPaginator = this.listPaginatorService.getPaginator(totalItem, page, pageSize);
      // get current page of items

  }
  excelExport(exportAll: boolean) {
      var renameProp = this.miscSrv.renameProp
      let properties2export = Object.assign([], this.config.ExportOptions.Columns);
      let fileName = this.config.ExportOptions.FileName;
      let data2export = [];
      if (!exportAll) {
          data2export = _.map(this.curr_list.Data, function (obj) {
              return _.pick(obj, properties2export);
          });
          this.excelService.exportAsExcelFile(data2export, fileName);
      } else {

          this.listService.getDataObservable(
              this.config.Code,
              this.config.DataUrl,
              this.curr_filter && this.curr_filter.FilterElements ? this.curr_filter.FilterElements : {},
              0,
              0,
              this.config.ListOptions.OrderBy,
              this.config.ListOptions.OrderDir).subscribe((listOfItems) => {
                  data2export = _.map(listOfItems.items, function (obj) {
                      return _.pick(obj, properties2export);

                  });

                  var cols = this.config.ExportOptions.Columns.reverse();
                  var heads = this.config.ExportOptions.Headers.reverse();
                  var newDataToExport = [];
                  data2export.forEach(function (element) {
                      for (let i = 0; i < cols.length; i++) {
                          element = renameProp(cols[i], heads[i], element);
                      }
                      newDataToExport.push(element)
                  });

                  this.excelService.exportAsExcelFile(newDataToExport, fileName);
              });
      }

  }

  saveSearch(element: any, label: any, value: any) {
      let s: IGfxListSearch = {
          Element: (element) ? element : "Tous",
          Label: label,
          Value: value
      }

    //   if(this.intervalSearch && s.Value && s.Value.dtDu) {
    //       s.Value.dtDu = this.miscSrv.dtMillisToFr(s.Value.dtDu);
    //       s.Value.dtAu = this.miscSrv.dtMillisToFr(s.Value.dtAu);
    //   }

      this.curr_saved_searchs.push(s);
  }
  removeSearch(search: IGfxListSearch) {
      let index = this.curr_saved_searchs.indexOf(search);
      if (index >= 0) {
          this.curr_saved_searchs.splice(index, 1);
          this.filterData(search.Element, "");


      }
  }

  filterData(element: any, searchStr: string) {
      if (searchStr)
          this.saveSearch(element.value, element.label, searchStr);
      else {
          let tmpFlt = this.curr_saved_searchs[this.curr_saved_searchs.length - 1];
          if (tmpFlt) {
              element = tmpFlt.Element;
              searchStr = tmpFlt.Value;
          }
      }
      if (this.config.PaginatorConfig.StaticMode) {
        //   this.dataSource.filterPredicate =
        //       (data: any, filter: string) => {
        //           for (let o of this.curr_saved_searchs) {
        //               if(o.Value && o.Value.dtDu && o.Value.dtAu) {
        //                   console.log(data[o.Element], ' :data[o.Element]')
        //                   if(!data[o.Element] || !this.inInterval(data[o.Element], o.Value))
        //                   return false;
        //               } 
        //               else if (!data[o.Element] || !(data[o.Element].toUpperCase().toString().indexOf(o.Value.toUpperCase()) >= 0))
        //                   return false;
        //           }
        //           return true;
        //       };
        //   this.dataSource.filter = searchStr;
      }
      this.curr_search_value = "";
      this.intervalSearch = false;
      console.log('curr_saved_searchs: ', this.curr_saved_searchs)
  }

  pageSizeChanged(value: any) {
      this.getData(1);
      this.setPage(this.curr_page, this.curr_list.TotalItems, this.curr_page_size);

  }

  inInterval(elem, val) {
      console.log(elem, val);
      return elem <= val.dtDu && elem >= val.dtAu;
  }

  loadCustomFilter(filterClass: typeof GfxListFilterComponent) {

      const filterComponentFactoryClass = this.componentFactoryResolver
          .resolveComponentFactory(filterClass);
      var viewContainerRef = this.customFilterDirective.viewContainerRef;
      viewContainerRef.clear();

      const cRef = viewContainerRef.createComponent(filterComponentFactoryClass);
      const filterComponent = cRef.instance as GfxListFilterComponent;
      filterComponent.Filters = this.config.Filter.FilterElements;
      filterComponent.RefreshEvent.subscribe((elems: IGfxListFilterElem[]) => {
          this.curr_filter.FilterElements = elems;
          this.getData(1);


      })
  }

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
      public snackBar: MatSnackBar,
      private appConfig: AppConfig,
      private listService: GfxListService,
      private miscSrv: GfxMiscSrv,
      private store: Store<GfxState>,
      private appContentService: GfxAppContentService,
      private listPaginatorService: GfxListPaginatorService,
      private excelService: GfxExcelService,
      private utilSrv: GfxMiscSrv,
  ) {
      this.appName = appConfig.get("AppName");
  }


}

