import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { IGfxListActionComponent } from "../../core/components/gfxlist/IGfxListActionComponent";
import { GfxAppContentService } from "../../core/components/app.content/gfx.app.content.service";
import { GfxDictionary } from "../../core/models/GfxDictionary";
import { GfxChangeContentEventData } from "../../core/components/app.content/gfx.change.content.event.data";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ProfileService } from "../services/profile.service";
import { appRefsSrv } from "../../../app/common/services/app.refs.service";
import { GfxMiscSrv } from "../../../app/../gfx/helpers/gfx.misc.service";

@Component({
    selector: "gfx-profile-form",
    templateUrl: "gfx.profile.form.component.html"
})
export class GfxProfileFormComponent implements OnInit,IGfxListActionComponent {

  SourceComponentName: string;
  SelectedItem;
  private profileForm: FormGroup;
  private profile = {
    id: '', code: '', libelle: ''
  };

    constructor (private appContentService: GfxAppContentService, private profilesService : ProfileService, private fb: FormBuilder, private refsrv: appRefsSrv, private utilSrv: GfxMiscSrv ) {
    
    }
    
    ngOnInit(): void {
      this.profileForm = this.fb.group({
        id: [this.profile.id],
        code: [this.profile.code, Validators.required],
        libelle: [this.profile.libelle, Validators.required],
        
      });
  
      if (this.SelectedItem && this.SelectedItem.id) {
        this.profilesService.get(this.SelectedItem.id).subscribe((data) => {
          this.profile = data;
          this.profileForm = this.fb.group({
            id: [this.profile.id],
            code: [this.profile.code, Validators.required],
            libelle: [this.profile.libelle, Validators.required],
          });
        });
      }

    }

    return() {
      this.utilSrv.navigateTo("GfxProfilesManagComponent");
    }
  
    save() {
     
      if (this.profileForm.value.id) {
        this.profilesService.put(this.profileForm.value).subscribe((data) => {
          this.return();
        })
      } else {
        this.profilesService.post(this.profileForm.value).subscribe((data) => {
          this.return();
        })
      }
    }
  
  }