import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { IGfxListConfig } from '../../../../gfx/core/components/gfxlist/IGfxListConfig';
import { appRefsSrv } from '../../../common/services/app.refs.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { GfxListOrderDirectionEnum } from '../../../../gfx/core/components/gfxlist/GfxListOrderDirectionEnum';

@Component({
  selector: 'app-ref-nature-prob-list',
  templateUrl: './ref-nature-prob-list.component.html',
  styleUrls: ['./ref-nature-prob-list.component.css']
})
export class RefNatureProbListComponent implements OnInit {
  private natureList: any;
  private idProfil;
  private filterForm: FormGroup;
  listCfg: IGfxListConfig;

  constructor(private fb: FormBuilder, private appRefSrv: appRefsSrv, private utilSrv: GfxMiscSrv) {

  }

  ngOnInit() {
    this.idProfil = sessionStorage.getItem('idProfile');

    this.listCfg = {
      Code: "natureProbList",
      Title: "Liste des NATURES DE LA RÉCLAMATION",
      DataUrl: "natureprobleme",
      ListOptions: {
        OrderBy: "id",
        OrderDir: GfxListOrderDirectionEnum.ASC,
        HeaderPanelExpanded: false,
        EnableBuiltInFilter: true,
        EnableCustomFilter: true,
        ExpandCustomFilter: false,
      },
      Columns: [
        { Name: "lib", Label: "LIBELLÉ", Render: "lib" },
      ],

      Actions: [
        { Name: "Edit", Label: "Modifier", ComponentName: "RefNatureProbFormComponent", FaIcon: "fa-edit" , Params:{hideAction: false} },    
        { Name: "Delete", Label: "Supprimer",  ComponentName: "RefNatureProbListComponent", FaIcon: "fa-trash-alt" , Params:{hideAction: false, confirmAction: {
          cancelBtn: "Non",
          confirmBtn: "Oui",
          msg: "Êtes-vous sûr de vouloir supprimer cet élément ?",
          url: "natureprobleme/supprimer"
        }} },
      ],

      PaginatorConfig: {
        StaticMode: false,
        PageSize: 10,
        SizeOptions: [10, 20, 50, 100],
        ShowFirstLastBtns: true
      },
      ExportOptions: {
        FileName: "nature",
        Columns: ["Libellé"],
        EnableEXCEL: true,
        EnableWORD: false,
        EnablePDF: false,
      }
    }

  }

  NewNatureProb(){
    this.utilSrv.navigateTo("RefNatureProbFormComponent");
  }

}
