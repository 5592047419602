import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GfxState } from '../../store/gfxstate';
import { Store } from '@ngrx/store';
import { MenuAction, UPDATEMENU } from '../../store/actions/menu';
import { MenuItem } from '../models/MenuItem';
import { GfxHttpSrv } from '../../helpers/GfxHttpSrv';

@Injectable()
export class AppMenu {
    constructor(private http: GfxHttpSrv, private store: Store<GfxState>) { }

    //We get and dipatch the menu to the store
    getMenu(idProfile?) {
        if(!idProfile)
            idProfile = 0;
        this.http.get('menus/menus', { id: idProfile })
            .subscribe((menu: MenuItem[]) => {
                this.store.dispatch(new MenuAction(UPDATEMENU, menu));
            });
    }


}