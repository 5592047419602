import { Action } from "@ngrx/store";
import { IGfxListData } from "../../core/components/gfxlist/IGfxListData";
export const UPDATEDATA = "[GFX] [LIST] UPDATE DATA";
export class GfxListAction implements Action {
    type: string;
    getListData() {
        return this.payload;
    }
    constructor(_type : string, private payload : IGfxListData){
        this.type = _type;
    }
}