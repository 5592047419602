import { Component } from "@angular/core";
import { GfxAppContentService } from "../../core/components/app.content/gfx.app.content.service";
import { IGfxListConfig } from "../../core/components/gfxlist/IGfxListConfig";
import { GfxListOrderDirectionEnum } from "../../core/components/gfxlist/GfxListOrderDirectionEnum";
import { GfxUsersListFilterComponent } from "../../admin/users/gfx.users.list.filter";
import { GfxWorkflowsListFilterComponent } from "./gfx.workflows.list.filter";

@Component({
    selector : "gfx-wf-main",
    templateUrl : "gfx.wf.main.component.html"
})
export class GfxWfMainComponent {
    title = "Paramétrage des workflows métier";
    listCfg: IGfxListConfig;
    
    constructor(private appContentService: GfxAppContentService ) {

    }

    ngOnInit(): void {

        this.listCfg = {
            Code: "UsrList",
            Title: "Liste des workflows",
            DataUrl: "workflow",
            ListOptions: {
                OrderBy : "loginUser",
                OrderDir : GfxListOrderDirectionEnum.ASC,
                HeaderPanelExpanded: false,
                EnableBuiltInFilter: true,
                EnableCustomFilter: true,
                ExpandCustomFilter: false,
            },

            Columns: [
                { Name: "libWfInst", Label: "Libellé", Render: "libWfInst"},
                { Name: "descriptionIns", Label: "Description", Render: "descriptionIns"},
                { Name: "codeWf", Label: "Code", Render: "codeWf"}
            ],
            Filter: {
                CustomFilterComponent : GfxWorkflowsListFilterComponent,
                FilterElements : []
            },
            Actions: [
                { Name: "states", Label: "Etats", ComponentName: "GfxWfStatesComponent", FaIcon: "fa-pencil-alt", Params: {mode : "UPDATE"} },
                { Name: "transitions", Label: "Transiations", ComponentName: "GfxWfTransitionsComponent", FaIcon: "fa-users" }
            ],
            PaginatorConfig: {
                StaticMode: false,
                PageSize: 5,
                SizeOptions: [5,10, 15, 20, 25, 30],
                ShowFirstLastBtns: true
            },
            ExportOptions: {
                FileName: "workflows",
                Columns: ["nom", "prenom", "tel", "adresse"],
                EnableEXCEL: true,
                EnableWORD: false,
                EnablePDF: false,
            }


        }
        
    }
}