
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from "@angular/material/dialog";
import { NgModule } from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import { MatSnackBarModule, MatTableModule, MatPaginatorModule, 
        MatExpansionModule, MatDividerModule, MatTooltipModule, 
        MatCheckboxModule, MatSelectModule, MatChipsModule, 
        MatListModule, MatSidenavModule, MatGridListModule, 
        MatProgressBarModule, MatSliderModule, MatTabsModule, MatRadioModule, MatDatepickerModule, MatNativeDateModule, MatAutocompleteModule } from '@angular/material';
        
import {MatIconModule} from "@angular/material"
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'DD/MM/YYYY',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
    imports: [
     
      MatButtonModule,
      MatCardModule,
      MatDialogModule,
      MatInputModule,
      MatMenuModule,
      MatToolbarModule,
      MatSnackBarModule,
      MatTableModule,
      MatIconModule,
      MatPaginatorModule,
      MatExpansionModule,
      MatDividerModule,
      MatTooltipModule,
      MatCheckboxModule,
      MatSelectModule,
      MatChipsModule,
      MatTabsModule,
      MatListModule,
      MatSidenavModule,
      MatGridListModule,
      MatProgressBarModule,
      MatSliderModule,
      MatBadgeModule,
      MatRadioModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatAutocompleteModule
    ],
    exports: [
      MatButtonModule,
      MatCardModule,
      MatDialogModule,
      MatInputModule,
      MatMenuModule,
      MatToolbarModule,
      MatSnackBarModule,
      MatTableModule,
      MatIconModule,
      MatPaginatorModule,
      MatExpansionModule,
      MatDividerModule,
      MatTooltipModule,
      MatCheckboxModule,
      MatSelectModule,
      MatChipsModule,
      MatTabsModule,
      MatListModule,
      MatSidenavModule,
      MatGridListModule,
      MatProgressBarModule,
      MatSliderModule,
      MatBadgeModule,
      MatRadioModule,
      MatDatepickerModule,
      MatNativeDateModule,
      MatAutocompleteModule
    ],
    providers: [
      { provide: MAT_DATE_LOCALE, useValue: 'fr' }, //you can change useValue
      { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
      { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
    ]
  })
  export class GfxCoreMaterialModule {}
  