import { Component, Input, OnInit, Output, EventEmitter, ViewChild, AfterViewInit, ComponentFactoryResolver, Type } from "@angular/core";
import { IGfxListConfig } from "./IGfxListConfig";
import { MatTableDataSource, MatPaginator, MatSnackBar } from "@angular/material";
import { GfxListService } from "./gfx.list.service";
import { IGfxListFilterElem } from "./IGfxListFilterElem";
import { Observable } from "rxjs";
import { IGfxListData } from "./IGfxListData";
import { Store } from "@ngrx/store";
import { GfxState, getGfxListDataSelector } from "../../../store/gfxstate";
import { IGfxListAction } from "./IGfxListAction";
import { GfxDictionary } from "../../models/GfxDictionary";
import { GfxChangeContentEventData } from "../app.content/gfx.change.content.event.data";
import { GfxAppContentService } from "../app.content/gfx.app.content.service";
import { GfxListPaginatorService } from "./gfx.list.paginator.service";
import { GfxExcelService } from "../../../helpers/GfxExcelService";
import * as _ from 'underscore';
import { IGfxListSearch } from "./IGfxListSearch";
import { GfxListCustomFilterDirective } from "./gfx.list.custom.filter.directive";
import { AppConfig } from "../../services/AppConfig";
import { IGfxListFilter } from "./IGfxListFilter";
import { GfxListFilterComponent } from "./GfxListFilterComponent";
import { GfxMiscSrv } from "../../../helpers/gfx.misc.service";
import { GfxHttpSrv } from "../../../helpers/GfxHttpSrv";


@Component({
    selector: "gfx-list",
    templateUrl: "./gfx.list.component.html",
    styleUrls: ["./gfx.list.component.css"]
})
export class GfxListComponent implements OnInit, AfterViewInit {


    @Input('config') config: IGfxListConfig;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    public dataObs: Observable<IGfxListData>;
    renderedColumns: any[];
    @ViewChild(GfxListCustomFilterDirective) customFilterDirective: GfxListCustomFilterDirective;
    dataSource: MatTableDataSource<{}>;
    gfxPaginator: any = {};
    gfxListPanelExpanded: boolean = false;
    curr_page_size: number;
    curr_page: number;
    curr_filter: IGfxListFilter;
    curr_list: IGfxListData;
    curr_saved_searchs: IGfxListSearch[];
    curr_search_element: string;
    curr_search_value: string;
    appName: string;
    filter_elements = [];
    idProfil
    isReturn: boolean;
    oldOffset: any;
    oldOrderBy: any;
    oldOrderDir: any;

    ngOnInit(): void {
        this.idProfil = sessionStorage.getItem('idProfile');
        this.isReturn = false;
        if (this.config.Filter)
            this.loadCustomFilter(this.config.Filter.CustomFilterComponent);
        this.renderedColumns = this.config.Columns.map(col => col.Name);
        this.renderedColumns.push("actionsList");
        if (this.config.Filter)
            this.curr_filter = this.config.Filter;
        this.curr_saved_searchs = [];
        this.filter_elements = this.config.Columns.map((col) => {
            return { value: col.Name, label: col.Label };
        })
        this.gfxListPanelExpanded = this.config.ListOptions.HeaderPanelExpanded;
        this.curr_page_size = this.config.PaginatorConfig.PageSize;
        this.curr_page = (this.config.PaginatorConfig.StaticMode) ? null : 1;
        this.getData(this.curr_page);
        this.dataObs = this.store.select(getGfxListDataSelector(this.config.Code));
        this.dataObs.subscribe((current_list: any) => {
            this.curr_list = current_list || {};
            this.dataSource = new MatTableDataSource(this.curr_list.Data);
            if (this.config.PaginatorConfig.StaticMode)

                this.dataSource.paginator = this.paginator;
            else
                this.setPage(this.curr_page, this.curr_list.TotalItems, this.curr_page_size);
        })

    }

    ngAfterViewInit() {

    }

    ProcessAction(item, act: IGfxListAction) {
        if (act.Params && act.Params.confirmAction) {
            this.utilSrv.confirmDialog(act.Params.confirmAction.cancelBtn, act.Params.confirmAction.confirmBtn,
                act.Params.confirmAction.msg,
                false, act.Params.confirmAction.url, { id: item.id }, act.ComponentName);

        } else {
            let infoToSend: GfxDictionary<any> = {}
            infoToSend["SelectedItem"] = item;
            infoToSend["SourceComponentName"] = "nothingForNow";
            infoToSend["Params"] = act.Params;
            let eventData: GfxChangeContentEventData = { ComponentName: "", Data: {} };
            eventData.ComponentName = act.ComponentName;
            eventData.Data = infoToSend;
            this.appContentService.changeContent.emit(eventData);
        }
    }


    actionHidden(item, cb) {
        if (!cb)
            return true;
        return cb(item);
    }

    getData(page: number) { 
        if (sessionStorage.getItem('isReturn')) {
            this.isReturn = true;
            this.oldOffset = JSON.parse(sessionStorage.getItem('oldOffset'));
            this.oldOrderBy = JSON.parse(sessionStorage.getItem('oldOrderBy'));
            this.oldOrderDir = JSON.parse(sessionStorage.getItem('oldOrderDir'));
        }
        console.log("gfxlist return", this.config.ListOptions.OrderBy)
        if (!page) {
            this.listService.getData(this.config.Code,
                this.config.DataUrl,
                this.curr_filter,
                0,
                0,
                this.config.ListOptions.OrderBy,
                this.config.ListOptions.OrderDir);
            return;
        }
        this.curr_page = page;
        let offset = (this.curr_page - 1) * this.curr_page_size;
        this.listService.getData(this.config.Code,
            this.config.DataUrl,
            this.curr_filter,
            this.oldOffset>10 ? this.oldOffset : offset,
            this.curr_page_size,
            this.isReturn ? this.oldOrderBy : this.config.ListOptions.OrderBy,
            this.isReturn ? this.oldOrderDir : this.config.ListOptions.OrderDir);

        sessionStorage.setItem("oldOrderBy", JSON.stringify(this.config.ListOptions.OrderBy));
        sessionStorage.setItem("oldOrderDir", JSON.stringify(this.config.ListOptions.OrderDir));
        sessionStorage.setItem("oldPageSize", JSON.stringify(this.curr_page_size));
        sessionStorage.setItem("oldOffset", JSON.stringify(offset));
        sessionStorage.setItem("oldFilter", JSON.stringify(this.curr_filter));

    }

    setPage(page: number, totalItem: number, pageSize: number) {

        // get pager object from service
        this.gfxPaginator = this.listPaginatorService.getPaginator(totalItem, page, pageSize);
        // get current page of items

    }
    excelExport(exportAll: boolean) {
        var renameProp = this.miscSrv.renameProp
        let properties2export = Object.assign([], this.config.ExportOptions.Columns);
        let fileName = this.config.ExportOptions.FileName;
        let data2export = [];
        if (!exportAll) {
            data2export = _.map(this.curr_list.Data, function (obj) {
                return _.pick(obj, properties2export);
            });
            this.excelService.exportAsExcelFile(data2export, fileName);
        } else {

            this.listService.getDataObservable(
                this.config.Code,
                this.config.DataUrl,
                this.curr_filter && this.curr_filter.FilterElements ? this.curr_filter.FilterElements : {},
                0,
                0,
                this.config.ListOptions.OrderBy,
                this.config.ListOptions.OrderDir).subscribe((listOfItems) => {
                    data2export = _.map(listOfItems.items, function (obj) {
                        return _.pick(obj, properties2export);

                    });

                    var cols = this.config.ExportOptions.Columns.reverse();
                    var heads = this.config.ExportOptions.Headers.reverse();
                    var newDataToExport = [];
                    data2export.forEach(function (element) {
                        for (let i = 0; i < cols.length; i++) {
                            element = renameProp(cols[i], heads[i], element);
                        }
                        newDataToExport.push(element)
                    });

                    this.excelService.exportAsExcelFile(newDataToExport, fileName);
                });
        }

    }

    saveSearch(element: any, label: any, value: any) {
        let s: IGfxListSearch = {
            Element: (element) ? element : "Tous",
            Label: label,
            Value: value
        }
        this.curr_saved_searchs.push(s);
    }
    removeSearch(search: IGfxListSearch) {
        let index = this.curr_saved_searchs.indexOf(search);
        if (index >= 0) {
            this.curr_saved_searchs.splice(index, 1);
            this.filterData(search.Element, "");


        }
    }

    filterData(element: any, searchStr: string) {
        if (searchStr)
            this.saveSearch(element.value, element.label, searchStr);
        else {
            let tmpFlt = this.curr_saved_searchs[this.curr_saved_searchs.length - 1];
            if (tmpFlt) {
                element = tmpFlt.Element;
                searchStr = tmpFlt.Value;
            }
        }
        if (this.config.PaginatorConfig.StaticMode) {
            this.dataSource.filterPredicate =
                (data: any, filter: string) => {
                    for (let o of this.curr_saved_searchs) {
                        if (!data[o.Element] || !(data[o.Element].toUpperCase().toString().indexOf(o.Value.toUpperCase()) >= 0))
                            return false;
                    }
                    return true;
                };
            this.dataSource.filter = searchStr;
        }
        this.curr_search_value = "";
    }

    pageSizeChanged(value: any) {
        this.getData(1);
        this.setPage(this.curr_page, this.curr_list.TotalItems, this.curr_page_size);

    }

    loadCustomFilter(filterClass: typeof GfxListFilterComponent) {

        const filterComponentFactoryClass = this.componentFactoryResolver
            .resolveComponentFactory(filterClass);
        var viewContainerRef = this.customFilterDirective.viewContainerRef;
        viewContainerRef.clear();

        const cRef = viewContainerRef.createComponent(filterComponentFactoryClass);
        const filterComponent = cRef.instance as GfxListFilterComponent;
        filterComponent.Filters = this.config.Filter.FilterElements;
        filterComponent.RefreshEvent.subscribe((elems: IGfxListFilterElem[]) => {
            this.curr_filter.FilterElements = elems;
            this.getData(1);


        })
    }

    orderByColumn(column) {console.log("hhhhhhhh", column)
    console.log("rrrrrrrrr", this.config.ListOptions.OrderDir)
        if (this.config.ListOptions.OrderDir == 0) {
            this.config.ListOptions.OrderBy = column;
            this.config.ListOptions.OrderDir = 1;
        } else {
            this.config.ListOptions.OrderBy = column;
            this.config.ListOptions.OrderDir = 0;
        }
        this.getData(this.curr_page);
    }

    constructor(private componentFactoryResolver: ComponentFactoryResolver,
        public snackBar: MatSnackBar,
        private appConfig: AppConfig,
        private listService: GfxListService,
        private miscSrv: GfxMiscSrv,
        private store: Store<GfxState>,
        private appContentService: GfxAppContentService,
        private listPaginatorService: GfxListPaginatorService,
        private excelService: GfxExcelService,
        private utilSrv: GfxMiscSrv,
    ) {
        this.appName = appConfig.get("AppName");
    }


}

