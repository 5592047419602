import { Component, OnInit } from "@angular/core";
import { AppConfig } from "../../../gfx/core/services/AppConfig";

@Component({
    selector: "ref-custom",
    templateUrl: "./demo.ref.custom.component.html"
})
export class RefCustomComponent implements OnInit {

    appName: string;
    ngOnInit(): void {

    }

    constructor(private appConfig: AppConfig) {
        this.appName = appConfig.get("AppName");
    }

}


