import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfig } from "../../services/AppConfig";
import { IGfxNotification } from "./IGfxNotification";
import { GfxState } from "../../../store/gfxstate";
import { Store } from "@ngrx/store";
import { GfxNotificationAction, UPDATENOTIFDATA } from "../../../store/actions/gfxnotifications";
import { IGfxOpResObj } from "../../models/IGfxOpResObj";
import { GfxTypeNotificationEnum } from "./IGfxTypeNotification";

@Injectable()
export class GfxNotificationsService {
    notifBackendAPI: {};
    notifColors: {};
    nbrOfNotifToShow: number = 0;
    constructor(
        private http: HttpClient,
        private appConfig: AppConfig,
        private store: Store<GfxState>) {
        this.notifBackendAPI = this.appConfig.get("Notifications")["BackenAPI"];
        this.notifColors = this.appConfig.get("Notifications")["NotifColors"];
    }
    getNotifications() {
        this.http.get<any>(this.notifBackendAPI["List"] + "?Dismissed=false").subscribe((notifs: IGfxNotification[]) => {
            this.store.dispatch(new GfxNotificationAction(UPDATENOTIFDATA, notifs));
        })
    }

    dismissNotification(notif: IGfxNotification) {
        if (notif && !notif.Dismissed) {
            return this.http.delete<IGfxOpResObj>(this.notifBackendAPI["Dismiss"] + "/" + notif.Id);
        }
    }

    getColor(notif: IGfxNotification) {
        let colorStr: string = "#0065c4";
        switch (notif.Type) {
            case GfxTypeNotificationEnum.Info:
                colorStr = this.notifColors["Info"];
                break;
            case GfxTypeNotificationEnum.Warning:
                colorStr = this.notifColors["Warning"];
                break;
            case GfxTypeNotificationEnum.Error:
                colorStr = this.notifColors["Error"];
                break;
            default:
                colorStr = this.notifColors["Default"];;
                break;
        }
        return colorStr;
    }

    getNotifIcon(notif: IGfxNotification) {
        let iconStr: string = "fa-file-o";
        switch (notif.Type) {
            case GfxTypeNotificationEnum.Info:
                iconStr = "fa-info";
                break;
            case GfxTypeNotificationEnum.Warning:
                iconStr = "fa-exclamation-circle";
                break;
            case GfxTypeNotificationEnum.Error:
                iconStr = "fa-exclamation-triangle";
                break;
            default:
                iconStr = "fa-info";
                break;
        }
        return iconStr;
    }

    getNbrOfNotifToShow(): number {
        this.nbrOfNotifToShow = this.appConfig.get("Notifications")["NbrOfNotifToShow"];
        if (this.nbrOfNotifToShow)
            return this.nbrOfNotifToShow;
        else
            return 0;
    }

}