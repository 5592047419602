import { Component, OnInit } from '@angular/core';
import { IGfxListConfig } from '../../../../gfx/core/components/gfxlist/IGfxListConfig';
import { GfxAppContentService } from '../../../../gfx/core/components/app.content/gfx.app.content.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { appRefsSrv } from '../../../common/services/app.refs.service';
import { GfxListOrderDirectionEnum } from '../../../../gfx/core/components/gfxlist/GfxListOrderDirectionEnum';
import { GfxListFilterComponent } from '../../../../gfx/core/components/gfxlist/GfxListFilterComponent';
import { FormGroup, FormBuilder } from '@angular/forms';
import { LocataireListFilterComponent } from '../../../reclam/components/locataire-list-filter/locataire-list-filter.component';


@Component({
    selector: 'app-locataire-list',
    templateUrl: './locataire-list.component.html',
    styleUrls: ['./locataire-list.component.css']
})
export class LocataireListComponent implements OnInit {

    private locataireList: any;
    private idProfil;
    private filterForm: FormGroup;
    listCfg: IGfxListConfig;
  
    constructor(private fb: FormBuilder, private appRefSrv: appRefsSrv, private utilSrv: GfxMiscSrv) {
  
    }
  
    ngOnInit() {
      this.idProfil = sessionStorage.getItem('idProfile');
  
      this.listCfg = {
        Code: "locataireList",
        Title: "Liste des locataires",
        DataUrl: "locataire",
        ListOptions: {
          OrderBy: "idLocataire",
          OrderDir: GfxListOrderDirectionEnum.ASC,
          HeaderPanelExpanded: false,
          EnableBuiltInFilter: true,
          EnableCustomFilter: true,
          ExpandCustomFilter: false,
        },
        Columns: [
          { Name: "loginuser", Label: "Identifiant", Render: "loginuser" },
          { Name: "nomprenom", Label: "Nom complet", Render: "nomprenom" },
          { Name: "appartement", Label: "Appartement", Render: "appartement" },
          { Name: "mail", Label: "Email", Render: "mail" },
          { Name: "teluser", Label: "Téléphone", Render: "teluser" },
        ],
        Filter: {
          CustomFilterComponent: LocataireListFilterComponent,
          FilterElements: [
            { Name: "locataire", Field: "f1", Operator: "=", Value: "" },
            { Name: "nom", Field: "f2", Operator: "Like", Value: "" },
            { Name: "tel", Field: "f3", Operator: "=", Value: "" },
          ]
        },
        Actions: [
          { Name: "Edit", Label: "Modifier", ComponentName: "LocataireFormComponent", FaIcon: "fa-edit", Params:{hideAction: false} },
        ],
  
        PaginatorConfig: {
          StaticMode: false,
          PageSize: 10,
          SizeOptions: [10, 20, 50, 100],
          ShowFirstLastBtns: true
        },
        ExportOptions: {
          FileName: "locataires",
          Columns: ["Identifiant", "Nom complet"],
          EnableEXCEL: true,
          EnableWORD: false,
          EnablePDF: false,
        }
      }
  
    }
  
    NewLoc(){
      this.utilSrv.navigateTo("LocataireFormComponent");
    }
  
  }
  