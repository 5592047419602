import { IGfxListData } from "../../core/components/gfxlist/IGfxListData";
import * as gfxlist from "../actions/gfxlist"
export function reducer(state: IGfxListData[], action: gfxlist.GfxListAction) {
    switch (action.type) {
        case gfxlist.UPDATEDATA:
            let newData = action.getListData();
            let filteredList = [];
            //Remove old entry of this specific list
            if (state) {
                filteredList = state.filter(d => d.Code != newData.Code);
                filteredList.push(newData);
            } else if (newData)
                filteredList.push(newData)

            return filteredList;
        default:
            return state;
    }
}