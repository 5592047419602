import { Component, OnInit } from '@angular/core';

import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AbstractControl } from '@angular/forms';

import { ProfilUserService } from '../user.menu/services/profil.user.service';
import { GfxMiscSrv } from "../../../../../app/../gfx/helpers/gfx.misc.service";
import { appRefsSrv } from "../../../../../app/common/services/app.refs.service";
import { Store } from '@ngrx/store';
import { GfxState, getUserState } from '../../../../store/gfxstate';
import { Observable } from 'rxjs';
import { IUser } from '../../../../authentication/models/IUser';
import { AuthAction, USRLOGGEDIN } from '../../../../store/actions/auth';

@Component({
  selector: 'profil-user',
  templateUrl: './profil.user.component.html',
})
export class GfxProfilComponent implements OnInit {

  private form: FormGroup;
  private user: any;
  private id: any;
  private password: any;
  private imageSrc: string = "";
  private msg: any = {};
  disableButtValider: boolean;

  constructor(
    private fb: FormBuilder, public userServ: ProfilUserService, private store: Store<GfxState>) {
    let usrObs: Observable<IUser>;
    this.store.select(getUserState).subscribe((user) => {
      this.user = user;
      if(this.user)
      this.imageSrc = this.user.photo;
    });
  }

  ngOnInit(): void {
    this.disableButtValider = false
    this.userServ.get(this.user.id).subscribe((user) => {
      this.imageSrc = user.photo;
    });
    this.form = this.fb.group({
      oldpassword: [''],
      password: ['', [Validators.minLength(4)]],
      confirmPassword: ['', [Validators.minLength(4)]],
      photo: ['']
    },
      { validator: this.MatchPassword });

    this.form.get("oldpassword").patchValue("")

    if (!this.form.value.oldpassword && !this.form.value.password && !this.form.value.confirmPassword && !this.form.value.photo) {
      this.disableButtValider = true
    }
  }

  verifInput() {
    if (this.form.value.oldpassword && this.form.value.password && this.form.value.confirmPassword) {
      this.disableButtValider = false
    } else if (this.form.value.oldpassword != "" || this.form.value.password != "" || this.form.value.confirmPassword != "") {
      this.form.controls["oldpassword"].setValidators([Validators.required]);
      this.form.controls["password"].setValidators([Validators.required]);
      this.form.controls["confirmPassword"].setValidators([Validators.required]);
    } else {
      this.form = this.fb.group({
        oldpassword: [''],
        password: ['', [Validators.minLength(4)]],
        confirmPassword: ['', [Validators.minLength(4)]],
        photo: ['']
      },
        { validator: this.MatchPassword });
    }
  }

  MatchPassword(AC: AbstractControl) {
    const password = AC.get('password').value; // to get value in input tag 
    const confirmPassword = AC.get('confirmPassword').value; // to get value in input tag 
    if (password !== confirmPassword) {
      AC.get('confirmPassword').setErrors({ MatchPassword: true })
    } else {
      return null
    }
  }

  save() {
    let data = this.form.value;
    data.id = this.user.id;
    data.photo = this.imageSrc;
    this.userServ.put(data).subscribe((retour: any) => {
      this.msg = retour;
      this.user.photo = this.imageSrc;
      this.store.dispatch(new AuthAction(USRLOGGEDIN, this.user));

    });


  }

  handleInputChange(e) {
    var file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
    var pattern = /image-*/;
    var reader = new FileReader();
    if (!file.type.match(pattern)) {
      alert('invalid format');
      return;
    }
    reader.onload = this._handleReaderLoaded.bind(this);
    reader.readAsDataURL(file);
  }
  _handleReaderLoaded(e) {
    this.disableButtValider = false
    this.form = this.fb.group({
      oldpassword: [''],
      password: ['', [Validators.minLength(4)]],
      confirmPassword: ['', [Validators.minLength(4)]],
      photo: ['']
    },
      { validator: this.MatchPassword });
    let reader = e.target;
    this.imageSrc = reader.result;

  }

  /*   onFileChanged(event) {
      const file = event.target.files[0]; 
    } */

  step = 0;

  setStep(index: number) {
    this.step = index;
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}
