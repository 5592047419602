import { Component, Input } from "@angular/core";

@Component({
    selector: 'note',
    templateUrl: './note.component.html',
  })
  export class Note {
    lib : String
    @Input('noteProp') data: Note;
    constructor (lib : String) {
       this.lib = lib;
    }
  }