import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class SuiviReclamationService {
  constructor(private gfxhttp: GfxHttpSrv) { }

  post(data) {
    return this.gfxhttp.post('suivireclamation', data);
  }
}
