import { Component, OnInit, Input } from '@angular/core';
import { IGfxOpResObj } from '../../../../core/models/IGfxOpResObj';
import { IUser } from '../../../../authentication/models/IUser';
import { Observable } from 'rxjs';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { IAuthObject } from '../../../../authentication/models/IAuthObject';
import { Router, Routes } from '@angular/router';
import { AuthService } from '../../../../authentication/services/AuthService';
import { GfxState, getUserState } from '../../../../store/gfxstate';
import { Store } from '@ngrx/store';
import { AppConfig } from '../../../../core/services/AppConfig';
import { GfxMiscSrv } from '../../../../helpers/gfx.misc.service';
import { RecuperationPwdComponent } from '../recuperation-pwd/recuperation-pwd.component';
import { UsersService } from '../../../../admin/services/users.service';
import { IRecuperationPwd } from '../../../../core/models/IRecuperationPwd';
import { appRefsSrv } from '../../../../../app/common/services/app.refs.service';


@Component({
    selector: 'residence-login',
    templateUrl: './residence-login.component.html',
    styleUrls: ['./residence-login.component.css']
})

export class ResidenceLoginComponent implements OnInit {

    userLogginIn: boolean;
    public authRes: IGfxOpResObj = null;
    public recRes: IRecuperationPwd = null;
    public userObs: Observable<IUser>;
    public appName: string;
    public appVersion: string;
    public appDescription: string;
    isForgetPwd
    loginValid
    user: any;

    @Input()
    set logginIn(isLogginIn: boolean) {
        this.userLogginIn = isLogginIn;
        if (isLogginIn) {
            this.identificationForm.disable();
        } else {
            this.identificationForm.enable();
        }

    }
    identificationForm: FormGroup = this.fb.group({
        utilisateur: ['', Validators.required],
        motDePasse: ['', Validators.required],
        email: ['']
    });



    logIn() {
        if (this.identificationForm.valid) {
            let authObj = <IAuthObject>this.identificationForm.value;
            this.authService.login(authObj);
        }
    }

    ngOnInit() {
        this.loginValid = false
        this.isForgetPwd = false
        this.userObs.subscribe(
            (usr: IUser) => {
                if (usr != null) {
                    this.user = JSON.parse(sessionStorage.getItem('curUser'))
                    if (this.user.firstaccess == 1)
                        this.goHome();
                    else
                        this.router.navigate(['changerPwd'], { skipLocationChange: true });
                }


            }
        )

        // window.onbeforeunload = function (e) {
        //     let curUser = JSON.parse(sessionStorage.getItem('curUser'));
        //     if (curUser) {
        //         this.store.dispatch(new AuthAction(USRLOGGEDIN, curUser));
        //     return true;
        //     }     
        //     localStorage.setItem('idProfile', localStorage.getItem("idProfile"));
        // }

    }


    goHome() {
        this.router.navigate(['home'], { skipLocationChange: true });
    }

    handlePwd() {
        this.loginValid = true;
    }

    credentialsChanged() {
        this.loginValid = true;
        if (this.authRes != null)
            this.authRes = null;
    }

    setForgetPwd() {
        this.authService.getRecupPwd(this.identificationForm.value.utilisateur).subscribe((data) => {
            localStorage.setItem("donneeRecupPwd", JSON.stringify(data));
            this.isForgetPwd = true;
            this.router.navigate(['recuperationPwd'], { skipLocationChange: true });
        });

    }


    //   saveCodeRecup() {

    //     console.log('price returned'); 
    //     const formData: FormData = new FormData();
    //     formData.append("mailUser", JSON.parse(localStorage.getItem("mailUser")));
    //     formData.append("loginUser", JSON.parse(localStorage.getItem("loginUser")));
    //     this.refsrv.saveCodeRecupPwd(FormData).subscribe((data) => {
    //         console.log(data)
    //     });
    //     localStorage.removeItem("loginUser")
    //     localStorage.removeItem("mailUser")
    // }
    constructor(public fb: FormBuilder,
        private router: Router,
        public utilSrv: GfxMiscSrv,
        private refsrv: appRefsSrv,
        private authService: AuthService,
        public store: Store<GfxState>,
        private appConfig: AppConfig) {
        this.userObs = store.select(getUserState);
        this.appName = appConfig.get("AppName");
        this.appDescription = appConfig.get("AppDescription");
        this.appVersion = appConfig.get("AppVersion");
        this.authService.authProcessed.subscribe((res: IGfxOpResObj) => {
            if (res.OpSuccess != 1) {
                this.authRes = res;
            }
        })

    }



}