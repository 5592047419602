import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class RefLieuxProbService {

  constructor(private gfxhttp: GfxHttpSrv) { }

  getLieuProb(id) {
      return this.gfxhttp.get('lieuprobleme/findOne', {idLieu:id});
  }

  post(data) {
      return this.gfxhttp.post('lieuprobleme', data);
  }

  put(data) {
      return this.gfxhttp.put('lieuprobleme', data);
  }

}