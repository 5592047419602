import { Injectable, NgModuleFactoryLoader } from "@angular/core";
import {
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { AppConfig } from "../core/services/AppConfig";
import { IUser } from "../authentication/models/IUser";
import { Store } from "@ngrx/store";
import { GfxState, getUserState } from "../store/gfxstate";
import { map, catchError } from "rxjs/operators";
import { GfxMiscSrv } from "./gfx.misc.service";
import { GfxHttpSrv } from "./GfxHttpSrv";
import { Router } from "@angular/router";
import { AuthService } from "../authentication/services/AuthService";

@Injectable()
export class GfxHttpInterceptor implements HttpInterceptor {
  constructor(private appCfg: AppConfig,private router: Router,
    private miscSrv: GfxMiscSrv,private readonly loader: NgModuleFactoryLoader,
    private gfxhttp: GfxHttpSrv,private authService: AuthService,
    private store: Store<GfxState>) { }
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let backendUrl = this.appCfg.get("BackendUrl");
    let capturedUrl = request.url;

    if (capturedUrl === "config/conf.json") return next.handle(request);

    if (capturedUrl.startsWith("http://")) return next.handle(request);
    //here we add gfx backend api url
    request = request.clone({ url: `${backendUrl}/${capturedUrl}` });
    if (capturedUrl === "auth/login" ) return next.handle(request);
    if (capturedUrl === "auth/saveCodeRecupPwd" ) return next.handle(request);
    if (capturedUrl === "auth/recuperationPwd" ) return next.handle(request);
    if (capturedUrl === "auth/saveResetPwd" ) return next.handle(request);
    //Add token to request heder
    let usrObs: Observable<IUser>;
    usrObs = this.store.select(getUserState);
    let currentUser: IUser;
    usrObs.subscribe(s => (currentUser = s));
    let changedRequest = request;
    if (currentUser != null && currentUser.token != "") {
      const headerSettings: { [name: string]: string | string[] } = {};

      for (const key of request.headers.keys()) {
        headerSettings[key] = request.headers.getAll(key);
      }

      headerSettings["GAuth"] = currentUser.token.toString();

      const newHeader = new HttpHeaders(headerSettings);

      changedRequest = request.clone({
        headers: newHeader
      });
      return next.handle(changedRequest).pipe(
        map((event: HttpEvent<any>) => {
          if (event instanceof HttpResponse) {

            if (event.body && event.body.msgError) {
              this.miscSrv.openSnackBarFromComp({ data: event.body.msgError, duration: 10000 });
            } else if (event.body && event.body.msgConfirm) {
              this.miscSrv.openSnackBarFromComp({ data: event.body.msgConfirm, duration: 10000 });
              this.gfxhttp.resend(changedRequest.url, changedRequest.method, changedRequest.body)

              //this.miscSrv.openSnackBarFromComp({data:event.body.msgError, duration: 3000});
            } else {
              return event;
            }
          } else {
            return event;
          }
        })
        ,
        catchError((error: HttpErrorResponse) => {
          if(error.status===403){
            this.authService.logout();
            return throwError(null);
          }
          return throwError(error);
        })
        );
    }
    //return next.handle(request);
  }
}
