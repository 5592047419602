import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ISelectData } from '../../../common/models/ISelectData';
import { GfxAppContentService } from '../../../../gfx/core/components/app.content/gfx.app.content.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { DeclarProbService } from '../../services/declar-prob.service';
import { FileUploader } from "ng2-file-upload";
import { Observable } from "rxjs";
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
let fileUpload = require('fuctbase64');
import * as moment from 'moment'
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { appRefsSrv } from '../../../common/services/app.refs.service';


@Component({
  selector: 'app-declar-prob',
  templateUrl: './declar-prob.component.html',
  styleUrls: ['./declar-prob.component.css']
})

export class DeclarProbComponent implements OnInit {

  uploadForm: FormGroup;

  public uploader: FileUploader = new FileUploader({
    isHTML5: true
  });
  title: string = 'Angular File Upload';
  private blobUrl: SafeUrl;
  private blobUrlList
  private fileList
  private extensionList
  reference
  idLoc
  wait
  msgFailedUpload
  isUploadFailed
  private incidentForm: FormGroup;
  private incident = {
    id: '', idLieu: '', idNature: '', date: moment(new Date()).format('YYYY-MM-DD'), files: '', commentaire: '', idAppartement: ''
  };
  private lieux: ISelectData;
  private natures: ISelectData;
  private locataires: ISelectData;
  private suivant = false;
  private appartement: any;
  private recap = {
    lieu: '', nature: '', date: '', files: '', commentaire: ''
  };
  file: File;
  private filesList
  private filesBase64

  private videoTag: HTMLVideoElement;
  private msgSucces
  private idProfil
  newDate
  selectedAppartem
  filteredOptions: any[];
  isLoading: boolean;
  pdfList
  public innerWidth: any;
  etapesStyle
  extension: string;

  constructor(private refSrv: appRefsSrv, private appContentService: GfxAppContentService,
    private fb: FormBuilder, private utilSrv: GfxMiscSrv, private declarProbSrv: DeclarProbService, private sanitizer: DomSanitizer) {

  }

  ngOnInit(): void {
    this.idProfil = sessionStorage.getItem('idProfile');
    this.etapesStyle = '';
    this.isUploadFailed = false
    this.innerWidth = window.innerWidth;
    if (this.innerWidth < 577) {
      this.etapesStyle = 'active';
    }

    this.wait = false
    this.idProfil = sessionStorage.getItem('idProfile');
    this.newDate = moment(new Date()).format('YYYY-MM-DD')

    this.msgSucces = false
    this.suivant = false;
    this.blobUrlList = []
    this.fileList = new Map();
    this.extensionList = new Map();
    this.filesList = []
    this.filesBase64 = []
    this.pdfList = []

    this.incidentForm = this.fb.group({
      idLieu: [this.incident.idLieu, Validators.required],
      idNature: [this.incident.idNature, Validators.required],
      date: [this.incident.date, Validators.required],
      files: [this.fileList],
      commentaire: [this.incident.commentaire],
      locataire: [""],

    });

    if (this.idProfil == 3) {
      this.incidentForm = this.fb.group({
        idLieu: [this.incident.idLieu, Validators.required],
        idNature: [this.incident.idNature, Validators.required],
        date: [this.incident.date, Validators.required],
        files: [this.fileList],
        commentaire: [this.incident.commentaire],
        locataire: ["", Validators.required],
      });
    }

    this.declarProbSrv.getLieuxProblemes().subscribe((data) => {
      this.lieux = data;
    });

    this.declarProbSrv.getNaturesProblemes().subscribe((data) => {
      this.natures = data;
    });

    this.declarProbSrv.getAppartementByLocataire().subscribe((data) => {
      this.appartement = data.appartCode;
      this.incident.idAppartement = data.idAppartement
    });

    this.declarProbSrv.getLocataires().subscribe((data) => {
      this.filteredOptions = data;
    });

    this.activateAutocomplete();
  }

  setDefaultUnite(locataire) {
    if (locataire && locataire.id) {
      this.incidentForm.get('locataire').setValue(locataire);
    }
  }

  displayFn(locataire) {

    return locataire.lib;
  }

  activateAutocomplete() {
    this.incidentForm.controls.locataire.valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredOptions = [];
          this.isLoading = true;
        }),
        switchMap(value => this.declarProbSrv.getLocataires(value)
          .pipe(
            finalize(() => {
              this.isLoading = false
            }),
          )
        )
      ).subscribe(data => {
        this.filteredOptions = data;
      });
  }

  Upload(event) {
    this.isUploadFailed = false
    this.file = event.target.files[0];
    var sizeInMB = Number((this.file.size / (1024 * 1024)).toFixed(2));

    if (sizeInMB <= 10) {
      this.extension = this.file.name.substr(this.file.name.length - 3)

      if (this.file && (this.extension != "jpg" && this.extension != "png" && this.extension != "mp4" && this.extension != "mov"
        && this.extension != "JPG" && this.extension != "PNG" && this.extension != "MP4" && this.extension != "MOV")) {
        this.isUploadFailed = true
        this.msgFailedUpload = "Seules les extensions jpg, png, mp4, mov sont autorisées"
      } else {
        this.isUploadFailed = false

        this.blobUrl = this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(this.file));
        this.fileList.set(this.file, this.blobUrl)
        this.extensionList.set(this.file, this.extension)
      }
    } else {
      this.isUploadFailed = true
      this.msgFailedUpload = 'Le fichier ne peut pas dépasser ' + 10 + 'MB'
    }
  }

  openPdf(file) {
    window.open(URL.createObjectURL(file));
  }

  getKeys(map) {
    return Array.from(map.keys());
  }
  getValues(map) {
    return Array.from(map.values());
  }

  remove(file) {
    this.fileList.delete(file)
    this.extensionList.delete(file)
  }
  suiv() {
    this.suivant = true;

    if (this.idProfil == 3) {
      this.declarProbSrv.getAppartementByIdLocataire(this.incidentForm.value.locataire.id).subscribe((data) => {
        this.selectedAppartem = data.code;
      });
    } else {
      this.selectedAppartem = this.appartement;
    }

    this.filesBase64 = []
    let files = Array.from(this.fileList.keys());
    files.forEach(element => {
      this.file = (element as File)

      var myReader: FileReader = new FileReader();

      myReader.onloadend = (e) => {
        this.filesBase64.push(myReader.result);
      }
      myReader.readAsDataURL(this.file);

    });

  }

  preced() {
    this.suivant = false;
  }

  saveAsRecap(controlName, value) {
    if (controlName == "nature") {
      this.declarProbSrv.getNatureById(value).subscribe((data) => {
        this.recap.nature = data.lib;
      });
    }
    if (controlName == "lieu") {
      this.declarProbSrv.getLieuById(value).subscribe((data) => {
        this.recap.lieu = data.lib;
      });
    }
    if (controlName == "commentaire") {
      this.recap.commentaire = value;
    }
    if (controlName == "date") {
      this.recap.date = value;
    }
    if (controlName == "locataire") {
      this.declarProbSrv.getAppartementByIdLocataire(value.id).subscribe((data) => {
        this.selectedAppartem = data.lib;
      });
    }
  }

  save() {
    this.wait = true

    if (this.incidentForm.value.id) {
      this.declarProbSrv.put(this.incidentForm.value).subscribe((data) => {
      })
    } else {
      let data = this.incidentForm.value;
      if (this.idProfil == 2)
        data.idAppartement = this.incident.idAppartement
      else data.idAppartement = ""

      if (this.idProfil == 3) {
        this.idLoc = data.locataire.id;
      } else {
        this.idLoc = 0
      }

      const formData: FormData = new FormData();

      formData.append("idAppartement", data.idAppartement);
      formData.append("idLieu", data.idLieu);
      formData.append("idNature", data.idNature);
      formData.append("idLocataire", this.idLoc);
      formData.append("commentaire", data.commentaire);
      formData.append("date", data.date);
      let files = Array.from(this.fileList.keys());

      files.forEach(element => {
        this.file = (element as File)
        formData.append('fileKey', this.file, this.file.name);
      })

      this.declarProbSrv.saveIncident(formData).subscribe((data) => {
        this.wait = false
        this.msgSucces = true;
        this.reference = data.reference;
      })
    }
  }

  return() {
    this.msgSucces = false;
    this.utilSrv.navigateTo("DeclarProbComponent");
  }

}