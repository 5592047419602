import {Action} from "@ngrx/store";
import { IUser } from "../../authentication/models/IUser";
export const USRLOGGEDIN = "[GFX] [USR] LoggedIn";
export const USRLOGGEDOUT = "[GFX] [USR] LoggedOut";

export class AuthAction implements Action {
    type : string;

    public getUser(){
        return this.payload;
    }

    constructor(_type : string ,private payload : IUser) {
       this.type = _type;
    }
}