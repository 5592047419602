import * as _ from 'underscore';
import { Injectable } from '@angular/core';

@Injectable()
export class GfxListPaginatorService {
    getPaginator(totalItems: number, currentPage: number, pageSize: number) {
        // nombre total des pages
        let totalPages = Math.ceil(totalItems / pageSize);

        let firstPage: number;
        let lastPage: number;
        if (totalPages <= 10) {
            // si le nombre de page et inferieur au 10 on affiche toutes les pages
            firstPage = 1;
            lastPage = totalPages;
        } else {
            // si non on calcule la page de debut et la page de fin
            if (currentPage <= 6) {
                firstPage = 1;
                lastPage = 10;
            } else if (currentPage + 4 >= totalPages) {
                firstPage = totalPages - 9;
                lastPage = totalPages;
            } else {
                firstPage = currentPage - 5;
                lastPage = currentPage + 4;
            }
        }

        // ici on calcul le 1er et le derniere element
        let startIndex = (currentPage - 1) * pageSize;
        let endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

        // le tableau de pages
        let pages = _.range(firstPage, lastPage + 1);

        
        return {
            totalItems: totalItems,
            currentPage: currentPage,
            pageSize: pageSize,
            totalPages: totalPages,
            firstPage: firstPage,
            lastPage: lastPage,
            startIndex: startIndex,
            endIndex: endIndex,
            pages: pages
        };
    }
}