import { Component, OnInit } from '@angular/core';
import { IGfxListActionComponent } from '../../../../gfx/core/components/gfxlist/IGfxListActionComponent';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ISelectData } from '../../../common/models/ISelectData';
import { GfxAppContentService } from '../../../../gfx/core/components/app.content/gfx.app.content.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { RefAppartementService } from '../../services/ref-appartement.service';
import * as XLSX from 'ts-xlsx';

@Component({
  selector: 'app-ref-appartement-form',
  templateUrl: './ref-appartement-form.component.html',
  styleUrls: ['./ref-appartement-form.component.css']
})
export class RefAppartementFormComponent implements OnInit, IGfxListActionComponent {
  SelectedItem;
  id;
  private appartementForm: FormGroup;
  private appartement = {
    id: '', code: '', lib: '', adresse: ''
  };
  private profiles: ISelectData;
  msgSucces: boolean;
  msgErr: boolean;
  msg: any;
  file: File;
  fileName: string;
  arrayBuffer: any;
  private appartements = [];
  fileSelected: boolean;
  appFromExcel: any;
  savedFromExcel: boolean;
  updateMode: boolean;

  constructor(private appContentService: GfxAppContentService,
    private fb: FormBuilder, private refappartemsrv: RefAppartementService, private utilSrv: GfxMiscSrv) {

  }

  ngOnInit(): void {
    this.msgSucces = false
    this.msgErr = false;
    this.fileName = "Sélectionner un fichier";
    this.fileSelected = false
    this.appFromExcel = ""
    this.savedFromExcel = false

    this.appartementForm = this.fb.group({
      code: [this.appartement.code, Validators.required],
      libelle: [this.appartement.lib],
      adresse: [this.appartement.adresse],
    });



    if (this.SelectedItem && this.SelectedItem.id) {
      this.updateMode = true
      this.refappartemsrv.getAppartement(this.SelectedItem.id).subscribe((data) => {
        this.appartement = data
        this.appartementForm = this.fb.group({
          id: [this.appartement.id],
          code: [this.appartement.code, Validators.required],
          libelle: [this.appartement.code],
          adresse: [this.appartement.adresse],
        });
      })

    }
  }


  Upload(event) {

    this.file = event.target.files[0];
    this.fileName = this.file.name;

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;

      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary", cellDates: true });
      var first_sheet_name1 = workbook.SheetNames[0];
      var worksheet1 = workbook.Sheets[first_sheet_name1];

      // sheet appartements
      this.appartements = XLSX.utils.sheet_to_json(worksheet1, { raw: true });
    }
    fileReader.readAsArrayBuffer(this.file);

    this.appartementForm = this.fb.group({
      code: [''],
      libelle: [''],
      adresse: [''],
    });
    this.fileSelected = true
  }

  return() {
    localStorage.removeItem("idAppartement");
    this.utilSrv.navigateTo("RefAppartemListComponent");
  }

  save() {
    if (this.appartementForm.value.id) {
      this.refappartemsrv.put(this.appartementForm.value).subscribe((data) => {
        this.return();
      })
    } else if (this.appFromExcel != "") {
      this.refappartemsrv.saveFromExcel(this.appartements).subscribe((data) => {
        if (data.retour && data.retour == 1) {
          this.msgSucces = true;
          this.msgErr = false;
          this.savedFromExcel = true
        }
        else {
          this.msgErr = true;
          this.msg = data.msg
        }
      })
    } else {
      this.refappartemsrv.post(this.appartementForm.value).subscribe((data) => {
        if (data && data.retour == 1) {
          this.msgSucces = true;
          this.msgErr = false;
        }
        else {
          this.msgErr = true;
          this.msg = data.msg
        }
        // this.return();
      })
    }
  }

}