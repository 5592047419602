import { OnInit, Component, ViewChild, ComponentFactoryResolver, Input, Type } from "@angular/core";
import { AppContentDirective } from "../../directives/app.content.host.directive";
import { MatSnackBar } from "@angular/material";
import { AppConfig } from "../../services/AppConfig";
import { GfxAppContentService } from "./gfx.app.content.service";
import { GfxChangeContentEventData } from "./gfx.change.content.event.data";
import { GfxDictionary } from "../../models/GfxDictionary";

@Component({
  selector: 'gfx-app-content',
  template: '<ng-template style="width:100%" app-content-host></ng-template>'
})
export class GfxAppContentComponent implements OnInit {
  private componentName: String;
  private appName: string;
  @Input()
  set ComponentName(cName: String) {
    this.componentName = cName;
    this.loadAppContent(this.componentName, null);
  }
  @ViewChild(AppContentDirective) appContentHost: AppContentDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver,
    public snackBar: MatSnackBar,
    private appConfig: AppConfig,
    private appContentService: GfxAppContentService) {
    this.appName = appConfig.get("AppName");
    this.appContentService.changeContent.subscribe((eventData: GfxChangeContentEventData) => {
      this.loadAppContent(eventData.ComponentName, eventData.Data);

    })

  }
  loadAppContent(cName: String, data: GfxDictionary<any>) {

    if (!cName)
      return;
    const factories = Array.from(this.componentFactoryResolver['_factories'].keys());
    var appDynComponentFactory = <Type<any>>factories.find((x: any) => x.name === cName);
    if (appDynComponentFactory == null || appDynComponentFactory == undefined) {
      setTimeout(() => {
        this.snackBar.open(this.appName + " : le composant " + cName + " n'est pas enregistré ou n'existe pas", "OK", {
          duration: 3000,
        });
      });
      return;
    }
    const appDynComponentFactoryClass = this.componentFactoryResolver.resolveComponentFactory(appDynComponentFactory);
    var viewContainerRef = this.appContentHost.viewContainerRef;
    viewContainerRef.clear();

    let componentRef = viewContainerRef.createComponent(appDynComponentFactoryClass);
    //Adding data 
    if (data) {
      for (let k in data) {
        let value = data[k];
        componentRef.instance[k] = value;

      }
    }

  }

  ngOnInit(): void {
  }
}