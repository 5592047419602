import { Component, OnInit, Input } from '@angular/core';
import { WorkflowService } from '../Services/workflow.service';
import { GfxGedService } from '../../core/components/gfxged/gfx.ged.service';
import * as FileSaver from "file-saver";
import { IGfxFile } from '../../core/components/gfxged/IGfxFile';
import { GfxUpDownHelper } from '../../helpers/GfxUpDownHelper';
import { AppConfig } from '../../core/services/AppConfig';

@Component({
  selector: 'wf-inst-docs',
  templateUrl: './wf-inst-docs.component.html',
  styleUrls: ['./wf-inst-docs.component.css']
})
export class WfInstDocsComponent implements OnInit {

  @Input("config") config;
  docs: any;
  constructor(private wfSrv: WorkflowService, private upDownHelper: GfxUpDownHelper,
    private appConfig: AppConfig) {

  }

  ngOnInit() {

    this.wfSrv.getInstDocs(this.config.inst, this.config.idTbl).subscribe((data) => {
      this.docs = data;
    });
  }

  downloadFile(doc) {
    const url = this.appConfig.get("GedUrl") + '/' + doc.dossier + '/' + doc.discName;
    console.log('url: ', url);
    
    this.upDownHelper.download(url).subscribe((fileData: Blob) => {
      FileSaver.saveAs(fileData, doc.nom);
    });
  }

}
