import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ISelectData } from '../../../common/models/ISelectData';
import { GfxAppContentService } from '../../../../gfx/core/components/app.content/gfx.app.content.service';
import { RefLieuxProbService } from '../../services/ref-lieux-prob.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { IGfxListActionComponent } from '../../../../gfx/core/components/gfxlist/IGfxListActionComponent';

@Component({
  selector: 'app-ref-lieu-prob-form',
  templateUrl: './ref-lieu-prob-form.component.html',
  styleUrls: ['./ref-lieu-prob-form.component.css']
})
export class RefLieuProbFormComponent implements OnInit , IGfxListActionComponent {
  SelectedItem;
  id;
  private lieuProbForm: FormGroup;
  private lieu = {
    id: '', lib: ''
  };

  constructor(private appContentService: GfxAppContentService,
    private fb: FormBuilder, private srvLieuProb: RefLieuxProbService, private utilSrv: GfxMiscSrv) {

  }

  ngOnInit(): void {
    this.lieuProbForm = this.fb.group({
      libelle: [this.lieu.lib, Validators.required],
    });


    if (this.SelectedItem && this.SelectedItem.id ) {
      this.srvLieuProb.getLieuProb(this.SelectedItem.id).subscribe((data) => {
        this.lieu = data
        this.lieuProbForm = this.fb.group({
          id: [this.lieu.id],
          libelle: [this.lieu.lib, Validators.required],
        });
      })
     
    }
  }

  return() {
    this.utilSrv.navigateTo("RefLieuxProbComponentList");
  }

  save() {
    if (this.lieuProbForm.value.id) {
      this.srvLieuProb.put(this.lieuProbForm.value).subscribe((data) => {
        this.return();
      })
    } else {
      this.srvLieuProb.post(this.lieuProbForm.value).subscribe((data) => {
        this.return();
      })
    }
  }

}