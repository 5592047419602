import { PipeTransform, Pipe } from "@angular/core";
@Pipe({
    name : "gfxTypeGetter"
})
export class GfxTypeGetterPipe implements PipeTransform {
    transform(value: any, ...args: any[]) {
        if(typeof value === 'object'){
            return 'object'
        } else if(typeof value === 'string') {
            return 'string'
        }
    }

}