import { Component, OnInit } from '@angular/core';
import { ResidenceNotifService } from './residence-notif.service';
import { appRefsSrv } from '../../../../../app/common/services/app.refs.service';

@Component({
  selector: 'app-residence-notif',
  templateUrl: './residence-notif.component.html',
  styleUrls: ['./residence-notif.component.css']
})
export class ResidenceNotifComponent implements OnInit {
  notification: any;

  constructor(private notifSrv: ResidenceNotifService, private refsrv: appRefsSrv) { }

  ngOnInit() {

    if (localStorage.getItem('notif'))
      this.notification = JSON.parse(localStorage.getItem('notif'));

    this.notifSrv.marqueLu(this.notification.idNotif).subscribe(data => {
    });

  }

}
