import { Injectable, EventEmitter } from "@angular/core";
import { GfxHttpSrv } from "../../helpers/GfxHttpSrv";
import { Store } from "@ngrx/store";
import { GfxState } from "../../store/gfxstate";
import { IGfxOpResObj } from "../../core/models/IGfxOpResObj";

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { tap } from "rxjs/operators";

let headers = new HttpHeaders({
    'Content-Type': 'application/json'
});
let options = {
    headers: headers
}

@Injectable()
export class UsersService {
    constructor(private http: GfxHttpSrv, private store: Store<GfxState>, ) {
    }

    recupProcessed: EventEmitter<IGfxOpResObj> = new EventEmitter<IGfxOpResObj>()

    get(idUser) {
        return this.http.get('user', { id: idUser });
    }

    post(data) {
        return this.http.post('user', data);
    }

    put(data) {
        return this.http.put('user', data);
    }

    delete(id) {
        return this.http.delete('user', id);
    }

    getProfilesSec(idUser) {
        return this.http.get('user/profilSecondaire', { idUser: idUser });
    }

    saveProfilesSec(data) {
        return this.http.post('user/saveProfilSecondaire', data);
    }



}