import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ColtureReclamService } from '../../../../../app/reclam/services/colture-reclam.service';
import { AuthService } from '../../../../authentication/services/AuthService';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { GfxState, getUserState } from '../../../../store/gfxstate';
import { IUser } from '../../../../authentication/models/IUser';
import { AuthAction, USRLOGGEDIN } from '../../../../store/actions/auth';
import { IAuthObject } from '../../../../authentication/models/IAuthObject';

@Component({
  selector: 'app-changer-pwd-first-access',
  templateUrl: './changer-pwd-first-access.component.html',
  styleUrls: ['./changer-pwd-first-access.component.css']
})
export class ChangerPwdFirstAccessComponent implements OnInit {
  isForgetPwd
  identificationForm: FormGroup
  emailPsd
  err: boolean;
  msg: any;
  usr: any;
  user: any;
  emailEnvoye: boolean;
  codeRecu: any;
  codeSaisi: string;
  codeValid: boolean;
  errCode: boolean;
  pwd: String;
  confirmPwd: String


  constructor(public fb: FormBuilder, private authService: AuthService, private router: Router, private store: Store<GfxState>) {
    let usrObs: Observable<IUser>;
    this.store.select(getUserState).subscribe((user) => {
      this.usr = user;
    });
  }

  ngOnInit() {
    this.err = false;
    this.pwd = ''
    this.confirmPwd = ''

    this.user = JSON.parse(sessionStorage.getItem("curUser"));

  }

  resetPwd() {
    this.authService.saveResetPwd(this.user.login, this.pwd).subscribe((res) => {
      if (res.retour == 1) {

        let authObj = <IAuthObject>{utilisateur : this.user.login, motDePasse : this.pwd};
        this.authService.login(authObj);

      } else {
        this.err = true;
        this.msg = res.msg;
      }
    })
  }

  return() {
    this.authService.logout();
  }

}
