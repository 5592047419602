import { IGfxListFilterElem } from "./IGfxListFilterElem";
import { Output, EventEmitter, Injector } from "@angular/core";
import * as _ from 'underscore';

export class GfxListFilterComponent {
    Filters : IGfxListFilterElem[];
    InjectorInst : Injector;
    @Output() 
    RefreshEvent : EventEmitter<IGfxListFilterElem[]> = new EventEmitter<IGfxListFilterElem[]>();
    RefreshList(){
        this.RefreshEvent.emit(this.Filters);
    }

    constructor(private injector: Injector, private param?: any){
        this.InjectorInst = this.injector;
    };

    SetElementValue(elementName:string, value:any){
        let elemIndex = _.indexOf(_.pluck(this.Filters, "Name"), elementName);
        this.Filters[elemIndex].Value  = value;
    }
}