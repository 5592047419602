import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class MesSignalementsService {

  constructor(private gfxhttp: GfxHttpSrv) { }

  getMesSignalements(limit, offset?) {
    return this.gfxhttp.get('incident/messignalements', {limit: limit, offset: offset ? offset : 0});
  }

  getSignalementsntsWithFilter(data) {
    return this.gfxhttp.get('incident/signalementsfiltred', data);
  }

  getItems(config) {
    return this.gfxhttp.get('incident/all', config);
  }

  getNaturesProblemes() {
    return this.gfxhttp.get('natureprobleme/all');
}
}
