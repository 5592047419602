import { Action } from "@ngrx/store";
import { IGfxNotification } from "../../core/components/gfxnotifications/IGfxNotification";
export const UPDATENOTIFDATA = "[GFX] [NOTIFICATIONS] UPDATE DATA";
export class GfxNotificationAction implements Action {
    type: string;

    getData() {
        return this.payload;
    }

    constructor(_type : string , private payload : IGfxNotification[]){
        this.type = _type;
    }

}