import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';
import { from } from 'rxjs';
import { map, filter, catchError, mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable()
export class ColtureReclamService {

  
  constructor(private gfxhttp: GfxHttpSrv) { }

  post(data) {
    return this.gfxhttp.post('incident/cloturer', data);
  }
 
}
