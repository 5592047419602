import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { GfxAppContentService } from '../app.content/gfx.app.content.service';

@Component({
  selector: 'app-gfx-modal-util',
  templateUrl: './gfx-modal-util.component.html',
  styleUrls: ['./gfx-modal-util.component.css']
})
export class GfxModalUtilComponent implements OnInit {
  //componentName;

  constructor(@Inject(MAT_DIALOG_DATA) private inputData: any, private appContentService: GfxAppContentService,) {
    //this.componentName = this.inputData.viewComp;
    this.appContentService.changeContent.emit({ ComponentName: this.inputData.viewComp, Data: {} });
   }

  ngOnInit() {
    
  }

}
