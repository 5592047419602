import { Injectable } from "@angular/core";
import { IGfxListFilter } from "./IGfxListFilter";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Store } from "@ngrx/store";
import { GfxState } from "../../../store/gfxstate";
import { GfxListAction, UPDATEDATA } from "../../../store/actions/gfxlist";
import { IGfxListData } from "./IGfxListData";
import { IGfxListFilterElem } from "./IGfxListFilterElem";

@Injectable()
export class GfxListService {
    constructor(private http: HttpClient, private store: Store<GfxState>) {

    }
    getData(listCode: string, dataUrl: string, filter: IGfxListFilter,
        offset: Number, limit: Number, orderBy: string, orderDir: number) {
        this.getDataObservable(listCode, dataUrl, filter ? filter.FilterElements: {}, offset, limit, orderBy, orderDir)
            .subscribe((result: any) => {
                let data: IGfxListData = {
                    Code: listCode,
                    Data: result.items,
                    TotalItems: result.size
                }
                this.store.dispatch(new GfxListAction(UPDATEDATA, data));
            });
    }

    getDataObservable(listCode: string, dataUrl: string, filter: any,
        offset: Number, limit: Number, orderBy: string, orderDir: number) {
        let tmpFlt = filter.length ? filter.filter(el => el.Value) : "";
        let params = new HttpParams();
        if (offset)
            params = params.set("offset", String(offset));
        if (limit)
            params = params .set("limit", String(limit));
        if (filter.length > 0) {
            let filterStr = JSON.stringify(tmpFlt);
            params = params.set("filter", filterStr);
        }
        params = params.set("orderBy", orderBy);
        params =params.set("orderDir",  String(orderDir));
             
        return this.http.get<any>(dataUrl, { params });
    }
}
