import { NgModule, Component } from "@angular/core";
import { AppConfig } from "../../../../core/services/AppConfig";



@Component({
    selector: 'gfx-apropos',
    templateUrl: './gfx.apropos.component.html',
  
})
export class GfxAproposComponent{
    public appName: string;
    public appVersion: string;
    public appDescription: string;
    public appVersionDate: string;
    constructor(private appConfig: AppConfig) {
        this.appName = appConfig.get("AppName");
        this.appDescription = appConfig.get("AppDescription");
        this.appVersion = appConfig.get("AppVersion");
        this.appVersionDate = appConfig.get("AppVersionDate");
    }

};