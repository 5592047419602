import { Component, OnInit } from "@angular/core";
import { GfxNotificationsService } from "./gfx.notifications.service";
import { Observable } from "rxjs";
import { IGfxNotification } from "./IGfxNotification";
import { GfxState, getGfxNotificationsState } from "../../../store/gfxstate";
import { Store } from "@ngrx/store";
import { GfxTypeNotificationEnum } from "./IGfxTypeNotification";
import { AppConfig } from "../../services/AppConfig";
@Component({
    selector: "gfx-notifications",
    templateUrl: "./gfx.notifications.component.html",
    styleUrls: ["./gfx.notifications.component.css"]


})
export class GfxNotificationsComponent implements OnInit {
    public title: string = "Welcome to gfx notifications component";
    notifObs: Observable<IGfxNotification[]>;
    public currentNotifications: IGfxNotification[] = [];
    nbrOfNotifications: number = 0;
    constructor(private state: Store<GfxState>,
        private appConfig: AppConfig,
        private notifService: GfxNotificationsService) {
        
    }
    ngOnInit(): void {

        this.notifObs = this.state.select(getGfxNotificationsState);
        this.notifObs.subscribe((notifs) => {
            this.currentNotifications = notifs;
            if (notifs && Array.isArray(notifs))
                this.nbrOfNotifications = notifs.length;
        });

    }

    getNotifIcon(notif: IGfxNotification) {
        return this.notifService.getNotifIcon(notif);
    }
    getColor(notif: IGfxNotification) {
        return this.notifService.getColor(notif);
    }

    dismissNotif(notif: IGfxNotification) {
        
        if (notif && !notif.Dismissed) {
            this.notifService.dismissNotification(notif).subscribe((res) => {
                this.notifService.getNotifications();
            });
        }
    }


}