import { NgModule } from "@angular/core";
import { DefaultHomeComponent } from "./default/containers/default.home.component";
import { DefaultLoginComponent } from "./default/containers/default.login.component";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { GfxCoreMaterialModule } from "../core/gfx.core.material.module";
import { GfxAppContentComponent } from "../core/components/app.content/gfx.app.content.component";
import { AppContentDirective } from "../core/directives/app.content.host.directive";
import { OtherThemeComponent } from './other-theme/other-theme.component';
import { OtherLoginThemeComponent } from './other-login-theme/other-login-theme.component';
import { ResidenceHomeComponent } from "./residence_theme/containers/residence-home/residence-home.component";
import { ResidenceLoginComponent } from "./residence_theme/containers/residence-login/residence-login.component";
import { ResidencePreConnecxionComponent } from './residence_theme/containers/residence-pre-connecxion/residence-pre-connecxion.component';
import { RecuperationPwdComponent } from './residence_theme/containers/recuperation-pwd/recuperation-pwd.component';
import { SubMenuInfoComponent } from './residence_theme/containers/sub-menu-info/sub-menu-info.component';
import { SubMenuInfoService } from "./residence_theme/containers/sub-menu-info/sub-menu-info.service";
import { ResidenceNotifComponent } from './residence_theme/containers/residence-notif/residence-notif.component';
import { ResidenceNotifService } from "./residence_theme/containers/residence-notif/residence-notif.service";
import { ChangerPwdFirstAccessComponent } from './residence_theme/containers/changer-pwd-first-access/changer-pwd-first-access.component';


@NgModule({
    imports: [BrowserAnimationsModule, CommonModule, NgbModule.forRoot(), ReactiveFormsModule, GfxCoreMaterialModule, FormsModule],
    declarations: [DefaultHomeComponent, DefaultLoginComponent, ResidenceHomeComponent, ResidenceLoginComponent, GfxAppContentComponent,
        AppContentDirective,
        OtherThemeComponent,
        OtherLoginThemeComponent,
        ResidenceHomeComponent,
        RecuperationPwdComponent,
        ResidenceNotifComponent,
        ChangerPwdFirstAccessComponent],

    exports: [DefaultHomeComponent, ResidenceHomeComponent, DefaultLoginComponent, ResidenceHomeComponent, ResidenceHomeComponent, ResidenceLoginComponent, RecuperationPwdComponent, ChangerPwdFirstAccessComponent, OtherThemeComponent, OtherLoginThemeComponent],
    providers: [SubMenuInfoService, ResidenceNotifService],
    entryComponents: [
        ResidenceNotifComponent
    ]
})
export class GfxThemeModule {

}