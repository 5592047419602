import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Note } from '../components/note.component';
import { NoteList } from '../components/note.list.component';

@Injectable()
export class NoteService {
    constructor(private http: HttpClient) { }

    //We get the notes and return observable
    getNotes() {
        let url = 'common/noteRs/notesList';
        return this.http.get(url);
    }

    postNote(lib) {
        let url = 'common/noteRs/add';
        return this.http.post(url, {lib:lib});
    }

    updateNote(note) {
        let url = 'common/noteRs/edit';
        return this.http.post(url, {id:note.id,lib:note.lib});
    }

    deleteNote(note) {
        let url = 'common/noteRs/delete';
        return this.http.post(url, {id:note.id});
    }
}