import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class RefLocataireService {

  constructor(private gfxhttp: GfxHttpSrv) { }

  getLocataire(id) {
      return this.gfxhttp.get('locataire/findOne', {idLoc:id});
  }

  post(data) {
      return this.gfxhttp.post('locataire', data);
  }
  
  saveFromExcel(data) {
    return this.gfxhttp.post('locataire/saveFromExcel', data);
}

  put(data) {
      return this.gfxhttp.put('locataire', data);
  }

}
