import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Injectable } from "@angular/core";
import { Store, State } from "@ngrx/store";
import { GfxState, getUserState } from "../../store/gfxstate";
import { Observable } from "rxjs";
import { IUser } from "../../authentication/models/IUser";
import { AuthAction, USRLOGGEDIN } from "../../store/actions/auth";

@Injectable()
export class AppGuard implements CanActivate {
    private authObs: Observable<IUser>;
    private allow: boolean;
    constructor(private router: Router, private store: Store<GfxState>) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        let usrObs: Observable<IUser>;

        usrObs = this.store.select(getUserState);
        let currentUser: IUser;
        let sub = usrObs.subscribe(s => currentUser = s);
        if (currentUser) {
            return true;
        } else {
            let curUser = JSON.parse(sessionStorage.getItem('curUser'));
            if (curUser) {
                this.store.dispatch(new AuthAction(USRLOGGEDIN, curUser));
            return true;
            }     
        }
        
        sub.unsubscribe();

        // si utilisateur n'est pas connecté
        this.router.navigate(['login'], { skipLocationChange: true });
        return false;

    }
}