import { ErrorHandler, Injectable, NgZone } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { MatSnackBar, MatDialog } from "@angular/material";
import { AppConfig } from "../../core/services/AppConfig";
import { GfxErrorDialogComponent } from "../components/gfx.error.dialog.component";
import { copyStyles } from "@angular/animations/browser/src/util";
@Injectable()
export class GfxErrorHandler implements ErrorHandler {
    constructor(private appConfig: AppConfig,
        public dialog: MatDialog,
        private zone: NgZone) {

    }
    handleError(error: Error): void {

        switch (error.constructor) {
            case HttpErrorResponse:
                this.zone.run(() => this.handleHttpError(error));
                break;
            default:
                this.zone.run(() => this.handleGenericError(error));;
                break;
        }


    }

    handleHttpError(error: any) {
        let backendError: boolean = true;
        let showDetail: boolean = true;
        let dialogWidth: string = "600px";
        let msg: string = "Problème de connexion au serveur.";
        switch (error.status) {
            case 0:
                msg = "Problème de connexion au serveur.";
                showDetail = false;
                break;
            case 500:
                msg = "Erreur interne du serveur.";
                showDetail = true;
                dialogWidth = "700px";
                break;
            case 503:
                msg = "Problème de connexion au serveur.";
                showDetail = false;
                break;
            case 403:
                msg = "Vous n'êtes pas autorisé à accéder à cette fonctionnalité.";
                showDetail = false;
            default:
                msg = "Une erreur s'est produite.";
                showDetail = false;
        }
        this.dialog.open(GfxErrorDialogComponent, {
            data: {

                Title: this.appConfig.get("AppName"),
                BackendError: backendError,
                ShowDetail: showDetail,
                Error: {
                    Msg: msg,
                    Detail: error.error
                },
                closeDialogErrorFn: () => { }
            },
            width: dialogWidth

        })
    }

    handleGenericError(error: Error) {
        let dialogRef = this.dialog.open(GfxErrorDialogComponent, {
            data: {

                Title: this.appConfig.get("AppName"),
                BackendError: false,
                Error: {
                    Msg: error.message
                }
            },
            width: "600px"
        });

    }

}