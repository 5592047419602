import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class TbdService {
  constructor(private gfxhttp: GfxHttpSrv) { }

  getNouvDemandes() {
    return this.gfxhttp.get('incident/demandesnouv');
  }

  getStatisAnnuel() {
    return this.gfxhttp.get('incident/statistiquesAnnuelles');
  }

  getRecapAnnuel() {
    return this.gfxhttp.get('incident/recaputilatifProblemAnnuel');
  }

  getEncoursDemandes() {
    return this.gfxhttp.get('incident/demandesencours');
  }

  getResoluDemandes() {
    return this.gfxhttp.get('incident/demandesresolu');
  }

  getItems(config) {
    return this.gfxhttp.get('incident/all', config);
  }

  getNaturesProblemes() {
    return this.gfxhttp.get('natureprobleme/all');
}
}
