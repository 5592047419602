import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MenuItem } from "../../core/models/MenuItem";
import { GfxHttpSrv } from "../../helpers/GfxHttpSrv";

@Injectable()
export class ProfileService {
    constructor(private http: GfxHttpSrv){

    }

    getProfiles() {

    }

    get(idProfile) {
        return this.http.get('usersprofile', {id: idProfile});
    }
    getDroits(idProfile) {
        return this.http.get('droits', {id: idProfile});
    }

    getReferentiels(idProfile) {
        return this.http.get('referentiels', {id: idProfile});
    }

    put(data) {
        let url = 'usersprofile';
        return this.http.put(url, data);
    }

    post(data) {
        let url = 'usersprofile';
        return this.http.post(url, data)
    }
    saveDroitsMenu(data) {
        let url = 'droits';
        return this.http.post(url, data)
    }
    saveDroitsRefs(data) {
        let url = 'referentiels';
        return this.http.post(url, data)
    }

    putProfile(data) {
        let url = 'profiles';
        return this.http.put(url, data);
    }

    postProfile(data) {
        let url = 'profiles';
        return this.http.post(url, data)
    }

    deleteProfile(idprofile) {
        let url = 'profiles/delete';
        return this.http.post(url, {idprofile : idprofile})
    }
    
    getPermitedMenus (idprofile) {
        let url = 'profiles/gestDroits?idprofile='+idprofile;
        return this.http.get(url);
    }
    
    postPermitedMenus(idprofile,data) {
        let url = "profiles/modificationDroits?idprofile="+idprofile;
        return this.http.post(url, data);
    }
}