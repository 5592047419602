export enum GfxGedFileTypeEnum {
    WORD = 1,
    Excel = 2,
    PDF = 3,
    PowerPoint = 4,
    Text = 5,
    PNG = 6,
    JPG = 7,
    Other = 8
}


