import { Component, OnInit } from '@angular/core';
import { IGfxListActionComponent } from '../../core/components/gfxlist/IGfxListActionComponent';
import { GfxMiscSrv } from '../../../app/../gfx/helpers/gfx.misc.service';
import { FormBuilder } from '@angular/forms';
import { WorkflowService } from '../Services/workflow.service';


@Component({
  selector: 'app-wf-instances',
  templateUrl: './wf-instances.component.html',
  styleUrls: ['./wf-instances.component.css']
})
export class WfInstancesComponent implements OnInit, IGfxListActionComponent {
  SelectedItem;
  SourceComponentName: string;

  private wfList : any;
  codes: any;

  constructor(private utilSrv: GfxMiscSrv, private fb: FormBuilder, 
    private wfSrv: WorkflowService) { }

  ngOnInit(): void {
    let idProfile = sessionStorage.getItem('idProfile');
    this.wfSrv.getWfList(idProfile).subscribe((data) => {
      this.wfList = data;
    });

  }

  getListInstance(idWf, idState){
    localStorage.setItem("idWfTrans", JSON.stringify(idWf));
    localStorage.setItem("ididStateTrans", JSON.stringify(idState));
    this.utilSrv.navigateTo("WfInstancesListComponent", {idWf: idWf, idState: idState});

}

}
