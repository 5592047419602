import { Action } from "@ngrx/store";
import { IGfxRef } from "../../ref/IGfxRef";
export const UPDATEREF: string = "[GFX] [REF] UPDATE REF";

export class GfxRefAction implements Action {
    type: string;

    getRef() {
        return this.payload;
    }

    constructor(_type: string, private payload: IGfxRef) {
        this.type = _type;
    }


}