import { Component, OnInit, Output, EventEmitter, ViewEncapsulation, Input } from '@angular/core';
import { Note } from './note.component';
import { NoteService } from '../services/note.service';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'note-form',
  templateUrl: './note.form.component.html',
  encapsulation: ViewEncapsulation.Emulated !
})
export class NoteForm implements OnInit {
  @Output() noteCreated = new EventEmitter<Note>();
  @Output() noteUpdated = new EventEmitter<Note>();
  @Input() note : Note;
  @Input() editMode : boolean;

  noteForm: FormGroup
  createNote(lib : String){
    let obs = this.ns.postNote(lib);
    obs.subscribe((retour : any) => {
        this.noteCreated.emit(new Note(lib));
    });
    this.noteForm.reset();
  }
  updateNote(note : Note){
    let obs = this.ns.updateNote(note);
    obs.subscribe((retour : any) => {
        this.noteUpdated.emit(new Note(note.lib));
    });
    this.noteForm.reset();
  }
  
  constructor(private ns : NoteService) {
    this.noteForm = new FormGroup({
    lib: new FormControl()
  });
  }

  ngOnInit() {
    this.note = new Note("");

  }

}
