import { Component, OnInit, Input, Output, EventEmitter, HostBinding } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { trigger, state, animate, transition, style } from '@angular/animations';
import { IAuthObject } from "../../../authentication/models/IAuthObject";
import { AuthService } from "../../../authentication/services/AuthService";
import { Router } from "@angular/router";
import { IUser } from "../../../authentication/models/IUser";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { GfxState, getUserState } from "../../../store/gfxstate";
import { AppConfig } from "../../../core/services/AppConfig";
import { IGfxOpResObj } from "../../../core/models/IGfxOpResObj";


@Component({
    selector: "default-login-container",
    templateUrl: "./default.login.component.html"
})

export class DefaultLoginComponent implements OnInit {



    userLogginIn: boolean;
    public authRes: IGfxOpResObj = null;
    public userObs: Observable<IUser>;
    public appName: string;
    public appVersion: string;
    public appDescription: string;

    @Input()
    set logginIn(isLogginIn: boolean) {
        this.userLogginIn = isLogginIn;
        if (isLogginIn) {
            this.identificationForm.disable();
        } else {
            this.identificationForm.enable();
        }

    }
    identificationForm: FormGroup = this.fb.group({
        utilisateur: ['', Validators.required],
        motDePasse: ['', Validators.required]
    });

    logIn() {
        if (this.identificationForm.valid) {
            let authObj = <IAuthObject>this.identificationForm.value;
            this.authService.login(authObj);

        }
    }

    ngOnInit() {
        this.userObs.subscribe(
            (usr: IUser) => {
                if (usr != null)
                    this.goHome();
            }
        )
    }


    goHome() {
        this.router.navigate(['home'], { skipLocationChange: true });

    }
    credentialsChanged() {
        if (this.authRes != null)
            this.authRes = null;
    }
    constructor(public fb: FormBuilder,
        private router: Router,
        private authService: AuthService,
        public store: Store<GfxState>,
        private appConfig: AppConfig) {
        this.userObs = store.select(getUserState);
        this.appName = appConfig.get("AppName");
        this.appDescription = appConfig.get("AppDescription");
        this.appVersion = appConfig.get("AppVersion");
        this.authService.authProcessed.subscribe((res: IGfxOpResObj) => {
            if (res.OpSuccess != 1) {
                this.authRes = res;
            }
        })
    }

}