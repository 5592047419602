import { Component, OnInit } from '@angular/core';
import { ISelectData } from '../../../common/models/ISelectData';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GfxAppContentService } from '../../../../gfx/core/components/app.content/gfx.app.content.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { RefLocataireService } from '../../services/ref-locataire.service';
import { appRefsSrv } from '../../../common/services/app.refs.service';
import { UsersService } from '../../../../gfx/admin/services/users.service';
import { IGfxListActionComponent } from '../../../../gfx/core/components/gfxlist/IGfxListActionComponent';
import * as XLSX from 'ts-xlsx';
import { RefAppartementService } from '../../services/ref-appartement.service';

@Component({
  selector: 'app-locataire-form',
  templateUrl: './locataire-form.component.html',
  styleUrls: ['./locataire-form.component.css']
})
export class LocataireFormComponent implements OnInit, IGfxListActionComponent {
  SelectedItem;

  id;
  private locataireForm: FormGroup;

  private locataire: any;
  private idUser: any;
  private appartements: ISelectData;
  private userLoc = {
    id: '', login: '', password: '', nom: '', tel: '', profil: '', mail: '', actif: '', idAppartement: '', dateDeb: '', dateFin: '', idUser: ''
  };
  msgSucces
  msgErr;
  msg = ''
  updateMode = false
  file: File;
  fileName: string;
  arrayBuffer: any;
  private locataires = [];
  fileSelected: boolean;
  locFromExcel: any;
  savedFromExcel: boolean;

  constructor(private usersService: UsersService, private appContentService: GfxAppContentService, private appsrv: RefAppartementService,
    private fb: FormBuilder, private reflocatairesrv: RefLocataireService, private utilSrv: GfxMiscSrv, private refsrv: appRefsSrv) {

  }

  ngOnInit(): void {
    this.fileName = "Sélectionner un fichier";
    this.msgSucces = false
    this.msgErr = false
    this.fileSelected = false
    this.locFromExcel = ""
    this.savedFromExcel = false

    this.locataireForm = this.fb.group({
      idAppartement: ['', Validators.required],
      login: [this.userLoc.login, Validators.required],
      // password: ['locataire', Validators.required],
      nom: [this.userLoc.nom],
      tel: [this.userLoc.tel, [Validators.pattern(/^\d{10}$/)]],
      profil: [2, Validators.required],
      mail: [this.userLoc.mail, [Validators.email]],
      actif: [1],
      dateDeb: [''],
      dateFin: [''],
      locFromExcel: ['']
    });

    if (this.SelectedItem && this.SelectedItem.id) {
      this.updateMode = true
      this.reflocatairesrv.getLocataire(this.SelectedItem.id).subscribe((data) => {
        this.userLoc = data
        this.idUser = this.userLoc.idUser

        this.locataireForm = this.fb.group({
          id: [this.userLoc.id],
          idAppartement: [this.userLoc.idAppartement, Validators.required],
          login: [this.userLoc.login, Validators.required],
          // password: [this.userLoc.password],
          nom: [this.userLoc.nom],
          tel: [this.userLoc.tel, [Validators.pattern(/^\d{10}$/)]],
          profil: [2],
          mail: [this.userLoc.mail, [Validators.email]],
          actif: [1],
          dateDeb: [this.userLoc.dateDeb],
          dateFin: [this.userLoc.dateFin],
        });
      })

    }

    if (this.SelectedItem && this.SelectedItem.id) {
      this.reflocatairesrv.getLocataire(this.SelectedItem && this.SelectedItem.id).subscribe((data) => {
        let idApp: number = 0
        if (data.idAppartement != null) {
          idApp = data.idAppartement;

        }
        this.refsrv.getRefAppartementsByLocataire(idApp).subscribe((data1) => {
          this.appartements = data1;
        });
      });
    } else {
      this.refsrv.getRefAppartements().subscribe((data) => {
        this.appartements = data;
      });
    }
  }

  setIdentifValue(idApp) {
    this.appsrv.getAppartement(idApp).subscribe((data) => {
      this.locataireForm.get("login").patchValue(data.code);
    })
  }

  Upload(event) {

    this.file = event.target.files[0];
    this.fileName = this.file.name;

    let fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;

      var data = new Uint8Array(this.arrayBuffer);
      var arr = new Array();
      for (var i = 0; i != data.length; ++i) arr[i] = String.fromCharCode(data[i]);
      var bstr = arr.join("");
      var workbook = XLSX.read(bstr, { type: "binary", cellDates: true });
      var first_sheet_name1 = workbook.SheetNames[0];
      var worksheet1 = workbook.Sheets[first_sheet_name1];

      // sheet locataires
      this.locataires = XLSX.utils.sheet_to_json(worksheet1, { raw: true });
    }
    fileReader.readAsArrayBuffer(this.file);

    this.locataireForm = this.fb.group({
      idAppartement: [''],
      login: [''],
      nom: [''],
      tel: [''],
      profil: [''],
      mail: [''],
      actif: [''],
      dateDeb: [''],
      dateFin: [''],
    });
    this.fileSelected = true
  }

  return() {
    localStorage.removeItem("idLocataire");
    this.utilSrv.navigateTo("LocataireListComponent");
  }

  save() {

    if (this.locataireForm.value.id) {
      let dataUser = this.locataireForm.value
      dataUser.id = this.idUser
      this.usersService.put(dataUser).subscribe((data) => {
        let dataLoc = this.locataireForm.value;
        dataLoc.id = this.SelectedItem.id
        const formData: FormData = new FormData();

        formData.append("idUser", this.idUser);
        formData.append("idAppartement", dataLoc.idAppartement);
        formData.append("dateDeb", dataLoc.dateDeb);
        formData.append("dateFin", dataLoc.dateFin);
        this.reflocatairesrv.put(formData).subscribe((data) => {
          this.return();
        })
      })

    } else {

      if (this.locFromExcel != "") {
        this.reflocatairesrv.saveFromExcel(this.locataires).subscribe((data) => {
          if (data.retour && data.retour == 1) {
            this.msgSucces = true;
            this.msgErr = false;
            this.savedFromExcel = true
          }
          else {
            this.msgErr = true;
            this.msg = data.msg
          }
        })
      } else {
        let dataUser = this.locataireForm.value;
        dataUser.password = this.locataireForm.value.login

        this.usersService.post(dataUser).subscribe((data) => {
          if (!data.idUser) {
            this.msgSucces = false;
            this.msgErr = true;
            this.msg = data[0];
            this.savedFromExcel = false
          } else {
            // this.msgSucces = true;
            // this.msgErr = false;
            // this.msg = "Enregistrement avec succés!"
            let data1 = this.locataireForm.value;
            const formData: FormData = new FormData();

            formData.append("idUser", data.idUser);
            formData.append("idAppartement", data1.idAppartement);
            formData.append("dateDeb", data1.dateDeb);
            formData.append("dateFin", data1.dateFin);

            this.reflocatairesrv.put(formData).subscribe((data) => {
              // if (data.idLoc) {
              //   this.msgSucces = true;
              //   this.msgErr = false;
              // }
              // else {
              //   this.msgErr = true;
              //   this.msg = data.msg
              // }
              this.return();
            })
          }

        })
      }

    }
  }

}