import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class DispatcherFormService {
  
  constructor(private gfxhttp: GfxHttpSrv) { }

  getResponsabilites() {
    return this.gfxhttp.get('refresponsabilite/all');
  }

  post(data) {
    return this.gfxhttp.post('incident/traitement', data);
  }

}
