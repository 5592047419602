import { Action } from "@ngrx/store";
import { IGfxRefInfo } from "../../ref/IGfxRefInfo";
export const UPDATEREFLIST: string = "[GFX] [REF] UPDATE REFLIST";

export class GfxRefListAction implements Action {
    type: string;

    getRefList() {
        return this.payload;
    }
    constructor(_type: string, private payload: IGfxRefInfo[]) {
        this.type = _type;
    }

}
