import { Injectable } from "@angular/core";
import { GfxHttpSrv } from "../../../gfx/helpers/GfxHttpSrv";

@Injectable()
export class appWfSrv {

    constructor(private gfxhttp: GfxHttpSrv) { }

    getCandidateState(idWf, idEtat) {
        return this.gfxhttp.get('workflow/actions/getCandidateState', { idWf: idWf, idEtat: idEtat});
    }
    getHisto(idWf, idElem){
        return this.gfxhttp.get('ouvrage/getHistOuvrage',{idWf: idWf, idElem: idElem});
    }

}
