import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

@Component({
  selector: 'app-gfx-msg-valid',
  templateUrl: './gfx-msg-valid.component.html',
  styleUrls: ['./gfx-msg-valid.component.css']
})
export class GfxMsgValidComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<GfxMsgValidComponent>, @Inject(MAT_DIALOG_DATA) public data: any) { }

  data1 = [];

  ngOnInit() {
    let [first, ...second] = this.data.split(" ")
    second = second.join(" ");
    let sillyString = second.slice(0, -1);
    let fin = sillyString.substring(1, sillyString.length - 1);

    this.data1 = JSON.parse(fin);
  }

  close() {
    this.dialogRef.close();
  }

}
