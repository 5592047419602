import * as auth from "../actions/auth";
import { IUser } from "../../authentication/models/IUser";

export function reducer(state: IUser = null, action : auth.AuthAction){

    switch (action.type){
        case auth.USRLOGGEDIN:
            return Object.assign({},action.getUser());
        case auth.USRLOGGEDOUT:
            return null;
        default:
            return state;
    }


}