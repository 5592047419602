import { Injectable } from "@angular/core";
import * as FileSaver from "file-saver";
import * as excel from 'xlsx';

const MIME_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXT = '.xlsx';

@Injectable()
export class GfxExcelService {

    public exportAsExcelFile(data: any[], excelFileName: string): void {
        const worksheet: excel.WorkSheet = excel.utils.json_to_sheet(data);
        const workbook: excel.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
        const excelBuffer: any = excel.write(workbook, { bookType: 'xlsx', type: 'array' });
        this.saveAsExcelFile(excelBuffer, excelFileName);
    }

    private saveAsExcelFile(buffer: any, fileName: string): void {
        const data: Blob = new Blob([buffer], {
            type: MIME_TYPE
        });
        FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXT);
    }

}