import { IGfxGedData } from "../../core/components/gfxged/IGfxGedData";
import { Action } from "@ngrx/store";
export const UPDATEDATA = "[GFX] [GED] UPDATE DATA";
export class GfxGedAction implements Action {
  type: string;

  getGedData() {
    return this.payload;
  }

  constructor(_type: string, private payload: IGfxGedData) {
    this.type = _type;
  }
}
