import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
  selector: "file-preview-dialog",
  templateUrl: "./file.preview.component.html"
})
export class FilePreviewDialogComponent {
  currMode: string = "";
  modeImg: boolean = false;
  mode;
  constructor(
    public dialogRef: MatDialogRef<FilePreviewDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    const fileExt : string = data.fileName.split(".").pop();
    if (fileExt.toLowerCase()) {
      switch (fileExt) {
        case "pdf": {
          this.currMode = "PDF";
          break;
        }
        case "docx": {
            this.currMode = "Unsupported";
            break;
        }
        case "doc": {
            this.currMode = "Unsupported";
            break;
        }
        case "xlsx": {
            this.currMode = "Unsupported";
            break;
        }
        case "xls": {
            this.currMode = "Unsupported";
            break;
        }
        case "pptx": {
            this.currMode = "Unsupported";
            break;
        }
        case "ppt": {
            this.currMode = "Unsupported";
            break;
        }
        case "png": {
            this.currMode = "Image";
            break;
        };
        case "jpg": {
            this.currMode = "Image";
            break;
        }
        case "gif": {
            this.currMode = "Image";
            break;
        }
        case "txt": {
            this.currMode = "Unsupported";
            break;
        }
        default: {
          //statements;
          break;
        }
      }
    }
  }
}
