import { NgModule, ErrorHandler } from "@angular/core";
import { GfxErrorHandler } from "./services/gfx.error.handler";

@NgModule({
    imports : [],
    providers : [{
        provide : ErrorHandler,
        useClass : GfxErrorHandler
    }]
})

export class GfxErrorModule {

}