import { Component } from "@angular/core";
import { IGfxRefConfig } from "../../../gfx/ref/IGfxRefConfig";
import { IGfxRefInfo } from "../../../gfx/ref/IGfxRefInfo";
import { GfxRefService } from "../../../gfx/ref/gfx.ref.service";
import { IGfxRefForm } from "../../../gfx/ref/IGfxRefForm";
import { IGfxRefFormElement } from "../../../gfx/ref/IGfxRefFormElement";
import { MatDialog } from "@angular/material";
import { GfxRefFormComponent } from "../../../gfx/ref/gfx.ref.form";


@Component({
  selector: "demo-gfx-ref",
  templateUrl: "./demo.ref.component.html"
})
export class DemoGfxRefComponent {
  constructor(private gfxRefService: GfxRefService, public dialog: MatDialog) {

  }
  config: IGfxRefConfig = {
    PageSize: 5,
    PageSizeOptions: [5, 10, 15]
  }

  items: any[] = [
    { value: 'item1', label: 'item 1' },
    { value: 'item2', label: 'item 2' },
    { value: 'item3', label: 'item 3' }
  ];

  //Standalone Ref 
  srccomponent: string = "GfxRefContainerComponent";
  standconfig: IGfxRefConfig = {
    PageSize: 5,
    PageSizeOptions: [5, 10, 15]
  }
  refInfo: IGfxRefInfo = {
    code: "52125-076",
    lib: "Cross-group impactful infrastructure",
  }

  openRefFormDialog() {
    this.gfxRefService.getRefForm(this.refInfo).subscribe((obj: any) => {
      let frmConfig: IGfxRefForm = {
        Elements: <IGfxRefFormElement[]>obj.Elements,
        Id: <IGfxRefFormElement[]>obj.Id
      }
      let dataObj = {
        Form: { ...frmConfig },
        RefInfo: { ...this.refInfo },
        EditMode: false
      }

      let dialogRef = this.dialog.open(GfxRefFormComponent, {
        width: '700px',
        panelClass: 'form-container',
        data: dataObj
      });
    })
  }

}


