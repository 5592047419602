import { Component, OnInit, Injector } from '@angular/core';
import { GfxListFilterComponent } from '../../../../gfx/core/components/gfxlist/GfxListFilterComponent';
import { GfxHttpSrv } from '../../../../gfx/helpers/GfxHttpSrv';
import { DeclarProbService } from '../../services/declar-prob.service';
import { HttpClient, HttpClientModule } from "@angular/common/http";

@Component({
  selector: 'app-signalements-list-filter',
  templateUrl: './signalements-list-filter.component.html',
  styleUrls: ['./signalements-list-filter.component.css']
})
export class SignalementsListFilterComponent extends GfxListFilterComponent implements OnInit {
  idProfil

  Types: any[]
  Lieux: any[]
  Status: any[]
  SelectedType: any;
  SelectedStatus: any;
  SelectedLieu: any;
  isReturn: boolean;
  oldFilter: any = { FilterElements: [] };

  constructor(injector: Injector) {
    super(injector);
  };

  ngOnInit(): void {
    this.idProfil = sessionStorage.getItem('idProfile');

    if (sessionStorage.getItem('isReturn')) {

      this.isReturn = true;
      this.oldFilter = JSON.parse(sessionStorage.getItem('oldFilter'));

      this.oldFilter.FilterElements.forEach(element => {
        if (element.Name == "nature" && element.Value > 0)
          this.SelectedType = element.Value
        if (element.Name == "lieu" && element.Value > 0)
          this.SelectedLieu = element.Value
        if (element.Name == "status" && element.Value >= 0)
          this.SelectedStatus = element.Value
      });

    }

    sessionStorage.removeItem("isReturn");

    const httpClient = this.InjectorInst.get<HttpClient>(HttpClient);
    httpClient.get<any>("lieuprobleme/all").subscribe((data) => {
      this.Lieux = data;
    })

    httpClient.get<any>("natureprobleme/all").subscribe((data) => {
      this.Types = data;
    })

  }

  Actualiser() {
    this.SetElementValue("status", this.SelectedStatus);
    this.SetElementValue("nature", this.SelectedType);
    this.SetElementValue("lieu", this.SelectedLieu);
    this.RefreshList();
  }
}