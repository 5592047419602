import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { RefTypeService } from '../../services/ref-type.service';
import { IGfxListActionComponent } from '../../../../gfx/core/components/gfxlist/IGfxListActionComponent';

@Component({
  selector: 'app-ref-type-form',
  templateUrl: './ref-type-form.component.html',
  styleUrls: ['./ref-type-form.component.css']
})
export class RefTypeFormComponent implements OnInit , IGfxListActionComponent {
  SelectedItem;
  id;
  private typeForm: FormGroup;
  private type = {
    id: '', libelle: ''
  };

  constructor(
    private fb: FormBuilder, private typeSrv: RefTypeService, private utilSrv: GfxMiscSrv) {

  }

  ngOnInit(): void {
    this.typeForm = this.fb.group({
      libelle: [this.type.libelle, Validators.required],
    });

    if (this.SelectedItem && this.SelectedItem.id) {
        this.typeForm = this.fb.group({
          id: [this.SelectedItem.id],
          libelle: [this.SelectedItem.libelle, Validators.required],
      })
    }
  }

  return() {
    this.utilSrv.navigateTo("RefTypeComponent");
  }

  save() {
    if (this.typeForm.value.id) {
      this.typeSrv.put(this.typeForm.value).subscribe((data) => {
        this.return();
      })
    } else {
      this.typeSrv.post(this.typeForm.value).subscribe((data) => {
        this.return();
      })
    }
  }

}