import * as notif from "../actions/gfxnotifications";
import { IGfxNotification } from "../../core/components/gfxnotifications/IGfxNotification";

export function reducer(state : IGfxNotification[] = null , action : notif.GfxNotificationAction) {

    if (action.type == notif.UPDATENOTIFDATA) {
        return [...action.getData()];
    } else
        return state;
   
}