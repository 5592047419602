import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'other-login-theme',
  templateUrl: './other-login-theme.component.html',
  styleUrls: ['./other-login-theme.component.css']
})
export class OtherLoginThemeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
