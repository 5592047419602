import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: "confirm-dialog",
    template: `
    <h1 mat-dialog-title>{{data.title}}</h1>
    <div mat-dialog-content>{{data.msg}}</div>
    <div mat-dialog-actions>
        <button style="outline:none" mat-button [mat-dialog-close]="false">{{libCancelBtn}}</button>&nbsp;&nbsp;&nbsp;
        <button style="outline:none" mat-flat-button color="primary" [mat-dialog-close]="true">{{libConfirmBtn}}</button>
    </div>
    `
})
export class ConfirmDialogComponent {
    private libCancelBtn;
    private libConfirmBtn;
    constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {
        
            if(this.data) {
                this.libCancelBtn = this.data.libCancelBtn ? this.data.libCancelBtn : 'Annuler';
                this.libConfirmBtn = this.data.libConfirmBtn ? this.data.libConfirmBtn : 'Confirmer';
            }
    }
}