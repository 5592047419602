import { Component, OnInit, Injector } from '@angular/core';
import { GfxListFilterComponent } from '../../../../gfx/core/components/gfxlist/GfxListFilterComponent';
import { HttpClient } from '@angular/common/http';
import { DeclarProbService } from '../../services/declar-prob.service';
import { debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { FormGroup, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-locataire-list-filter',
  templateUrl: './locataire-list-filter.component.html',
  styleUrls: ['./locataire-list-filter.component.css']
})
export class LocataireListFilterComponent  extends GfxListFilterComponent implements OnInit {
  idProfil

  Types: any[]
  Lieux: any[]
  Status: any[]
  SelectedType: any;
  SelectedStatus: any;
  SelectedLieu: any;
  motif: { motif: string; };
  filteredOptions: any[];
  isLoading: boolean;
  private filterForm: FormGroup;

  constructor(injector: Injector,  private fb: FormBuilder) {
    super(injector);
  };

  ngOnInit(): void {
    this.idProfil = sessionStorage.getItem('idProfile');

    // this.declarProbSrv.getLocataires().subscribe((data) => {
    //   this.filteredOptions = data;
    // });

    this.filterForm = this.fb.group({
      locataire: [""],
      nom: [""],
      tel: [""],
    });

    // this.activateAutocomplete();
  }

  Actualiser() {
    let dataFilter = this.filterForm.value;

    this.SetElementValue("locataire", dataFilter.locataire);
    this.SetElementValue("nom", dataFilter.nom);
    this.SetElementValue("tel", dataFilter.tel);
    this.RefreshList();
  }

  // setDefaultUnite(appartement) {
  //   if (appartement && appartement.id) {
  //     this.filterForm.get('appartement').setValue(appartement);
  //   }
  // }

  // displayFn(appartement) {
  //   return appartement.lib;
  // }

  // activateAutocomplete() {
  //   this.filterForm.controls.appartement.valueChanges
  //     .pipe(
  //       debounceTime(500),
  //       tap(() => {
  //         this.filteredOptions = [];
  //         this.isLoading = true;
  //       }),
  //       switchMap(value => this.declarProbSrv.getLocataires(value)
  //         .pipe(
  //           finalize(() => {
  //             this.isLoading = false
  //           }),
  //         )
  //       )
  //     ).subscribe(data => {
  //       this.filteredOptions = data;
  //     });
  // }
}
