import { Component, OnInit, Input } from "@angular/core";
import { IGfxRefInfo } from "./IGfxRefInfo";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { GfxState, getGfxRefListState } from "../store/gfxstate";
import { GfxRefService } from "./gfx.ref.service";
import { GfxAppContentService } from "../core/components/app.content/gfx.app.content.service";
import { IGfxRefConfig } from "./IGfxRefConfig";

@Component({
    selector: "gfx-ref-container",
    templateUrl: "gfx.ref.container.html",
    styleUrls: ["./gfx.ref.container.css"]
})
export class GfxRefContainerComponent implements OnInit {
    @Input() config: IGfxRefConfig;
    reflistObs: Observable<IGfxRefInfo[]>;
    RefList: IGfxRefInfo[] = [];
    constructor(
        private store: Store<GfxState>,
        private refService: GfxRefService,
        private appContentService: GfxAppContentService
    ) {

    }

    ngOnInit(): void {
        if (!this.config) {
            this.config = {
                PageSize: 5,
                PageSizeOptions: [5, 10, 15]
            }
        }

        this.refService.getRefList(sessionStorage.getItem("idProfile"));
        this.reflistObs = this.store.select(getGfxRefListState);
        this.reflistObs.subscribe((refList: IGfxRefInfo[]) => {
            this.RefList = refList;
        })
    }

    OpenRef(ref: IGfxRefInfo) {
        this.appContentService.changeContent.emit(
            {
                ComponentName: ref.compName ? ref.compName : "GfxRefComponent",
                Data: {
                    refinfo: ref,
                    sourcecomponent: "GfxRefContainerComponent",
                    refconfig: this.config
                }
            });
    }




}