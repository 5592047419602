import { Component, OnInit } from '@angular/core';
import { IGfxListActionComponent } from '../../../../gfx/core/components/gfxlist/IGfxListActionComponent';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { GfxAppContentService } from '../../../../gfx/core/components/app.content/gfx.app.content.service';
import { RefLieuxProbService } from '../../services/ref-lieux-prob.service';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { RefNatureProbService } from '../../services/ref-nature-prob.service';

@Component({
  selector: 'app-ref-nature-prob-form',
  templateUrl: './ref-nature-prob-form.component.html',
  styleUrls: ['./ref-nature-prob-form.component.css']
})
export class RefNatureProbFormComponent implements OnInit, IGfxListActionComponent {
  SelectedItem;
  id;
  private natureProbForm: FormGroup;
  private nature = {
    id: '', lib: ''
  };

  constructor(private appContentService: GfxAppContentService,
    private fb: FormBuilder, private srvNatureProb: RefNatureProbService, private utilSrv: GfxMiscSrv) {

  }

  ngOnInit(): void {
    this.natureProbForm = this.fb.group({
      libelle: [this.nature.lib, Validators.required],
    });

    if (this.SelectedItem && this.SelectedItem.id) {
      this.srvNatureProb.getNatureProb(this.SelectedItem.id).subscribe((data) => {
        this.nature = data
        this.natureProbForm = this.fb.group({
          id: [this.nature.id],
          libelle: [this.nature.lib, Validators.required],
        });
      })
    }

  }

  return() {
    this.utilSrv.navigateTo("RefNatureProbListComponent");
  }

  save() {
    if (this.natureProbForm.value.id) {
      this.srvNatureProb.put(this.natureProbForm.value).subscribe((data) => {
        this.return();
      })
    } else {
      this.srvNatureProb.post(this.natureProbForm.value).subscribe((data) => {
        this.return();
      })
    }
  }

}