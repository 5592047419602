import { Component, OnInit } from "@angular/core";
import { GfxMiscSrv } from "../../../app/../gfx/helpers/gfx.misc.service";
import { GfxAppContentService } from "../../core/components/app.content/gfx.app.content.service";
import { GfxListOrderDirectionEnum } from "../../core/components/gfxlist/GfxListOrderDirectionEnum";
import { IGfxListConfig } from "../../core/components/gfxlist/IGfxListConfig";
import { UsersService } from "../services/users.service";
import { GfxUsersListFilterComponent } from "./gfx.users.list.filter";



@Component({
    selector: "gfx-users-management",
    templateUrl: "gfx.users.management.component.html"
})
export class GfxUsersManagComponent implements OnInit {

    title = "Gestion des utilisateurs";
    listCfg: IGfxListConfig;
    
    constructor(private appContentService: GfxAppContentService, private usersService : UsersService ,
        private utilSrv: GfxMiscSrv) {

    }

    ngOnInit(): void {

        this.listCfg = {
            Code: "UsrList",
            Title: "Liste des utilisateurs",
            DataUrl: "users",
            ListOptions: {
                OrderBy : "iduser",
                OrderDir : GfxListOrderDirectionEnum.ASC,
                HeaderPanelExpanded: false,
                EnableBuiltInFilter: true,
                EnableCustomFilter: true,
                ExpandCustomFilter: false,
            },

            Columns: [
                { Name: "loginUser", Label: "Login", Render: "loginUser"},
                { Name: "nomPrenom", Label: "Nom et prénom", Render: "nomPrenom"},
                { Name: "libGroupe", Label: "Groupe", Render: "libGroupe"}
            ],
            Filter: {
                CustomFilterComponent: GfxUsersListFilterComponent,
                FilterElements: [
                    { Name: "login", Field: "f1", Operator: "LIKE", Value: "" },
                    { Name: "profile", Field: "f2", Operator: "=", Value: "" }
                ]
            },
            Actions: [
                { Name: "edit", Label: "Modifier", ComponentName: "GfxUserFormComponent", FaIcon: "fa-pencil-alt", Params: {mode : "UPDATE", hideAction: false} },
                { Name: "profilSecond", Label: "Profils secondaires", ComponentName: "UsrProfilSecComponent", FaIcon: "fa-users", Params:{hideAction: false} }
            ],
            PaginatorConfig: {
                StaticMode: false,
                PageSize: 10,
                SizeOptions: [5,10, 15, 20, 25, 30],
                ShowFirstLastBtns: true
            },
            ExportOptions: {
                FileName: "users",
                Columns: ["loginUser", "nomPrenom", "libGroupe"],
                Headers: ["Login", "Nom et prénom", "Groupe"],
                EnableEXCEL: true,
                EnableWORD: false,
                EnablePDF: false,
            }


        }
        
    }

    NewUser() {
        this.utilSrv.navigateTo("GfxUserFormComponent");
    }

}