import { Injectable } from '@angular/core';
import { GfxHttpSrv } from '../../../gfx/helpers/GfxHttpSrv';

@Injectable()
export class RefTypeService {

  constructor(private gfxhttp: GfxHttpSrv) { }

  getTypeById(id) {
      return this.gfxhttp.get('type/findOne', {idLieu:id});
  }

  post(data) {
      return this.gfxhttp.post('type', data);
  }

  put(data) {
      return this.gfxhttp.put('type', data);
  }

}