import { Component } from "@angular/core";
import { IGfxGedConfig } from "../../../gfx/core/components/gfxged/IGfxGedConfig";
import { GfxGedRenderingMode } from "../../../gfx/core/components/gfxged/GfxGedRenderingMode";

@Component({
  selector: "demo-gfx-ged",
  templateUrl: "./demo.ged.component.html"
})
export class DemoGfxGedComponent {
  
  gedConfig: IGfxGedConfig = {
    GedName: "MyGed",
    Params: [],
    Layout: {
      RenderingMode : GfxGedRenderingMode.Grid,
      GridNbrOfColumns: 8,
      ListModePageSize : 10,
      ListModePageSizeOptions : [5,10,15,20]
    }

  };
}


