import { Component, OnInit, Injector } from '@angular/core';
import { GfxListFilterComponent } from '../../../../gfx/core/components/gfxlist/GfxListFilterComponent';

@Component({
  selector: 'app-ref-appartem-list-filter',
  templateUrl: './ref-appartem-list-filter.component.html',
  styleUrls: ['./ref-appartem-list-filter.component.css']
})
export class RefAppartemListFilterComponent extends GfxListFilterComponent implements OnInit {

  ngOnInit(): void {
  }
  
  Actualiser() {
  }

  constructor(injector: Injector) {
      super(injector);
  };

}