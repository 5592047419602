import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector : "gfx-error-dialog",
    templateUrl : "./gfx.error.dialog.component.html",
    styleUrls : ["./gfx.error.dialog.component.css"]
})
export class GfxErrorDialogComponent {
    constructor(public dialogRef: MatDialogRef<GfxErrorDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any) {

    }
}