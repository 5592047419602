import { Component, OnInit } from '@angular/core';
import { IGfxListConfig } from '../../../../gfx/core/components/gfxlist/IGfxListConfig';
import { FormGroup, FormBuilder } from '@angular/forms';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { GfxListOrderDirectionEnum } from '../../../../gfx/core/components/gfxlist/GfxListOrderDirectionEnum';
import { SignalementsListFilterComponent } from '../../../reclam/components/signalements-list-filter/signalements-list-filter.component';
import { RefTypeService } from '../../services/ref-type.service';

@Component({
  selector: 'app-ref-type',
  templateUrl: './ref-type.component.html',
  styleUrls: ['./ref-type.component.css']
})
export class RefTypeComponent implements OnInit {

  private typesList: any;
  config: any;
  collection = { count: 0, data: [] };
  private idProfil;
  private filterForm: FormGroup;
  listCfg: IGfxListConfig;

  constructor(private fb: FormBuilder, private reftypeSrv: RefTypeService, private utilSrv: GfxMiscSrv) {

  }

  ngOnInit() {
    this.idProfil = sessionStorage.getItem('idProfile');


    this.listCfg = {
      Code: "typeList",
      Title: "Liste des types",
      DataUrl: "type",
      ListOptions: {
        OrderBy: "id",
        OrderDir: GfxListOrderDirectionEnum.ASC,
        HeaderPanelExpanded: false,
        EnableBuiltInFilter: true,
        EnableCustomFilter: true,
        ExpandCustomFilter: false,
      },
      Columns: [
        { Name: "libelle", Label: "LIBELLÉ", Render: "libelle" },
      ],
      // Filter: {
      //   CustomFilterComponent: SignalementsListFilterComponent,
      //   FilterElements: [
      //     // { Name: "reference", Field: "f1", Operator: "=", Value: "" },
      //     // { Name: "locataire", Field: "f2", Operator: "=", Value: "" }, 
      //     { Name: "libelle", Field: "f1", Operator: "=", Value: "" },
      //   ]
      // },

      Actions: [
        { Name: "Edit", Label: "Modifier", ComponentName: "RefTypeFormComponent", FaIcon: "fa-edit", Params: { hideAction: false } },
        {
          Name: "Supprimer", Label: "Supprimer",  ComponentName: "RefTypeComponent", FaIcon: "fa-trash-alt", Params: {hideAction: false,
            confirmAction: {
              cancelBtn: "Non",
              confirmBtn: "Oui",
              msg: "Êtes-vous sûr de vouloir supprimer cet élément ?",
              url: "type/supprimer"
            }
          }
        }
      ],

      PaginatorConfig: {
        StaticMode: false,
        PageSize: 10,
        SizeOptions: [10, 20, 50, 100],
        ShowFirstLastBtns: true
      },
      ExportOptions: {
        FileName: "profils",
        Columns: ["code", "libelle"],
        EnableEXCEL: true,
        EnableWORD: false,
        EnablePDF: false,
      }
    }

  }

  NewType() {
    this.utilSrv.navigateTo("RefTypeFormComponent");
  }

}
