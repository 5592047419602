import { Component, OnInit } from '@angular/core';
import { GfxMiscSrv } from '../../../../gfx/helpers/gfx.misc.service';
import { VisualisationProbService } from '../../services/visualisation-prob.service';
import { TypeFeExport } from '../../../enums/type-fe-export.enum';

@Component({
  selector: 'app-imprimer-prob',
  templateUrl: './imprimer-prob.component.html',
  styleUrls: ['./imprimer-prob.component.css']
})
export class ImprimerProbComponent implements OnInit {
  SelectedItem
  idReclam
  reclammation = {
    appartement: "",
    lieu: "",
    nature: "",
    date: "",
    commentaire: ""
  }
  idProfil
  isPrint: any;
  constructor(private srvVisProb: VisualisationProbService, private misc: GfxMiscSrv) { }

  ngOnInit() {
    this.idProfil = sessionStorage.getItem('idProfile');

    this.idReclam = this.SelectedItem.id
    this.srvVisProb.getIncidentById(this.idReclam).subscribe((data) => {
      this.reclammation = data;
    });

    setTimeout(() => {
      this.impression()
    }, 1000);

    
  }

  return() {
    this.misc.navigateTo("MesSignalementsComponent");
  }

  impression(){
    this.misc.impressFe("divImp", TypeFeExport.PDF, "Réclamation");
  }

}
