import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/users.service';
import { IGfxListActionComponent } from '../../../core/components/gfxlist/IGfxListActionComponent';
import { GfxMiscSrv } from '../../../../app/../gfx/helpers/gfx.misc.service';

@Component({
  selector: 'app-usr-profil-sec',
  templateUrl: './usr-profil-sec.component.html',
  styleUrls: ['./usr-profil-sec.component.css']
})
export class UsrProfilSecComponent implements OnInit, IGfxListActionComponent {
  SelectedItem;

  private profiles;
  constructor(private usersrv: UsersService, private utilSrv: GfxMiscSrv) { }

  ngOnInit() {
    this.usersrv.getProfilesSec(this.SelectedItem.id).subscribe((data) => {
      this.profiles = data;
    });
  }

  return() {
    this.utilSrv.navigateTo("GfxUsersManagComponent");
  }

  save() {
    this.usersrv.saveProfilesSec(this.profiles).subscribe((data) => {
      this.return();
    })
  }

}
