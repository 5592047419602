import { Component, OnInit } from "@angular/core";
import { IGfxListConfig } from "../../core/components/gfxlist/IGfxListConfig";
import { GfxListOrderDirectionEnum } from "../../core/components/gfxlist/GfxListOrderDirectionEnum";
import { GfxDictionary } from "../../core/models/GfxDictionary";
import { GfxChangeContentEventData } from "../../core/components/app.content/gfx.change.content.event.data";
import { GfxAppContentService } from "../../core/components/app.content/gfx.app.content.service";
import { ProfileService } from "../services/profile.service";
import { GfxMiscSrv } from "../../../app/../gfx/helpers/gfx.misc.service";

@Component({
    selector : "gfx-profiles-management",
    templateUrl : "gfx.profiles.management.component.html"
})
export class GfxProfilesManagComponent implements OnInit {
    title = "Gestion des profils";
    listCfg: IGfxListConfig;
    
    constructor(private appContentService: GfxAppContentService, private utilSrv: GfxMiscSrv, private profilesService : ProfileService ) {

    }
    
    ngOnInit(): void {

        this.listCfg = {
            Code: "ProfilList",
            Title: "Liste des profils",
            DataUrl: "profiles/listeProfile",
            ListOptions: {
                OrderBy : "idprofile",
                OrderDir : GfxListOrderDirectionEnum.ASC,
                HeaderPanelExpanded: false,
                EnableBuiltInFilter: true,
                EnableCustomFilter: true,
                ExpandCustomFilter: false,
            },
            Columns: [
                { Name: "codegroupe", Label: "Code", Render: "codegroupe" },
                { Name: "libgroupe", Label: "Libellé", Render: "libgroupe" }
            ],
            
            Actions: [
                { Name: "edit", Label: "Modifier", ComponentName: "GfxProfileFormComponent", FaIcon: "fa-pencil-alt", Params: {mode : "UPDATE", hideAction: false}},
                { Name: "droitMenus", Label: "Droits de l'application", ComponentName: "ProfilDroitsComponent", FaIcon: "fa-lock" , Params: {hideAction: false}}
            ],
            PaginatorConfig : {
                StaticMode : false,
                PageSize : 7,
                SizeOptions : [7,10,15,20,25,50,100],
                ShowFirstLastBtns : true
            },
            ExportOptions : {
                FileName : "profils",
                Columns : ["code","libelle"],
                EnableEXCEL : true,
                EnableWORD : false,
                EnablePDF : false,
            }
            

        }
    }

    NewProfile() {
        this.utilSrv.navigateTo("GfxProfileFormComponent");
    }
}