import { Component } from "@angular/core";
import { GfxAppContentService } from "../../core/components/app.content/gfx.app.content.service";
import { WorkflowService } from "../Services/workflow.service";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";
import { GfxState, getUserState } from "../../store/gfxstate";
import { IUser } from "../../authentication/models/IUser";

@Component({
    selector : "gfx-wf-view",
    templateUrl : "gfx.wf.view.html"
})
export class GfxWfView {
    
    curItemWFPending: any[];
    wfData;
    item : any[];
    title = "Détail instance";
    actionFiles = [];
    actions = [];
    decision
    comment
    public currUser: IUser;

    constructor(private appContentService: GfxAppContentService, private wfService : WorkflowService, private store: Store<GfxState>) {

    }
    
    ngOnInit() {
        //Get current user
        let usrObs: Observable<IUser>;
        usrObs = this.store.select(getUserState);
        let sub = usrObs.subscribe((s) => {
            this.currUser = s;
        })
        this.item = this.curItemWFPending;
        let obs = this.wfService.getInstActions(this.currUser.idProfile, this.wfData.idWfInstState);
        obs.subscribe((retour:any)=>{
            this.actions = retour.lines;
        })
    }

    UpdateStateWf (item) {
        let data = {
            idElem : item.id,
            idWf : this.wfData.idWf,
            from : this.wfData.idWfInstState,
            to : this.decision,
            comment : this.comment
        }
        let obs = this.wfService.postWfAction(data);
        obs.subscribe((retour:any)=>{
            this.actions = retour.lines;
        })
    };

}